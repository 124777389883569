import React, { FC } from 'react'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { S } from './styled'

const Vote: FC = () => {
  return (
    <S.VoteContainer>
      <S.VoteCard>
        <S.VoteCardTopText>GIANT Connect Governance</S.VoteCardTopText>
        <S.VoteCardMiddleText>
          GNT tokens represent voting shares in GIANT Connect governance. You can vote on each proposal yourself or
          delegate your votes to a third party.
        </S.VoteCardMiddleText>
        <S.VoteCardBottomText>Read more about GIANT Connect Governance</S.VoteCardBottomText>
      </S.VoteCard>

      <S.ContentBox>
        <S.VoteCardTopText>Proposals</S.VoteCardTopText>
        <PrimaryButton style={{ height: '50px', width: '12.8125rem' }}>Create Proposal</PrimaryButton>
      </S.ContentBox>

      <S.VoteCardContainer>
        <S.ContentCard>
          <S.ContentCardText>
            <span style={{ color: '#66717B' }}>2.16</span> &nbsp;Lorem Ipsum dolor
          </S.ContentCardText>
          <S.ContentCardButton>Succeeded</S.ContentCardButton>
        </S.ContentCard>
        <S.ContentCard>
          <S.ContentCardText>
            <span style={{ color: '#66717B' }}>2.16</span> &nbsp;Lorem Ipsum dolor
          </S.ContentCardText>
          <S.ContentCardButton sx={{ color: '#66717B !important' }}>Canceled</S.ContentCardButton>
        </S.ContentCard>
        <S.ContentCard>
          <S.ContentCardText>
            <span style={{ color: '#66717B' }}>2.16</span> &nbsp;Lorem Ipsum dolor
          </S.ContentCardText>
          <S.ContentCardButton sx={{ color: '#45B549 !important' }}>Executed</S.ContentCardButton>
        </S.ContentCard>
      </S.VoteCardContainer>
    </S.VoteContainer>
  )
}

export default Vote
