import React, { FC } from 'react'
import Header from './header'
import Content from './content'
import { Footer } from './footer'
import AppRouters from '../../routes/AppRouters'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
const Layout: FC = () => {
  return (
    <div className="main-layout">
      <Router>
        <Header />
        <Content>
          <AppRouters />
        </Content>
        <Footer />
      </Router>
      <ToastContainer autoClose={3000} position="bottom-right" closeOnClick rtl={false} draggable />
    </div>
  )
}

export default Layout
