import { Buffer } from 'buffer'
import { BigNumber } from 'bignumber.js'
export const Utils = {
  paramConversion: {
    num: ['Compact<Balance>', 'BalanceOf', 'u8', 'u16', 'u32', 'u64', 'u128', 'i8', 'i16', 'i32', 'i64', 'i128'],
  },
}

export const isNumType = (type: string) => Utils.paramConversion.num.some((el) => type.indexOf(el) >= 0)

export function transformParams(paramFields: any[], inputParams: any[], opts = { emptyAsNull: true }) {
  const paramVal = inputParams.map((inputParam: { value: string } | null | string) => {
    if (typeof inputParam === 'object' && inputParam !== null && typeof inputParam.value === 'string') {
      return inputParam.value.trim()
    } else if (typeof inputParam === 'string') {
      return inputParam.trim()
    }
    return inputParam
  })
  const params = paramFields.map((field: any, ind: number) => ({
    ...field,
    value: paramVal[ind] || null,
  }))
  return params.reduce((memo: any, { type = 'string', value }: any) => {
    if (value == null || value === '') return opts.emptyAsNull ? [...memo, null] : memo

    let converted = value

    if (type.indexOf('Vec<') >= 0) {
      converted = converted.split(',').map((e: string) => e.trim())
      converted = converted.map((single: string) =>
        isNumType(type) ? (single.indexOf('.') >= 0 ? Number.parseFloat(single) : Number.parseInt(single)) : single,
      )
      return [...memo, converted]
    }

    if (isNumType(type)) {
      converted = converted.indexOf('.') >= 0 ? Number.parseFloat(converted) : Number.parseInt(converted)
    }
    return [...memo, converted]
  }, [])
}

export function tohex(name: string): string {
  return Buffer.from(name, 'utf8').toString('hex')
}

export function uriToHex(didUri: string) {
  return didUri
    .split(':')
    .map((part, i) => {
      switch (i) {
        case 0:
          return part
        case 1:
          return '0x' + tohex(part)

        default:
          return tohex(part)
      }
    })
    .reduce((a, b) => `${a === '' ? '' : a + ':'}${b}`, '')
}

export const convert = (from: any, to: any) => (str: any) => Buffer.from(str, from).toString(to)

export const hexToUtf8Convert = convert('hex', 'utf8')

export function hexToUtf8(hex: any) {
  if (hex.length) {
    let newHex = hex.charAt(0) === '0' ? hex.slice(2) : hex
    return decodeURIComponent('%' + newHex.match(/.{1,2}/g).join('%'))
  }
}

export const currencyToNumber = (currency: string) => currency.replace(/[^0-9.-]+/g, '')

export const toDecimal = (amount: string, decimal = 12) => {
  let fullAmount = new BigNumber(amount).dividedBy(Math.pow(10, decimal)).toString()

  return {
    full: fullAmount !== 'NaN' ? fullAmount : '0',
    deci: fullAmount !== 'NaN' ? new BigNumber(fullAmount)?.toFixed(4, 1) : '0.0000',
  }
}

export const toWei = (amount: string, decimal = 12) => {
  return new BigNumber(amount).multipliedBy(Math.pow(10, decimal)).toString()
}

export const getDifference = (array1: any, array2: any) => {
  return array1.filter((object1: any) => {
    return !array2.some((object2: any) => {
      return object1.metadata.id === object2.metadata.id
    })
  })
}
