import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import React, { FC } from 'react'
import { WHY_US_DATATYPE } from '../../types'

interface Props {
  data: WHY_US_DATATYPE
}

const WhyUsCard: FC<Props> = ({ data }) => {
  return (
    <CustomBox>
      <Box>
        <HeaderText>{data.title}</HeaderText>
        <SubText>{data.subTitle}</SubText>
      </Box>
    </CustomBox>
  )
}

const CustomBox = styled(Box)(({ theme }: any) => ({
  height: '12.1875rem',
  width: '384px',
  [theme.breakpoints.down('sm')]: {
    width: '350px',
  },
  background: theme.palette.grey[400],
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  justifyContent: 'space-around',
  padding: '2rem',
  boxSizing: 'border-box',
}))

const HeaderText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 700,
  fontSize: '1.125rem',
  lineHeight: '1.4375rem',
  // marginBottom: '1rem',
}))
const SubText = styled(Typography)(({ theme }: any) => ({
  fontWeight: 400,
  fontSize: '0.8125rem',
  lineHeight: '1.5rem',
}))

export default WhyUsCard
