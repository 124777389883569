import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { NavLink } from 'react-router-dom'
import { PAGES } from 'utils/constants'
import { PAGES_TYPE } from 'types'
import { Divider, Grid, styled, Tooltip } from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import { Box } from '@mui/system'
import WalletIcon from 'assets/icons/WalletIcon.svg'
import { useWalletContext } from 'contexts/wallet'
import ProfileMenu from './ProfileMenu'
import ConnetWalletModal from './ConnetWalletModal'
import DisconnectWalletModal from './DisconnectWalletModal'
import { getItem, setItem } from '../../../services/localStorage/localStorage.service'
import { getBalance } from 'polkadot/callmethods/system'
const injectedWeb3 = (window as any).injectedWeb3

const Collapse: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const [isToggle, setIsToggle] = useState<boolean>(false)
  const handleModalOpen = () => setIsToggle(true)
  const handleModalClose = () => setIsToggle(false)

  const [isDisconnectToggle, setIsDisconnectToggle] = useState<boolean>(false)
  const handleDisconnectModalOpen = () => setIsDisconnectToggle(true)
  const handleDisconnectModalClose = () => setIsDisconnectToggle(false)

  const { isConnected, connectedAccount, balance } = useWalletContext()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    const checkInjected = async () => {
      if (Object.keys(injectedWeb3).length && isConnected) {
        const accounts = getItem<any[]>('accounts', [])

        if (accounts.length) {
          // setAddress(accounts[0].address)
          getBalance(accounts[0].address).then((bal) => {})
        } else console.log('please link your account to the wallet.')
      } else {
        setItem<boolean>('isConnected', false)
      }
    }
    checkInjected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ProfileMenu />
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ListIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <WalletContainer item>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            {isConnected ? (
              <>
                <BalanceContainer
                  onClick={() => {
                    handleDisconnectModalOpen()
                  }}
                >
                  <LeftContainer
                    className="animate__animated animate__fadeInRight"
                    sx={{ fontWeight: 700, fontSize: '18px' }}
                  >
                    {(balance?.deci ?? '0') + ' GNT'}
                  </LeftContainer>
                  <img
                    src={WalletIcon}
                    style={{
                      zIndex: '99',
                      background: isConnected ? '#CED6E0' : '',
                      height: '2.5rem',
                    }}
                    className="animate__animated animate__fadeIn"
                    alt="walletLogo"
                  />
                  <RightContainer
                    sx={{
                      fontWeight: 400,
                      color: '#66717B',
                      fontSize: '1.125rem',
                    }}
                    className="animate__animated animate__fadeInLeft"
                  >
                    {connectedAccount?.address.substring(0, 6)}...
                    {connectedAccount?.address.substring(connectedAccount.address.length - 4)}
                  </RightContainer>
                </BalanceContainer>
              </>
            ) : (
              <div
                style={{
                  cursor: 'pointer',
                  width: '100%',
                }}
                onClick={() => {
                  handleModalOpen()
                }}
              >
                <Tooltip title="Connect wallet">
                  <div>
                    <ConnectContainer>
                      <img src={WalletIcon} alt="walletLogo" />
                      Connect wallet
                    </ConnectContainer>
                  </div>
                </Tooltip>
              </div>
            )}
          </Box>
        </WalletContainer>
        <Divider />

        {PAGES.map((page: PAGES_TYPE) => {
          return (
            <NavItem
              to={page.route}
              key={page.id}
              style={({ isActive }) => {
                return {
                  borderLeft: isActive ? '5px solid #45B549' : '',
                }
              }}
              onClick={handleClose}
            >
              <MenuItem sx={{ width: '100%', displat: 'flex', justifyContent: 'center' }}>{page.title}</MenuItem>
            </NavItem>
          )
        })}
      </Menu>
      <ConnetWalletModal isToggle={isToggle} handleModalClose={handleModalClose} />
      <DisconnectWalletModal isToggle={isDisconnectToggle} handleModalClose={handleDisconnectModalClose} />
    </div>
  )
}

const NavItem = styled(NavLink)(({ theme }) => ({
  color: theme.palette.common.black,
  boxSizing: 'border-box',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100%',
  minWidth: '20rem',
  textDecoration: 'none',
}))

const WalletContainer = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  display: 'grid',
  placeContent: 'center',
  [theme.breakpoints.down('sm')]: {
    order: 2,
  },
}))

const LeftContainer: any = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  width: '100%',
  minWidth: '40%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '2.5rem',
  borderRadius: '2rem 0 0 2rem',
  padding: '0 0.5rem 0 1rem',
}))
const RightContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '2.5rem',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  padding: '0 1rem',
  borderRadius: '0 2rem 2rem 0',
}))
const ConnectContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '2.5rem',
  width: '10rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  padding: '0.2rem 1rem 0.2rem 0.2rem',
  borderRadius: '2rem',
}))

const BalanceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
  borderRadius: '20px',
}))
export default Collapse
