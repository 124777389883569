import { Modal } from '@mui/material'
import { Box } from '@mui/system'
import { WalletContext } from 'contexts/wallet'
import React, { FC, useContext } from 'react'
import { S } from './styled'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

interface Props {
  isToggle: boolean
  handleModalClose: () => void
}

const DisconnectWalletModal: FC<Props> = ({ isToggle, handleModalClose }) => {
  const { accounts, disconnectHandler, walletConnectHandler, connectedAccount } = useContext(WalletContext)

  return (
    <>
      <Modal
        open={isToggle}
        onClose={() => {
          handleModalClose()
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <S.DisconnectModalContainer>
          <S.ModalHeading>
            <h2 id="parent-modal-title">Disconnect wallet</h2>

            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </S.ModalHeading>

          <S.DisconnectModalContent>
            <S.ConnectedAccountBox>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <S.MainText sx={{ marginBottom: '0.2rem' }}>{connectedAccount?.meta?.name}</S.MainText>
                <S.AddressText>
                  {connectedAccount?.address?.substring(0, 6)}.....
                  {connectedAccount?.address?.substring(connectedAccount?.address?.length - 10)}
                </S.AddressText>
              </Box>
              <S.ModalContentBoxItems>
                <S.DisconnectBtn
                  onClick={async () => {
                    await disconnectHandler()
                    handleModalClose()
                  }}
                >
                  Disconnect
                </S.DisconnectBtn>
              </S.ModalContentBoxItems>
            </S.ConnectedAccountBox>
          </S.DisconnectModalContent>
          <S.DisconnectModalContent>
            <S.AddressText sx={{ width: '100%' }}>CHANGE TO</S.AddressText>
            {accounts.length > 0 &&
              accounts.map((account: any) => {
                return (
                  <S.DisconnectModalContentBox
                    key={account.address}
                    onClick={async () => {
                      await walletConnectHandler(account)
                      handleModalClose()
                    }}
                    toggleTheme={account.address === connectedAccount.address}
                  >
                    <S.ModalContentBoxItems sx={{ display: 'flex', width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <S.MainText sx={{ minWidth: '100px' }}>{account.meta.name}</S.MainText>
                        <S.AddressText sx={{ minWidth: '50px', overflow: 'hidden',textOverflow: "ellipsis",whiteSpace:"nowrap" }}>{account.address}</S.AddressText>
                      </Box>
                    </S.ModalContentBoxItems>
                  </S.DisconnectModalContentBox>
                )
              })}
          </S.DisconnectModalContent>
        </S.DisconnectModalContainer>
      </Modal>
    </>
  )
}

export default DisconnectWalletModal
