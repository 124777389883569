import BigNumber from 'bignumber.js'
import { txSend } from 'polkadot'
import { getProviderAccount } from 'polkadot/callmethods/giantsudo'
import { toast } from 'react-toastify'
import { toWei } from 'utils/utils'

export const handleStake = async (
  stakeAmount: string,
  account: any,
  setIsLoading: any,
  assetMetadata: any,
  setStakeEl: any,
) => {
  try {
    await setIsLoading(true)
    let assetId = 1
    let providerAccount = await getProviderAccount()
    let weiAmount = toWei(stakeAmount)
    let paramFields = [true]
    let inputParams = [weiAmount]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'staking', 'stake')
    const unsub = txExecute
      .signAndSend(...fromAcct, ({ status }: any) => {
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setIsLoading(false)
          return
        }
        if (status.type === 'Finalized') {
          setIsLoading(false)
          setStakeEl(null)
          toast.success('Staked')
        }
      })
      .catch((err: any) => {
        toast.error(err.message)
        setIsLoading(false)
        setStakeEl(null)
      })
  } catch (err) {
    console.log("providerAccount doesn't exist", err)
    setIsLoading(false)
  }
}

export const handleUnstake = async (
  amount: string,
  account: any,
  assetMetadata: any,
  setIsLoading: any,
  setUnStakeEl: any,
) => {
  try {
    await setIsLoading(true)
    let asset = 1
    let providerAccount = await getProviderAccount()
    let deciAmount = toWei(amount)
    let minimalAmount = new BigNumber(deciAmount).minus(1).toString()
    let paramFields = [true]
    let inputParams = [minimalAmount]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'staking', 'unstake')
    const unsub = txExecute
      .signAndSend(...fromAcct, ({ status }: any) => {
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setIsLoading(false)
          return
        }
        if (status.type === 'Finalized') {
          toast.success('Unstaked')
          setIsLoading(false)
          setUnStakeEl(null)
        }
      })
      .catch((err: any) => {
        console.log('Transaction Failed', err)
      })
  } catch (e) {
    console.log('ERROR UNSTAKING ------>', e)
  }
}
