import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { FC, Fragment, useEffect, useState } from 'react'
import WorldLogo from 'assets/icons/World.svg'
import DownArrow from 'assets/icons/DownArrow.svg'
import Select, { components, ControlProps } from 'react-select'
import { S } from './shop.style'
import PacksContainer from './PacksContainer'
import { POPULAR_COUNTRIES } from 'utils/constants'
import { useQuery } from 'react-query'
import { fetchCountries, fetchDummyCountries, fetchPacksById } from 'utils/constants/queries'
import { fetchQueries, validateCode } from 'utils/constants/config'
import PrimaryCard from 'components/cards/PrimaryCard'
import PurchaseModal from 'components/modals/PurchaseModal'
import ReactCountryFlag from 'react-country-flag'
import { useNavigate, useParams } from 'react-router-dom'
import { API_STATE, useWalletContext } from 'contexts/wallet'
import GiftModal from 'components/modals/GiftModal'
import { getAccountInstances } from 'polkadot/callmethods/dct'
import ShopPageImg from 'assets/icons/ShopPage.svg'
import WarningAlert from 'components/notification/WarningAlert'

const Shop: FC = () => {
  const { id } = useParams()
  const match = useMediaQuery('(max-width:650px)')
  const match2 = useMediaQuery('(max-width:1360px)')
  const [selectCountry, setSelectCountry] = useState<any>({ value: '', label: '', flagCountryCode: '' })
  const [countryOptions, setCountryOption] = useState<any>([])
  const [popularCountry, setPopularCountry] = useState<string>('')
  const [filteredData, setFilteredData] = useState<any>()
  const [showModal, setShowModal] = useState<any>(false)
  const [showGiftModal, setShowGiftModal] = useState<any>(false)
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [countryCount, setCountryCount] = useState<number>(0)
  const [updatedCountryList, setUpdatedCountryList] = useState([])
  const { connectedAccount, apiState } = useWalletContext()
  const [giftPlan, setGiftPlan] = useState<any>([])
  const filterValue = selectCountry?.value ? selectCountry?.value : ''
  const { data: countryDataList, isLoading } = useQuery('getCountries', () => fetchQueries(fetchCountries(variables)))
  const variables = {}
  const navigate = useNavigate()
  const { data: dummyCountryData } = useQuery('getDummyCountries', () => fetchQueries(fetchDummyCountries(variable)))
  const variable = {
    where: {
      country: ['Europe', 'Global', 'United States'],
    },
    packsWhere2: {
      dataLimitInBytes: 5368709120,
    },
  }
  // console.log('dummyCountryData', dummyCountryData)
  // useEffect(() => {
  //   if (countryList.length) {
  //     let list = countryList.map((data: any) => {
  //       return { value: data.name, label: data.name }
  //     })
  //     setCountryOption(list)
  //   }
  // }, [])

  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <>
        <components.Control {...props}>
          {selectCountry === null ||
          selectCountry?.flagCountryCode === '' ||
          selectCountry?.flagCountryCode === 'Global' ? (
            <img src={WorldLogo} alt="WorldLogo" style={{ width: '21px', height: '28px' }} />
          ) : (
            <ReactCountryFlag countryCode={selectCountry?.flagCountryCode} />
          )}
          {children} <img src={DownArrow} alt="arrow" />
        </components.Control>
      </>
    )
  }

  useEffect(() => {
    let temp = dummyCountryData?.data?.data?.countries.sort((a: any, b: any) =>
      b.packs[0].priceInWifiToken.toString().localeCompare(a.packs[0].priceInWifiToken.toString()),
    )
    setUpdatedCountryList(temp)
  }, [dummyCountryData])

  useEffect(() => {
    if (!isLoading) {
      const countryList = countryDataList?.data?.data?.countries
        ?.filter((country: any) => country.packs.length > 0)
        .map((data: any) => {
          return { value: data.country, label: data.country, flagCountryCode: data?.flagCountryCode }
        })

      const updatedCountryList = Array.from(new Set(countryList?.map((el: any) => JSON.stringify(el)))).map((el: any) =>
        JSON.parse(el),
      )

      setCountryOption(updatedCountryList)
      setCountryCount(updatedCountryList.length)
    }
  }, [countryDataList])

  useEffect(() => {
    if (popularCountry !== '') {
      let filterdData = countryDataList?.data.data.countries.filter((data: any) => data.country === popularCountry)
      setFilteredData(filterdData)
    }
  }, [popularCountry, countryDataList])

  useEffect(() => {
    if (filterValue !== '') {
      setPopularCountry('')
    }
  }, [filterValue])

  useEffect(() => {
    if (apiState === API_STATE.READY)
      if (id !== undefined && showGiftModal !== true) {
        //here goes the code to varify the URL
        setShowGiftModal(true)
        setIsModalLoading(true)
        validateCode(id)
          .then((res) => {
            if (res?.status === true) {
              return getAccountInstances(res?.decrypted?.owner, res?.decrypted?.instanceId)
            } else {
              return Promise.reject('API is not ready')
            }
          })
          .then((res) => {
            let variables = {
              where: {
                id: res?.metadata.id,
              },
            }
            fetchQueries(fetchPacksById(variables)).then((res: any) => {
              setGiftPlan(res?.data?.data?.packs)
              setIsModalLoading(false)
            })
          })
          .catch((err) => {
            setShowGiftModal(false)

            setIsModalLoading(false)
            navigate('/shop')
          })
      }
  }, [id, showGiftModal, connectedAccount, apiState])


  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <WarningAlert
        type="warning"
        content="This runs on devnet and no real money is involved. Expect things to break with future releases."
      />
      <S.ShopHeader>
        <S.HeaderBgText>
          Connect to earn <br />
          Global mobile data in 118 countries
        </S.HeaderBgText>
      </S.ShopHeader>
      <S.ShopContainer maxWidth={false}>
        <S.ShopBar>
          <Select
            options={countryOptions}
            value={selectCountry?.value && selectCountry}
            onChange={setSelectCountry}
            isSearchable
            components={{ Control }}
            placeholder={'Choose your country'}
            isClearable
            // closeMenuOnSelect={false}
            styles={{
              placeholder: (base) => ({
                ...base,
                opacity: '0.8',
                fontWeight: 500,
                fontSize: '1.2rem',
                lineHeight: '31px',
                color: '#000000',
              }),
              control: (base) => ({
                ...base,
                background: '#e2e7ee',
                border: 'none',
                borderRadius: '0.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                fontWeight: 500,
                fontSize: '1.5rem',
                lineHeight: '1.9375rem',
                color: '#000000',
                cursor: 'pointer',
                padding: '0.5rem 2rem',
                width: '25rem',
              }),
              indicatorSeparator: (base) => ({
                ...base,
                display: 'none',
              }),
              // clearIndicator: (base) => ({
              //   ...base,
              //   cursor: 'pointer',
              //   color: 'black',
              // }),
              dropdownIndicator: (base) => ({
                ...base,
                display: 'none',
              }),
              container: (base) => ({
                ...base,
                maxWidth: '26rem',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '0 1rem',
              }),
              menu: (base) => ({
                ...base,
                maxWidth: '23rem',
                '@media(max-width:600px)': {
                  maxWidth: '20rem',
                },
              }),
            }}
          />
          <S.PopularBox>
            <S.PopularText>Popular: </S.PopularText>
            <S.PopularCountryContainer>
              {POPULAR_COUNTRIES.map((country) => {
                return (
                  <S.PopularCard
                    sx={
                      country.name === popularCountry
                        ? { border: '1px solid rgb(69, 181, 73)', color: 'rgb(69, 181, 73)', fontWeight: '500' }
                        : {}
                    }
                    key={country.id}
                    onClick={() => {
                      setSelectCountry({ value: '', label: '' })
                      setPopularCountry((prev: any) => {
                        if (prev === country.name) {
                          return ''
                        } else {
                          return country.name
                        }
                      })
                    }}
                  >
                    <Typography>
                      {country.name} &nbsp;
                      <ReactCountryFlag countryCode={country.flag} />
                    </Typography>
                  </S.PopularCard>
                )
              })}
            </S.PopularCountryContainer>
          </S.PopularBox>
        </S.ShopBar>
        {filterValue === '' && (
          <S.DataPlansDataContainer
            item
            sx={{
              justifyContent: `${match2 ? 'center' : 'space-between'}`,
              gap: `${match2 ? '2rem' : '0'}`,
            }}
          >
            {popularCountry === '' ? (
              <>
                {updatedCountryList?.map((val: any) => {
                  let country = val.country
                  let countryColor = val.countryColor
                  let countryFlag = val.flagCountryCode
                  return (
                    <Fragment key={val.id}>
                      {val.packs.map((pack: any) => {
                        return (
                          <PrimaryCard
                            key={pack.id}
                            data={pack}
                            countryData={val}
                            country={country}
                            color={countryColor}
                            flag={countryFlag}
                            location="shop"
                            handleOpen={() => setShowModal(true)}
                          />
                        )
                      })}
                    </Fragment>
                  )
                })}
              </>
            ) : (
              <>
                {filteredData?.map((val: any) => {
                  let country = val.country
                  let countryColor = val.countryColor
                  let countryFlag = val.flagCountryCode

                  return (
                    <Fragment key={val.id}>
                      {val.packs.length > 0 ? (
                        <Fragment key={val.id}>
                          {val.packs.map((pack: any) => {
                            return (
                              <PrimaryCard
                                key={pack.id}
                                data={pack}
                                country={country}
                                countryData={val}
                                flag={countryFlag}
                                color={countryColor}
                                location="shop"
                                handleOpen={() => setShowModal(true)}
                              />
                            )
                          })}
                        </Fragment>
                      ) : (
                        <Box
                          sx={{
                            height: '10vh',
                            display: 'grid',
                            placeContent: 'center',
                            width: '100%',
                          }}
                        >
                          No Packs Found
                        </Box>
                      )}
                    </Fragment>
                  )
                })}
              </>
            )}
          </S.DataPlansDataContainer>
        )}

        {/* <PackContainerPaginated country={filterValue} /> */}
        {/* <PackContainerScroll country={filterValue} /> */}
        <PacksContainer country={filterValue} count={countryCount} />
        <PurchaseModal show={showModal} handleCloseModal={handleClose} />
        <GiftModal
          show={showGiftModal}
          plan={giftPlan}
          handleCloseModal={() => setShowGiftModal(false)}
          isLoading={isModalLoading}
        />
      </S.ShopContainer>
    </>
  )
}

export default Shop
