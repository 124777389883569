import styled from '@emotion/styled'
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material'
import BgImg from 'assets/icons/ShopPage.svg'

export namespace S {
  export const ShopHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: '100%',
    // width: '100%',
    background: '#DBE8F1',
    padding: '1rem 10rem',
    '@media (max-width: 900px)': {
      padding: '1rem 4rem',
      textAlign: 'center',
    },
    '@media (max-width: 600px)': {
      padding: '1rem 2rem',
    },
  }))

  export const ShopBar = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '1rem',
    width: '100%',
    padding: '0 4rem 0 3rem',
    boxSizing: 'border-box',
  }))

  export const HeaderBgText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.4375rem',
    width: '100%',
    maxWidth: '1400px',
    margin: 'auto',
    textAlign: 'center',
  }))

  export const PopularBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flex: '1 1 0',
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 10,
      flexGrow: 0,
    },
  }))

  export const PopularText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.5rem',
    fontWeight: '500',
    marginRight: '1rem',
  }))

  export const PopularCard = styled(Box)(({ theme }: any) => ({
    height: '52px',
    width: '100%',
    minWidth: '162px',
    maxWidth: '400px',
    display: 'grid',
    placeItems: 'center',
    background: '#ffffff',
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: ' 4px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }))

  export const PopularCountryContainer = styled(Box)(({ theme }: any) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '2rem',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  }))

  export const ShopContainer = styled(Container)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '3rem 0 ',
    flexWrap: 'wrap',
    gap: '2rem',
    width: '100%',
    maxWidth: '1400px',
    minHeight: 'calc(100vh - 21rem)',
  }))

  export const PageBtn = styled(Button)(({ theme }: any) => ({
    background: '#66717B',
    borderRadius: '8px',
    minWidth: '44px !important',
    height: '44px',
    color: theme.palette.primary.contrastText,
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      background: `${theme.palette.primary.main} !important`,
      boxShadow: 'none',
      color: theme.palette.primary.contrastText,
    },
  }))

  export const PageSideBtn = styled(PageBtn)(({ theme }: any) => ({
    background: theme.palette.primary.light,
    borderRadius: '8px',
    color: 'black',
  }))

  export const MoreContainer = styled(Container)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: '2rem',
    padding: '3rem',
    minHeight: 'calc(100vh - 21rem)',
  }))

  export const MoreDetailCard = styled(Card)(({ theme }: any) => ({
    width: '100%',
    maxWidth: '51rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
    borderRadius: '8px',
  }))

  export const MoreCardHeader = styled(CardContent)(({ theme }: any) => ({
    width: '88%',
    padding: '1.5rem 3.5rem',
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px 8px 0px 0px',
  }))

  export const ContentBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
  }))

  export const HeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: '2.9375rem',
    color: theme.palette.primary.contrastText,
  }))

  export const Main = styled(Box)(({ theme }: any) => ({
    margin: '2rem',
    width: '88%',
  }))

  export const MainContent = styled(Box)(({ theme }: any) => ({
    gap: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  }))

  export const MainContentBox = styled(ContentBox)(({ theme }: any) => ({
    alignItems: 'start',
    flexDirection: 'column',
  }))

  export const MainSubContentBox = styled(MainContentBox)(({ theme }: any) => ({
    gap: '1.5rem',
  }))

  export const MainContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
    color: theme.palette.text.primary,
  }))

  export const MainContentSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '1.9375rem',
    color: theme.palette.text.primary,
    textAlign: 'right',
  }))

  export const SubContentText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.3125rem',
    color: theme.palette.primary.main,
    display: 'flex',
  }))

  export const SubContentSubText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '1.3125rem',
    color: '#66717B',
  }))

  export const CheckBox = styled.input(({ theme }: any) => ({
    width: '21px',
    height: '21px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
  }))

  export const ConditionText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    color: theme.palette.text.primary,
  }))

  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: theme.palette.common.white,
    minWidth: '17rem !important',
    height: '50px',
    boxShadow: 'none',
    border: 'none',
    fontWeight: 700,
    padding: '0rem 0.625rem',
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '12rem !important',
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: '12rem !important',
    },
  }))

  export const BackBtn = styled(ConfirmBtn)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    minWidth: '18rem !important',
    backgroundColor: 'rgba(69, 181, 73, 0.1)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  }))

  export const ActiveBtn = styled(ConfirmBtn)(({ theme }: any) => ({
    minWidth: '18rem !important',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: '#2f592f',
    },
  }))

  export const GntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2.625rem',
    lineHeight: '3.4375rem',
    color: theme.palette.text.primary,
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
    },
  }))

  export const PurchaseContent = styled(ContentBox)(({ theme }: any) => ({
    gap: '2rem',
    flexDirection: 'column',
  }))

  export const PurchaseContentBox = styled(ContentBox)(({ theme }: any) => ({
    background: '#f3f5f7',
    height: '87px',
    width: '100%',
    borderRadius: '8px',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '1.9375rem',
    color: '#000000',
    textAlign: 'center',
  }))

  export const RedeemMain = styled(ContentBox)(({ theme }: any) => ({
    gap: '2rem',
    flexWrap: 'wrap',
  }))

  export const RedeemMainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.75rem',
    letterSpacing: '-0.02em',
    color: theme.palette.text.primary,
  }))

  export const RedeemContentText = styled(RedeemMainText)(({ theme }: any) => ({
    lineHeight: '21px',
    color: '#66717B',
  }))

  export const QrCodeContent = styled(Box)(({ theme }: any) => ({
    background: theme.palette.primary.contrastText,
    padding: '0.8rem',
    border: '5px solid #CED6E0',
    borderRadius: '8px',
  }))

  export const CustomShopContainer = styled(Box)(({ theme }: any) => ({
    // background: theme.palette.common.white,
    height: '100%',
    width: '90%',
    display: 'grid',
    gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
    gap: '2rem',
    '@media (min-width: 576px)': {
      gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
    },
    '@media (min-width: 1024px)': {
      gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
    },
  }))
  export const CountryCard = styled(Box)(({ theme }: any) => ({
    background: theme.palette.common.white,
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }))
  export const CardContainer = styled(Box)(({ theme }: any) => ({
    // height: '100%',
    left: 0,
    // width: '20rem',
    boxSizing: 'border-box',
  }))
  export const DataPlansDataContainer = styled(Grid)(({ theme }: any) => ({
    display: 'flex',
    allignItems: 'center',
    width: '93%',
    flexWrap: 'wrap',
    padding: '0 1rem',
  }))
  export const PlansDataContainer = styled(DataPlansDataContainer)(({ theme }: any) => ({
    display: 'flex',
    allignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    padding: '2rem 0',
  }))
  export const NoPacksContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    placeContent: 'center',
  }))
}
