import { apiProvider } from 'polkadot'
import { hexToUtf8 } from 'utils/utils'

export const getClassDetails = async (classId: any) => {
  apiProvider.query.dct.classes(classId, (res: any) => {
    if (!res.isEmpty) {
    }
  })
}

export const getInstanceId = async () => {
  try {
    const res = await apiProvider.query.dct.currentInstanceId()
    return res.isEmpty ? null : JSON.parse(res)
  } catch (e) {
    return null
  }
}

export const getAccountInstances = async (account: any, instanceId: number) => {
  let res = await apiProvider.query.dct.accountInstances(account, instanceId)
  let instances = res.isEmpty ? null : JSON.parse(res)
  if (instances) {
    let data: any
    let metadata: any = hexToUtf8(instances.metadata)
    try {
      data = JSON.parse(metadata)
    } catch (e) {
      data = metadata
    }
    return { metadata: data }
  }
  return null
}

export const getInstanceByAccount = async (account: any) => {
  let res = await apiProvider.query.dct.instancesByAccount(account.address)
  let data = res.isEmpty ? null : JSON.parse(res)
  if (data) {
    let activeDatas = await data.map((item: any) => {
      let data: any
      let metadataUft: any = hexToUtf8(item[1].metadata)
      try {
        data = JSON.parse(metadataUft)
      } catch (e) {
        data = metadataUft
      }
      return {
        id: item[0],
        metadata: data,
      }
    })
    return activeDatas
  }
  return null
}

// export const getDctList = async (account: any) => {
//   let dctList: any
//   return apiProvider.query.dct
//     .get_dtc_by_account(account)
//     .then((res: any) => {
//       if (!res.isEmpty) {
//         dctList = JSON.parse(res)
//         return dctList
//       }
//     })
//     .catch((err: any) => {
//     })
// }
