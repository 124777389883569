import React, { FC, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Select, { components, ControlProps } from 'react-select'
import ReactCountryFlag from 'react-country-flag'
import SuccessCreers from 'assets/success_cheers.svg'
import WarningIcon from 'assets/icons/WarningIcon.svg'

import { S } from './modal.styles'
import DownArrow from 'assets/icons/DownArrowWhite.svg'
import QRImg from 'assets/gaintQR.png'
import CloseIcon from 'assets/icons/CloseIcon.svg'
import WorldIcon from 'assets/icons/WorldIcon.svg'
import FreeDataImg from 'assets/freeDataImg.png'

import { useParams, useNavigate } from 'react-router-dom'
import { CircularProgress, Grid, Tooltip } from '@mui/material'
import QRCode from 'react-qr-code'

interface Props {
  show: boolean
  handleCloseModal: () => void
  isLoading: boolean
  plan: any
  location?: string
}

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <>
      <components.Control {...props}>
        {children} <img src={DownArrow} alt="arrow" />
      </components.Control>
    </>
  )
}

const GiftModal: FC<Props> = ({ show, handleCloseModal, location, plan, isLoading }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [urlPlan, setUrlPlan] = useState<any>({})
  const [type, setType] = useState<number>(0)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [giftCode, setGiftCode] = useState<string>('')
  const [claimed, setClaimed] = useState<boolean>(false)
  // useEffect(() => {
  //   let interval: any
  //   if (show && !isLoading) {
  //     interval = setInterval(() => {
  //       setClaimed(true)
  //     }, 10000)
  //   }
  //   return () => clearInterval(interval)
  // }, [show, isLoading])

  useEffect(() => {
    if (id !== undefined) {
      setType(1)
      if (plan?.length) {
        setUrlPlan(plan[0])
      }
    }
  }, [id, plan])

  const handleGiftSuccess = async (type: any, code: string) => {
    console.log('GIFT CODE', code)
    setGiftCode(code)
    setType(type)
  }

  return (
    <div>
      <Modal
        open={show}
        onClose={() => {
          setType(0)
          setIsChecked(false)
          handleCloseModal()
          location === 'landing-page' ? navigate('/') : navigate('/shop')
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.ModalMainContainer>
          {isLoading ? (
            <>
              <Grid sx={{ display: 'flex', height: '500px', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Grid>
            </>
          ) : (
            <>
              <S.ModalHeader
                sx={{
                  background: `linear-gradient(113.74deg, ${
                    urlPlan?.country?.countryColor?.start ? urlPlan?.country?.countryColor?.start : '#1471DE'
                  } 9.6%, ${
                    urlPlan?.country?.countryColor?.end ? urlPlan?.country?.countryColor?.end : '#109D6A'
                  } 91.34%);`,
                }}
              >
                {' '}
                <Box>
                  <Typography sx={{ fontWeight: '700', fontSize: '2.25rem' }}>
                    {urlPlan?.dataLimitInBytes / (1024 * 1024) / 1024}GB
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontWeight: 700, fontSize: '2rem', margin: '0 0.5rem 0 0' }}>
                    {urlPlan?.country?.country}
                  </p>
                  {urlPlan?.country?.flagCountryCode === 'Global' ? (
                    <img src={WorldIcon} alt="currency img" style={{ width: '28px', height: '28px' }} />
                  ) : (
                    <ReactCountryFlag countryCode={urlPlan?.country?.flagCountryCode} style={{ paddingTop: '10px' }} />
                  )}
                </Box>
                <img
                  src={CloseIcon}
                  alt=""
                  onClick={() => {
                    setType(0)
                    handleCloseModal()
                    setIsChecked(false)
                    location === 'landing-page' ? navigate('/') : navigate('/shop')
                  }}
                  style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }}
                />
              </S.ModalHeader>
              {claimed ? (
                <S.GiftSuccessContainer>
                  <Typography sx={{ fontWeight: '700', fontSize: '1.5rem', textAlign: 'center' }}>
                    You successfully activated your plan!
                  </Typography>
                  <S.GiftCheers>
                    <img
                      src={SuccessCreers}
                      loading="lazy"
                      alt="qr-img"
                      style={{
                        width: '100%',
                        height: '256px',
                      }}
                    />
                  </S.GiftCheers>
                  <Typography
                    sx={{ fontWeight: '400', fontSize: '1.5rem', textAlign: 'center', paddingBottom: '1.375rem' }}
                  >
                    Now it’s your turn! Earn GIANT when you gift data.
                  </Typography>
                  <S.ConfirmBtn
                    onClick={() => {
                      navigate('/shop')
                      handleCloseModal()
                    }}
                  >
                    Gift and Earn
                  </S.ConfirmBtn>
                </S.GiftSuccessContainer>
              ) : (
                <>
                  <S.ModalWarning>
                    {' '}
                    <img src={WarningIcon} alt="" /> &nbsp; This runs on devnet and no real money or data is involved.
                  </S.ModalWarning>
                  <S.CongratsText sx={{ textAlign: 'center', padding: '4rem 0 0 0' }}>
                    Thank you for participating in our devnet!
                  </S.CongratsText>
                  <S.CustomDynamicContainer>
                    <S.NotDoneContainer>
                      <S.TextWrapper>
                        <S.RoundWrapper>
                          1
                          <span
                            style={{
                              position: 'absolute',
                              background: '#00C853',
                              height: '100%',
                              width: '2px',
                              bottom: '-1.8rem',
                              left: '1rem',
                            }}
                          ></span>
                        </S.RoundWrapper>
                        <Box>
                          <S.ShareHeaderText>Share with your community</S.ShareHeaderText>
                          <S.ShareHeaderSubText>
                            Share your referral code <b>GNT-abcdfg</b> with friends for a chance to earn mobile data for 12
                            months
                          </S.ShareHeaderSubText>
                        </Box>
                      </S.TextWrapper>
                      <S.TextWrapper>
                        <S.RoundWrapper>
                          2
                          <span
                            style={{
                              position: 'absolute',
                              background: '#00C853',
                              height: '100%',
                              width: '2px',
                              bottom: '-1.8rem',
                              left: '1rem',
                            }}
                          ></span>
                        </S.RoundWrapper>

                        <Box>
                          <S.ShareHeaderText>Scan QR code for real data and rewards</S.ShareHeaderText>
                          <S.ShareHeaderSubText>
                            Use your phone camera to scan the QR code to download the GIANT Connect mobile app
                          </S.ShareHeaderSubText>
                        </Box>
                      </S.TextWrapper>
                      <S.TextWrapper>
                        <S.RoundWrapper>3</S.RoundWrapper>
                        <Box>
                          <S.ShareHeaderText>Connect and earn</S.ShareHeaderText>
                          <S.ShareHeaderSubText>
                            Buy cellular eSIM data in 118 countries through GIANT Connect to earn GIANT rewards
                          </S.ShareHeaderSubText>
                        </Box>
                      </S.TextWrapper>
                    </S.NotDoneContainer>
                    <Box
                      sx={{
                        display: 'grid',
                        placeContent: 'center',
                        border: '5.98131px solid #CED6E0',
                        borderRadius: '9.5px',
                        padding: '20px',
                      }}
                    >
                      <QRCode size={200} value="https://devapp.giantprotocol.org/thanks" />
                    </Box>
                  </S.CustomDynamicContainer>
                </>
              )}
            </>
          )}
        </S.ModalMainContainer>
      </Modal>
    </div>
  )
}

export default GiftModal
