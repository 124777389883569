import React, { FC, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import NotFound from '../components/notFound/NotFound'
import LandingPage from '../views/landing-page'
import Shop from '../views/shop'
import Wallet from '../views/wallet'
import Swap from 'views/swap'
import Vote from 'views/vote'
import ThanksPage from 'views/thanks-page'

const AppRouters: FC = () => {
  const [prevPath, setPrevPath] = React.useState('')
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== prevPath) {
      console.log(location)
      setPrevPath(location.pathname)
      ;(window as any).analytics.page()
    }
  }, [location.pathname, prevPath])

  return (
    <Routes>
      <Route path={`/`} element={<LandingPage />} />
      <Route path={`/home`} element={<LandingPage />} />
      <Route path={`/earn`} element={<Wallet />} />
      <Route path={`/shop`} element={<Shop />} />
      <Route path={`/shop/gift/:id`} element={<Shop />} />
      <Route
        path={`/gift/:id`}
        element={<Navigate to={`/shop/gift/${window.location.pathname.split('/')[2]}`} replace />}
      />
      {/* <Route path={`/dashboard`} element={<Dashboard />} /> */}
      {/* <Route path={`/mypage`} element={<MyPage />} /> */}
      <Route path={`/swap`} element={<Swap />} />
      <Route path={`/vote`} element={<Vote />} />
      <Route path={`/thanks`} element={<ThanksPage />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRouters
