import * as React from 'react'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import FooterLogo from 'assets/icons/GiantconnectFooterLogo.svg'

import { SOCIAL_ICONSTYPE } from '../../../types'
import { SOCIAL_ICONS } from '../../../utils/constants'
import AppleStore from 'assets/AppleStore.svg'
import PlayStore from 'assets/PlayStore.svg'
import { S } from './styled'

export const Footer = () => { 
  return (
    <>
      <S.CustomFooter maxWidth={false}>
        <S.FooterContainer container>
          <S.FooterLogoContainer item>
            <img style={{ height: '3rem', marginBottom: '1rem' }} src={FooterLogo} alt="" />
            <S.FooterSubText>
              A global connectivity economy that is <br />
              owned and governed by its users.
            </S.FooterSubText>
          </S.FooterLogoContainer>
          <S.FooterSubContainer item>
            <S.FooterHeaderText>Foundation</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks
              // onClick={() => {
              //   ;(window as any).analytics.trackLink(aboutLink, 'Clicked on About link')
              // }}
              >
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on About link')
                    window.open('https://giantprotocol.org/about/')
                  }}
                  disableRipple={true}
                >
                  About
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Blog link')
                    window.open('https://giantprotocol.org/blog/')
                  }}
                  disableRipple={true}
                >
                  Blog
                </S.LinkButton>
              </S.FooterLinks>
              {/* <S.FooterLink to="#">Contact us</S.FooterLink> */}
            </Box>
          </S.FooterSubContainer>
          <S.FooterSubContainer item>
            <S.FooterHeaderText>Get Involved</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Be an Ambassador link')
                    window.open('https://admin252.typeform.com/to/iaZWlIlK')
                  }}
                  disableRipple={true}
                >
                  Be an Ambassador
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Be a Provider link')
                    window.open('https://admin252.typeform.com/to/w3LoSM5S')
                  }}
                  disableRipple={true}
                >
                  Be a Provider
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Be a Validator link')
                    window.open('https://admin252.typeform.com/to/VMdsrhFh"')
                  }}
                  disableRipple={true}
                >
                  Be a Validator
                </S.LinkButton>
              </S.FooterLinks>
            </Box>
          </S.FooterSubContainer>
          <S.FooterSubContainer item>
            <S.FooterHeaderText>Support</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Installation link')
                    window.open('https://giantconnect.zendesk.com/hc/en-us/articles/4419826397847')
                  }}
                  disableRipple={true}
                >
                  Installation Guide
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on FAQs link')
                    window.open('https://giantconnect.zendesk.com/hc/en-us')
                  }}
                  disableRipple={true}
                >
                  FAQs
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Contact Us link')
                    window.open('https://giantprotocol.org/contact/')
                  }}
                  disableRipple={true}
                >
                  Contact Us
                </S.LinkButton>
              </S.FooterLinks>
            </Box>
          </S.FooterSubContainer>

          <S.FooterCustomSubContainer item>
            <S.FooterHeaderText>Download App</S.FooterHeaderText>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <S.FooterLinks>
                <img
                  src={PlayStore}
                  alt=""
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on PlayStore link')
                    window.open('https://wific0in.app.link/nJP40nhAgrb')
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </S.FooterLinks>
              <S.FooterLinks>
                <img
                  src={AppleStore}
                  alt=""
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on AppStore link')
                    window.open('https://wific0in.app.link/nJP40nhAgrb')
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </S.FooterLinks>
            </Box>
          </S.FooterCustomSubContainer>
          <S.SocialMediaContainer
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid sx={{ display: 'flex' }}>
              {SOCIAL_ICONS.map((icon: SOCIAL_ICONSTYPE) => {
                return (
                  <S.LinkButton
                    onClick={async () => {
                      await(window as any).analytics.track(`Clicked on ${icon.name}`)
                      window.open(icon.link.toString())
                    }}
                    disableRipple={true}
                  >
                    <img src={icon.icon} style={{ marginRight: '1rem', cursor: 'pointer' }} alt="" />
                  </S.LinkButton>
                  // <a href={icon.link.toString()} rel="noreferrer" key={icon.id} target="_blank">
                  //   <img src={icon.icon} style={{ marginRight: '1rem', cursor: 'pointer' }} alt="" />
                  // </a>
                )
              })}
            </Grid>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Privacy Policy link')
                    window.open('https://giantprotocol.org/privacy/')
                  }}
                >
                  Privacy Policy
                </S.LinkButton>
              </S.FooterLinks>
              <S.FooterLinks>
                <S.LinkButton
                  onClick={async () => {
                    await (window as any).analytics.track('Clicked on Terms of service link')
                    window.open('https://giantprotocol.org/tos/')
                  }}
                >
                  Terms of service
                </S.LinkButton>
              </S.FooterLinks>
            </Box>
          </S.SocialMediaContainer>
        </S.FooterContainer>
      </S.CustomFooter>
      <S.CopyrightContainer maxWidth={false}>
        @2022, GIANT Protocol Foundation. All rights reserved
      </S.CopyrightContainer>
    </>
  )
}
