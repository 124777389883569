import { apiProvider } from 'polkadot'
import { toDecimal } from 'utils/utils'

export const getCurrentRewardDemand = async () => {
  try {
    let res = await apiProvider.query.provider.currentRewardsDemand()
    let reward = !res.isEmpty ? JSON.parse(res) : 0
    let deciRewardDemand = toDecimal(reward, 6).full
    return deciRewardDemand
  } catch (e) {
    return 0
  }
}
