import { styled } from '@mui/system'
import { Grid, Button, Container, Popper, InputBase, Select, Box, TextField, Typography } from '@mui/material'

export namespace S {
  export const TokenCard = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
    borderRadius: '8px',
    justifyContent: 'space-between',
    padding: '0 2rem 2rem 2rem',
    minWidth: '400px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  }))

  export const CustomGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    borderBottom: '1px solid #DEE7FD',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0',
    gridColumnGap: '5rem',
    flexWrap: 'wrap',
  }))

  export const PageToggleBtn = styled(Button)(({ theme }: any) => ({
    minWidth: '44px',
    height: '44px',
    borderRadius: '8px',
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
    '&:hover': {
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.contrastText,
    },
  }))

  export const ProtocolChartCard = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
    borderRadius: '8px',
    padding: '2rem',
    minWidth: '300px',
    height: '100%',
    justifyContent: 'space-between',
  }))

  export const WalletContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    // background: '#edeff2',
    height: '100%',
    gridRowGap: '1.5rem',
    maxWidth: 'false',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1800px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5rem 2rem',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '5rem 7rem',
    },
  }))

  export const CustomPopper = styled(Popper)(({ theme }) => ({
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    left: '2.8125rem !important',
    zIndex: 101,
  }))

  export const CustomOverlayPopper = styled(CustomPopper)(({ theme }) => ({
    left: '1.8125rem !important',
    top: '-15rem !important',
  }))

  export const DropList = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.3125rem',
    cursor: 'pointer',
  }))

  export const SearchGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '40%',
    maxWidth: '28.125rem',
    minWidth: '8rem',
    borderRadius: '8px',
    height: '44px',
    alignSelf: 'center',
  }))

  export const Search = styled(Grid)(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    width: '100%',
  }))

  export const SearchIconWrapper = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      width: '100%',
    },
    width: '100%',
  }))

  export const CustomSelect = styled(Select)(({ theme }) => ({
    textTransform: 'none',
    height: '44px',
    width: '68px',
    textAlign: 'center',
    borderRadius: '8px !important',
    background: theme.palette.grey[200],
  }))

  export const StakeOverlayCard = styled(Grid)(({ theme }) => ({
    // background: theme.palette.common.white,
    display: 'flex',
    gap: '1rem',
    padding: '1.25rem',
    alignItems: 'center',
    borderRadius: '0.5rem',
  }))

  export const CustomStakeOverlayCard = styled(StakeOverlayCard)(({ theme }) => ({
    // background: theme.palette.common.white,
    padding: '0',
  }))

  export const StakeTextField = styled(TextField)`
    .MuiInputBase-root {
      background-color: rgba(206, 214, 224, 0.25);
      height: 2.625rem;
    }

    .Mui-focused {
      border: none;
    }
    color: black;
  `
  export const Backdrop = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100vh',
    zIndex: '100',
    background: 'rgb(167 180 171 / 46%)',
  }))
  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1.25rem',
    borderRadius: '0.5rem',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
  }))
  export const MyWalletSubText = styled(Typography)(({ theme }) => ({
    fontSize: '1.125rem',
    padding: '2rem 0 1.5rem 0',
    color: '#66717B',
  }))
  export const MyWalletTextContainer = styled(Box)(({ theme }) => ({
    width: '70%',
    display: 'grid',
    placeContent: 'center',
  }))
  export const MyWalletHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: '1.125rem',
    fontWeight: '700',
  }))
  export const ViewAllText = styled(MyWalletHeaderText)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }))
  export const FaucetContainer = styled(MyWalletHeaderText)(({ theme }) => ({
    // height: '8rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  }))
  export const EmailText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '18px',
    margin: '0.2rem 0',
  }))
  export const EmailSubTextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }))
  export const EmailSubText = styled(EmailText)(({ theme }) => ({
    color: '#66717B',
  }))
  export const ErrorText = styled(EmailSubText)(({ theme }) => ({
    color: '#DE1428',
    fontWeight: 400,
  }))
  export const CongratsText = styled(EmailText)(({ theme }) => ({
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '15px',
  }))
  export const TimerText = styled(CongratsText)(({ theme }) => ({
    fontSize: '29px',
  }))
  export const TimerSubText = styled(CongratsText)(({ theme }) => ({
    fontSize: '10px',
    lineHeight: '13px',
    marginRight: '1rem',
  }))
  export const MyWalletSuccessContainer = styled(Box)(({ theme }) => ({
    background: 'rgba(69, 181, 73, 0.1)',
    height: '123px',
    width: '332px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    justifyContent: 'space-between',
  }))

  export const MyWalletNextAttemptContainer = styled(Box)(({ theme }) => ({
    // height: '123px',
    width: '332px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0 1rem',
  }))
  export const StakeTextContainer = styled(Box)(({ theme }) => ({
    dispaly: 'flex',
    position: 'relative',
    paddingTop: '0.5rem',
  }))
  export const FaucetInput = styled(Box)(({ theme }) => ({
    dispaly: 'flex',
    position: 'relative',
  }))
  export const FaucetTextField = styled(TextField)`
    .MuiInputBase-root {
      background-color: rgba(206, 214, 224, 0.25);
      height: 2.75rem;
      border-radius: 8px;
    }
    .MuiFilledInput-input {
      padding: 1rem;
      border-radius: 8px;
    }
    .Mui-focused {
      border: none;
    }
    color: black;
  `
  export const MyDataPlansHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: '1.1875rem',
  }))
  export const MyDataPlansBody = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2rem',
    justifyContent: 'center',
  }))
}
