import { FC } from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

interface Props {
  style: {
    height: string
    width: string
    marginLeft?: string
    alignSelf?: string
  }
  children: string
  onClick?: (e?: any) => void
}

const SecondaryButton: FC<Props> = ({ style, children, onClick }) => {
  return (
    <CustomButton onClick={onClick} style={style}>
      {children}
    </CustomButton>
  )
}

const CustomButton = styled(Button)(({ theme }: any) => ({
  color: theme.palette.primary.main,
  background: theme.palette.secondary.main,
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '1.25rem',
  borderRadius: '0.5rem',
  '&:hover': {
    opacity: 0.7,
    border: `0.125rem solid ${theme.palette.primary.main}`,
  },
}))

export default SecondaryButton
