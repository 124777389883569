import { ASSETID } from 'contexts/wallet'
import { apiProvider } from 'polkadot'
import { hexToUtf8, toDecimal } from 'utils/utils'

export const getAssetBalance = async (account: any) => {
  try {
    // with assetId
    // const res = await apiProvider.query.assets.account(assetId, account.address)
    // const balance = res.isEmpty ? 0 : JSON.parse(res)
    // return toDecimal(balance.balance, decimal)

    // without assetId
    const res = await apiProvider.query.balances.account(account.address)
    const balance = res.isEmpty ? 0 : JSON.parse(res)
    return balance.freeBalance
  } catch (e) {
    return null
  }
}

// export const getMetadata = async () => {
//   let res = await apiProvider.query.assets.metadata(ASSETID)
//   let metadata = res.isEmpty ? null : JSON.parse(res)
//   return metadata?.name
//     ? { ...metadata, name: hexToUtf8(metadata.name.slice(2)), symbol: hexToUtf8(metadata.symbol.slice(2)) }
//     : null
// }
