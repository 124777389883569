import { apiProvider } from "polkadot"

export const getProviderAccount = async () => {
    try {
        const providerAccount = await apiProvider.query.giantSudo.providerAccount()
        return providerAccount.isEmpty ? null : providerAccount.toString()
    } catch (e) {
        console.log(e)
        return null
    }
}



export const getInstanceDetail = async () => { }
