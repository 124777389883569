export function setItem<T>(key: string, item: T): void {
  localStorage.setItem(key, JSON.stringify(item))
}

// export function getItem<T>(key: string): T | null {
//     const data: string | null = localStorage.getItem(key);

//     if (data !== null) {
//         return JSON.parse(data);
//     }

//     return null;
// };

export function getItem<T>(key: string, otherwise?: T): T | string {
  const data: string | null = localStorage.getItem(key)

  if (data !== null) {
    return JSON.parse(data)
  }

  if (otherwise) {
    return otherwise
  }

  return ''
}
