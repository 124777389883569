import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { S } from './thankspage.style'
import ConnectImg from '../../assets/ConnectEarn.svg'
import PlayStoreImg from '../../assets/PlayStore.svg'
import AppleStore from '../../assets/AppleStore.svg'
import ConnectedWorldImg from '../../assets/Connected world.svg'
import DiscordImg from '../../assets/Discord.svg'
import TelegramImg from '../../assets/Telegram.svg'
import PacksGroupImg from '../../assets/PacksGroup.svg'

const ThanksPage = () => {
  return (
    <S.CustomContainer>
      <S.ThanksBox>
        <Typography sx={{ fontSize: '2.25rem', fontWeight: '500' }}>{`Thank you `}</Typography>
        <Typography sx={{ fontSize: '2rem', paddingLeft: 0.8 }}>for using the devapp!</Typography>
      </S.ThanksBox>
      <S.DownloadContainer>
        <img src={ConnectImg} alt="Connect image" />
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid sx={{ display: 'flex', gap: '0.5rem' }}>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: '500' }}>Download</Typography>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: '500', color: '#45B549' }}>GIANT</Typography>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: '500', color: '#6F6F73' }}>Connect</Typography>
          </Grid>
          <Typography sx={{ textAlign: 'center', color: '#6F6F73', fontSize: '1.35rem' }}>
            to connect and earn.
          </Typography>
        </Grid>
      </S.DownloadContainer>
      <S.PacksContainer>
        <S.Packs>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: '#45B549', fontWeight: '600' }}>Inflight Wifi</Typography>
            <Typography sx={{ fontWeight: '500' }}>on 1000s of flights.</Typography>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: '#45B549', fontWeight: '600' }}>Cellular eSIM data</Typography>
            <Typography sx={{ fontWeight: '500' }}>in 118 countries</Typography>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: '500' }}>Connect to earn</Typography>
            <Typography sx={{ color: '#45B549', fontWeight: '600' }}>GIANT rewards</Typography>
          </Grid>
        </S.Packs>
        {/* <Grid> */}
        <img src={PacksGroupImg} alt="Packs group image" style={{ position: 'absolute', right: '0' }} />
        {/* </Grid> */}
      </S.PacksContainer>
      <S.CommunityContainer>
        <Grid sx={{ display: 'flex', margin: '2rem 0' }}>
          <img
            src={PlayStoreImg}
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://wific0in.app.link/kSKDARQhgrb')}
            alt="playstore"
          />
          <img
            src={AppleStore}
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://wific0in.app.link/kSKDARQhgrb')}
            alt="appstore"
          />
        </Grid>
        <Typography sx={{ fontWeight: '500', fontSize: '1.35rem', color: '#414141' }}>Join the Community</Typography>
      </S.CommunityContainer>
      <S.WorldImageContainer>
        <img src={ConnectedWorldImg} alt="Connected world image" />
      </S.WorldImageContainer>
      <S.SocialAppContainer>
        <Button
          sx={{ border: '1px solid #6C19FF', padding: '0.5rem 1.5rem', borderRadius: '1.5rem' }}
          onClick={() => window.open('https://discord.gg/FTACe5KPsn')}
        >
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <img src={DiscordImg} alt="Discord Image" style={{ height: '1.25rem' }} />
            <p style={{ color: '#6C19FF', textTransform: 'none', margin: 'unset', fontWeight: '500' }}>Discord</p>
          </div>
        </Button>
        <Button
          sx={{ border: '1px solid #2AABEE', padding: '0.5rem 1.5rem', borderRadius: '1.5rem', background: '#dceff9' }}
          onClick={() => window.open('https://t.me/giantprotocol')}
        >
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <img src={TelegramImg} alt="Discord Image" style={{ height: '1rem' }} />
            <p style={{ color: '#2AABEE', textTransform: 'none', margin: 'unset', fontWeight: '500' }}>Telegram</p>
          </div>
        </Button>
      </S.SocialAppContainer>
    </S.CustomContainer>
  )
}

export default ThanksPage
