import React, { useContext, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { S } from './styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import SecondaryButton from 'components/buttons/SecondaryButton'
import PrimaryButton from 'components/buttons/PrimaryButton'
import StakeOverlay from './StakeOverlay'
import { useWalletContext, WalletContext } from 'contexts/wallet'
import UnStakeOverlay from './UnStakeOverlay'
import ManageModal from 'components/modals/ManageModal'
import FaucetOverlay from './faucetOverlay'

const ConnectedToken = () => {
  const { stakeBalance, balance } = useContext(WalletContext)

  const matches = useMediaQuery('(max-width:450px)')
  const [stakeEl, setStakeEl] = useState<HTMLElement | any>(null)
  const stakeDrop = Boolean(stakeEl)

  const [unStakeEl, setUnStakeEl] = useState<HTMLElement | any>(null)
  const unStakeDrop = Boolean(unStakeEl)

  const [FaucetEl, setFaucetEl] = useState<HTMLElement | any>(null)
  const faucetDrop = Boolean(FaucetEl)

  return (
    <S.TokenCard>
      <S.CustomGrid
        sx={{
          display: 'flex',
          gridColumnGap: '2rem',
          borderBottom: '1px solid #DEE7FD',
          flexWrap: 'wrap',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            flexDirection: `${matches ? 'row' : 'column'}`,
            width: `${matches ? '100%' : 'unset'}`,
            justifyContent: `${matches ? 'space-between' : 'unset'}`,
            alignItems: `${matches ? 'center' : 'unset'}`,
          }}
        >
          <Typography sx={{ fontSize: '1.125rem', fontWeight: '700', lineHeight: '1.75rem' }}>GNT Tokens</Typography>
          <Typography sx={{ fontSize: '2.25rem', fontWeight: 'bold' }}>{balance?.deci.slice(0, -2)}</Typography>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            gridColumnGap: '1rem',
            paddingBottom: '0.5rem',
            alignSelf: 'end',
            justifyContent: `${matches ? 'space-between' : 'unset'}`,
            width: `${matches ? '100%' : 'unset'}`,
            alignItems: `${matches ? 'center' : 'unset'}`,
          }}
        >
          {/* <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="body2" sx={{ color: '#66717B', fontSize: '1.125rem' }}>
              Change
            </Typography>
            <Grid sx={{ display: 'flex' }}>
              <ArrowDropUpIcon
                sx={{
                  color: '#45B549',
                  fontSize: '1rem',
                  alignSelf: 'center',
                }}
              />
              <Typography variant="body1" sx={{ color: '#45B549', fontSize: '1.125rem' }}>
                {0.05}%
              </Typography>
            </Grid>
          </Grid> */}
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant="body2" sx={{ color: '#66717B', fontSize: '1.125rem' }}>
              eAPY
            </Typography>
            <Typography variant="body1" sx={{ color: '#45B549', fontSize: '1.125rem' }}>
              {13.5}%
            </Typography>
          </Grid>
        </Grid>
      </S.CustomGrid>
      <S.CustomGrid
        sx={{
          gap: `${matches ? '1rem' : '1rem !important'}`,
          minWidth: `${matches ? '300px' : '390px'}`,
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            flexDirection: `${matches ? 'row' : 'column'}`,
            justifyContent: `${matches ? 'space-between' : 'unset'}`,
            width: `${matches ? '100%' : 'unset'}`,
            alignItems: `${matches ? 'center' : 'unset'}`,
          }}
        >
          <Typography variant="body2" sx={{ color: '#66717B', fontSize: '1.125rem' }}>
            Staked
          </Typography>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{stakeBalance?.total}</Typography>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '1rem',
            width: `${matches ? '100%' : 'unset'}`,
          }}
        >
          <PrimaryButton
            style={{ height: '50px', width: `${matches ? '100%' : '134px'}`, alignSelf: 'center' }}
            onClick={(e: any) => {
              ;(window as any).analytics.track('Clicked on Stake button')
              setStakeEl(stakeEl ? null : e.currentTarget)
            }}
          >
            Stake
          </PrimaryButton>
          {stakeDrop ? <StakeOverlay open={stakeDrop} stakeEl={stakeEl} setStakeEl={(arg) => setStakeEl(arg)} /> : null}
          {parseInt(stakeBalance?.total) > 0 && (
            <SecondaryButton
              style={{ height: '50px', width: `${matches ? '100%' : '134px'}`, alignSelf: 'center' }}
              onClick={(e: any) => {
                 ;(window as any).analytics.track('Clicked on UnStake button')
                 setUnStakeEl(unStakeEl ? null : e.currentTarget)
              } }
            >
              Unstake
            </SecondaryButton>
          )}
          {unStakeDrop ? (
            <UnStakeOverlay open={unStakeDrop} unStakeEl={unStakeEl} setUnStakeEl={(arg) => setUnStakeEl(arg)} />
          ) : null}
        </Grid>
      </S.CustomGrid>
      <S.CustomGrid
        sx={{
          gap: `${matches ? '1rem' : ''}`,
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            flexDirection: `${matches ? 'row' : 'column'}`,
            justifyContent: `${matches ? 'space-between' : 'unset'}`,
            width: `${matches ? '100%' : 'unset'}`,
            alignItems: `${matches ? 'center' : 'unset'}`,
          }}
        >
          <Typography variant="body2" sx={{ color: '#66717B', fontSize: '1.125rem' }}>
            Earned
          </Typography>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{stakeBalance?.earnedBalance}</Typography>
        </Grid>
      </S.CustomGrid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gridRowGap: '1.5rem',
          paddingTop: '1.5rem',
        }}
      >
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <S.MyWalletHeaderText>My Data Plans</S.MyWalletHeaderText>
          <S.ViewAllText onClick={() => setShowModal(true)}>View All</S.ViewAllText>
        </Box> */}
        {/* <Typography sx={{ color: '#66717B' }}>No Active Data Plan</Typography> */}
        {/* {(userInstances?.length >= 0 || userInstances !== null) && <ActivePlanCard />}
        {(userInstances?.length <= 0 || userInstances === null) && (
          <Typography sx={{ color: '#66717B', height: '5rem', textAlign: 'center' }}>No Active Data Plan</Typography>
        )} */}
        {/* <SecondaryButton
          style={{ height: '50px', width: '100%' }}
          onClick={(e: any) => setFaucetEl(FaucetEl ? null : e.currentTarget)}
        >
          Faucet
        </SecondaryButton> */}
        <FaucetOverlay open={faucetDrop} faucetEl={FaucetEl} setFaucetEl={(arg) => setFaucetEl(arg)} />
      </Grid>
    </S.TokenCard>
  )
}

export default ConnectedToken
