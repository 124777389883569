import React from 'react'
import { Grid, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import GiantTableContainer from './GiantTableContainer'
import SearchField from './SearchField'
import ShowRows from './ShowRows'
import { S } from './styled'

const GiantConnectTable = () => {
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
        borderRadius: '8px',
        flexWrap: 'wrap',
        paddingBottom: '2rem',
      }}
      item
      xs={12}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 2rem',
          flexWrap: 'wrap',
          gap: '1rem',
        }}
      >
        {/* <SearchField /> */}
        {/* <ShowRows /> */}
        <Typography sx={{ fontSize: '2rem', fontWeight: '600' }}>Stakeholders</Typography>
      </Grid>
      <GiantTableContainer />
      <Grid
        sx={{
          display: 'flex',
          padding: '0 2rem',
          justifyContent: 'space-between',
          gridColumnGap: '4rem',
          flexWrap: 'wrap',
          gap: '1rem',
        }}
      >
        <Typography sx={{ fontSize: '1.125rem', color: '#66717B', alignSelf: 'center' }}>
          Showing page 1 of 1
        </Typography>
        <Grid
          sx={{
            display: 'flex',
            gridColumnGap: '0.5rem',
            flexWrap: 'wrap',
            gridRowGap: '1rem',
            paddingRight: '5rem',
          }}
        >
          {/* <S.PageToggleBtn>
            <ArrowBackIosIcon sx={{ fontSize: '1rem' }} />
          </S.PageToggleBtn> */}
          {[1].map((data, i) => (
            <S.PageToggleBtn
              key={i}
              sx={{
                background: `${i === 0 && '#66717B !important'}`,
                color: `${i === 0 && 'white !important'}`,
              }}
            >
              <Typography>{data}</Typography>
            </S.PageToggleBtn>
          ))}
          {/* <S.PageToggleBtn>
            <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
          </S.PageToggleBtn> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GiantConnectTable
