import { Box, CircularProgress, useMediaQuery } from '@mui/material'
import PrimaryButton from 'components/buttons/PrimaryButton'
import PrimaryCard from 'components/cards/PrimaryCard'
import PurchaseModal from 'components/modals/PurchaseModal'
import { FC, Fragment, useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { fetchQueries, getCountiresDataForScroll } from 'utils/constants/config'
import { fetchCountryCount } from 'utils/constants/queries'
import ReactCountryFlag from 'react-country-flag'
import { S } from './shop.style'
import WorldIcon from 'assets/icons/WorldIcon.svg'

interface Props {
  country: string
  count: number
}

const PacksContainer: FC<Props> = ({ country, count }) => {
  const match2 = useMediaQuery('(max-width:1360px)')
  // const { isLoading, data: countryList } = useQuery('getCountryNameForPacks', () =>
  //   fetchQueries(fetchCountries(variables)),
  // )

  const [dataId, setDataId] = useState<any>({ id: '', index: '' })
  const [cardData, setCardData] = useState<any>()
  const [showModal, setShowModal] = useState<any>(false)
  const [countryData, setCountryData] = useState<any>([])
  const [countryCount, setCountryCount] = useState<any>(0)
  const variables = {}
  const twoGrid = useMediaQuery('(min-width:576px)')
  const threeGrid = useMediaQuery('(min-width:1024px)')

  const {
    data: countryList,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['countriesData', country],
    ({ pageParam = 12 }) => {
      return getCountiresDataForScroll(pageParam, country)
    },
    {
      getNextPageParam: (_lastPage: any, pages: any) => {
        return 0
      },
    },
  )

  useEffect(() => {
    if (countryList?.pages !== undefined) {
      if (countryList?.pages.length === 1) {
        const filteredCountryList = countryList?.pages[0].filter((country: any) => country.packs.length > 0)
        setCountryData(filteredCountryList)
      } else if (countryList?.pages.length === 2) {
        const fullFilteredCountryList = countryList?.pages[1].filter((country: any) => country.packs.length > 0)
        setCountryData(fullFilteredCountryList)
      }
    }
  }, [countryList])

  useEffect(() => {
    fetchQueries(fetchCountryCount).then((value: any) => {
      let data = value.data.data.countriesConnection.aggregate
      setCountryCount(data.totalCount)
    })
  }, [])

  useEffect(() => {
    if (countryData.length === 1) {
      if (country === '') {
        setDataId({ id: '', index: '' })
      } else {
        setDataId({ id: countryData[0].id, index: 0 })
        setCardData(countryData[0])
      }
    }
  }, [countryData, country])

  const handleClose = () => {
    setShowModal(false)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  // Note:  to show the selected country's package below the grid row of the specific country on all resolutions
  const getGridRow = (): any => {
    if (dataId.index !== '') {
      if (twoGrid && !threeGrid) return parseInt(((dataId.index + 3) / 2).toFixed())
      if (threeGrid) return parseInt(((dataId.index + 2) / 3).toFixed()) + 1
      return dataId.index + 2
    }
  }

  // Note:  To check how many grids needs to be spaned to get the packs of the selected country in full width
  const getColumnCount = (): any => {
    if (twoGrid && !threeGrid) return 2
    if (threeGrid) return 3
    return 1
  }

  return (
    <>
      <S.CustomShopContainer>
        {countryData.length > 0 &&
          countryData.map((data: any, index: number) => {
            return (
              <div key={data.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <S.CountryCard
                  sx={
                    data.id === dataId.id
                      ? { border: '1px solid rgb(69, 181, 73)', color: 'rgb(69, 181, 73)', fontWeight: '500' }
                      : {}
                  }
                  onClick={() => {
                    // setDataId({ id: data.id, index: index })
                    setCardData(data)
                    setDataId((prevState: any) => {
                      if (prevState.id === data.id) {
                        return { id: '', index: '' }
                      }
                      return { id: data.id, index: index }
                    })
                  }}
                >
                  {data.country} &nbsp;
                  {data.flagCountryCode === 'Global' ? (
                    <img src={WorldIcon} alt="currency img" style={{ width: '28px', height: '28px' }} />
                  ) : (
                    <ReactCountryFlag countryCode={data.flagCountryCode} />
                  )}
                </S.CountryCard>
              </div>
            )
          })}

        {dataId?.id && (
          <S.CardContainer
            sx={{ gridRowStart: getGridRow(), gridColumn: `span ${getColumnCount()}/span ${getColumnCount()}` }}
          >
            {cardData.packs.length > 0 ? (
              <S.PlansDataContainer
                item
                sx={{
                  justifyContent: `${match2 ? 'center' : 'space-between'}`,
                  gap: `${match2 ? '2rem' : '0'}`,
                }}
              >
                {cardData.packs.map((pack: any) => {
                  return (
                    <PrimaryCard
                      key={pack.id}
                      data={pack}
                      country={cardData.country}
                      countryData={cardData}
                      color={cardData.countryColor}
                      flag={cardData.flagCountryCode}
                      location="shop"
                      handleOpen={() => setShowModal(true)}
                    />
                  )
                })}
              </S.PlansDataContainer>
            ) : (
              <S.NoPacksContainer>No packs found</S.NoPacksContainer>
            )}
          </S.CardContainer>
        )}
      </S.CustomShopContainer>
      {countryData.length <= 12 && country === '' && count > 0 && (
        <>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            <PrimaryButton
              style={{ height: '50px', width: '386px' }}
              onClick={() => {
                fetchNextPage()
              }}
            >
              Show {count - 12} countries
            </PrimaryButton>
          )}
        </>
      )}
      {countryData.length === 0 && (
        <Box sx={{ width: '100%', display: 'grid', placeContent: 'center' }}>No Packs Found</Box>
      )}

      <PurchaseModal show={showModal} handleCloseModal={handleClose} />
    </>
  )
}

export default PacksContainer
