import axios from 'axios'
import { txSend } from 'polkadot'
import { toast } from 'react-toastify'
import { getFaucetStatus } from 'utils/constants/config'
import { toWei } from 'utils/utils'

export const faucetHandler = async (
  account: any,
  decimal: any,
  setFaucetLoading: any,
  setComponentState: any,
  userEmail: any,
  setFaucetEl: any,
  setTimeForNextAttempt: any,
  referalCode: any,
  setReferalCode: any,
  setFaucetId: any,
  setSendLoading: any,
) => {
  try {
    // let ASSETID = 1
    let targetAccount = account.address
    // let amount = '100'
    // let paramFields = [true, true]
    // let inputParams = [targetAccount, amount]
    let url = `${process.env.REACT_APP_BACKEND_URL}sendFaucet?email=${userEmail}&address=${targetAccount}`

    if (referalCode) {
      url = `${url}&referalCode=${referalCode}`
    }

    axios
      .get(url)
      .then(async (res) => {
        if (res.data.status === true) {
          let timer: any
          setSendLoading(true)
          setInterval(() => {
            getFaucetStatus(targetAccount)
              .then((res) => {
                if (timer) {
                  setSendLoading(false)
                  setReferalCode(res.referral)
                  setFaucetId(res.faucetId)
                } else {
                  if (res.timer) {
                    timer = res.timer
                    setSendLoading(false)
                    setTimeForNextAttempt(res.timer)
                    setReferalCode(res.referral)
                    setFaucetId(res.faucetId)
                  }
                }
              })
              .catch((e) => {
                setSendLoading(false)
              })
          }, 10000)

          setFaucetLoading(false)
          setComponentState('Success')
        } else {
          setComponentState('Success')
          setTimeForNextAttempt(res.data.timer)
        }
      })
      .catch((err: any) => {
        console.log('inside err')
        let errorMessage =
          err.message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low'
            ? 'Account balance too low'
            : err.message
        toast.error(errorMessage)
      })
  } catch (e) {
    console.log('Redeem-error', e)
  }
}
