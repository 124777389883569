import styled from '@emotion/styled'
import { Box, Card, Container, Typography } from '@mui/material'

export namespace S {
  export const SwapContainer = styled(Container)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
    gap: '2rem',
    padding: '3rem',
    minHeight: 'calc(100vh - 21rem)',
  }))

  export const SwapCard = styled(Card)(({ theme }: any) => ({
    maxWidth: '810px',
    width: '100%',
    background: `${theme.palette.common.white}`,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
    borderRadius: '8px',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  }))

  export const SwapHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    // order:"1"
  }))

  export const SwapHeaderLeft = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
    color: theme.palette.text.primary,
  }))

  export const SwapHeaderRight = styled(SwapHeaderLeft)(({ theme }: any) => ({
    fontSize: '18px',
    letterSpacing: '-0.02em',
    cursor: 'pointer',
  }))

  export const SwapMain = styled(SwapHeader)(({ theme }: any) => ({
    flexWrap: 'wrap',
    minHeight: '55px',
    // order: theme.isSwap === true ? '2' : '4',
    // ':nth-of-type(2)': {
      // order: theme.isSwap === true ? '4' : '2',
    // },
  }))

  export const SwapMainLeft = styled.input(({ theme }: any) => ({
    background: '#f1f3f5',
    outline: 'none',
    borderRadius: '4px',
    width: 'calc(100% - 10rem)',
    fontSize: '24px',
    border: 'none',
    color: '#000000',
    lineHeight: '31px',
    fontWeight: 700,
    padding: '0 1rem',
  }))

  export const SwapInfo = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.5rem',
    // order:"5"
  }))

  export const SwapExchange = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '-2.5rem 40%',
    position: 'relative',
    width: '42px',
    height: '42px',
    borderRadius: '50%',
    background: '#ECF8ED',
    border: `3px solid ${theme.palette.primary.contrastText}`,
    cursor: 'pointer',
    // order:"3"
  }))
}
