import styled from '@emotion/styled'
import { Box, Card, Container, Typography } from '@mui/material'
import VoteCardBg from 'assets/VoteCardBg.png'

export namespace S {
  export const VoteContainer = styled(Container)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '90%',
    padding: '3rem',
    gap: '2rem',
    minHeight: 'calc(100vh - 21rem)',
  }))
  export const VoteCardContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '1rem',
    maxWidth: '810px',
    width: '100%',
  }))
  export const VoteCard = styled(Card)(({ theme }: any) => ({
    // maxWidth: '50.625rem',
    maxWidth: '810px',
    width: '100%',
    background: `url(${VoteCardBg}) no-repeat `,
    backgroundSize: '100% 100%',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
    borderRadius: '8px',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }))

  export const VoteCardTopText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.5rem',
    lineHeight: '1.9375rem',
    color:  theme.palette.text.primary,
    fontWeight: 700,
  }))

  export const VoteCardMiddleText = styled(Typography)(({ theme }: any) => ({
    fontSize: '0.9375rem',
    lineHeight: '1.3125rem',
    color: '#66717B',
    fontWeight: 400,
    maxWidth: '90%',
  }))

  export const VoteCardBottomText = styled(VoteCardMiddleText)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    fontWeight: 700,
    maxWidth: '100%',
  }))

  export const ContentBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '54.375rem',
    // maxWidth: '810px',
    width: '100%',
    gap: '1rem',
  }))
  
  export const ContentCard = styled(VoteCard)(({ theme }: any) => ({
    background: theme.palette.primary.contrastText,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding:"1rem 2rem"
  }))

  export const ContentCardText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.3125rem',
    lineHeight: '2.3125rem',
    color:  theme.palette.text.primary,
    fontWeight: 500,
  }))
    export const ContentCardButton = styled(Box)(({ theme }: any) => ({
      fontSize: '1.3125rem',
      lineHeight: '2.3125rem',
      color:  theme.palette.text.primary,
      textAlign: 'center',
      background: '#f3f5f7',
    //   opacity: 0.25,
      padding: '0 1rem',
      borderRadius: '8px',
      textTransform: 'none',
      width: '126px',
    }))
}
