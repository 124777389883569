import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { LEGENDS_DATATYPE } from 'types'
import BarChart from 'components/charts/BarChart'
import { S } from './styled'
interface Props {
  flex?: string
  source: string
  hasFee: boolean
  value: Array<LEGENDS_DATATYPE>
  justifyContent?: string
  showCharts: boolean
}

const GiantProtocol: FC<Props> = ({ flex, source, hasFee, value, justifyContent, showCharts }) => {
  return (
    <Grid
      sx={{
        flex: { flex },
        gridRowGap: '1rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showCharts && <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Charts</Typography>}
      <S.ProtocolChartCard>
        <Grid
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: { justifyContent },
            gridColumnGap: `${showCharts ? '1rem' : '3rem'}`,
            gridRowGap: '1rem',
            width: '100%',
          }}
        >
          <img src={source} alt="" />
          {value.map((data: LEGENDS_DATATYPE) => (
            <Grid sx={{ display: 'flex', flexDirection: 'column', gridRowGap: '0.25rem' }} key={data.id}>
              <Grid sx={{ display: 'flex', gap: '0.3rem' }}>
                <img src={data.src} alt="" />
                <Typography variant="body2" sx={{ color: '#66717B' }}>
                  {data.name}
                </Typography>
              </Grid>
              <Grid sx={{ display: 'flex' }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  ${data.amount}
                </Typography>
                <Grid sx={{ display: 'flex' }}>
                  <ArrowDropUpIcon sx={{ color: '#45B549' }} />
                  <Typography variant="body1" sx={{ color: '#45B549' }}>
                    {data?.percentage}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
          {hasFee && (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gridRowGap: '0.25rem',
              }}
            >
              <Typography variant="body2" sx={{ color: '#66717B' }}>
                Fees 24H
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }}>${1.2}M</Typography>
            </Grid>
          )}
        </Grid>
        <BarChart height="22rem" />
      </S.ProtocolChartCard>
    </Grid>
  )
}

export default GiantProtocol
