import { txSend } from 'polkadot'
import { toast } from 'react-toastify'
import { generateGiftCode } from 'utils/constants/config'

export const handleRedeem = async (account: any, instanceId: any, setRedeemLoading: any, setType: any) => {
  try {
    await setRedeemLoading(true)
    let paramFields = [true]
    let inputParams = [instanceId]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'dct', 'redeem')
    const unsub = await txExecute
      .signAndSend(...fromAcct, ({ status }: any) => {
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setRedeemLoading(false)
          return
        }
        if (status.type === 'Finalized') {
          toast.success('Redeemed')
          setRedeemLoading(false)
          setType(1)
        }
      })
      .catch((err: any) => {
        console.log('inside err')
        let errorMessage =
          err.message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low'
            ? 'Account balance too low'
            : err.message
        toast.error(errorMessage)
      })
  } catch (e) {
    console.log('Redeem-error', e)
  }
}

export const handleGift = async (account: any, instanceId: any, setGiftLoading: any, handleGiftSuccess: any) => {
  try {
    await setGiftLoading(true)
    let paramFields = [true]
    let inputParams = [instanceId]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'dct', 'gift')
    const unsub = await txExecute
      .signAndSend(...fromAcct, ({ status }: any) => {
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setGiftLoading(false)
        }
        if (status.type === 'Finalized') {
          // toast.success('Redeemed')
          generateGiftCode({ instanceId: instanceId, owner: account.address }).then((res) => {
            if (res?.status === true) {
              let code = res?.encrypted?.codes
              handleGiftSuccess(4, code)
              setGiftLoading(false)
            }
          })
        }
      })
      .catch((err: any) => {
        // console.log('Transaction Failed', err.message)
        console.log('inside err')
        let errorMessage =
          err.message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low'
            ? 'Account balance too low'
            : err.message
        toast.error(errorMessage)
      })
  } catch (e) {
    console.log('Gift-error', e)
  }
}
