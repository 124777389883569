import React, { FC, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, IconButton, Typography, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { S } from './styled'
import Polygon from 'assets/icons/Polygon.svg'
import GiantCoin from 'assets/coinImg.png'
import GreenTick from 'assets/icons/GreenTick.svg'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { useWalletContext } from 'contexts/wallet'
import { faucetHandler } from 'polkadot/sendmethods/assets'
import Countdown from 'react-countdown'
import { getFaucetDetails, getFaucetStatus, updateFaucet } from 'utils/constants/config'

interface Props {
  open: boolean
  faucetEl: HTMLElement
  setFaucetEl: (stakeEl: any) => void
}

const FaucetOverlay: FC<Props> = ({ open, faucetEl, setFaucetEl }) => {
  const { connectedAccount, assetMetadata } = useWalletContext()
  const [componentState, setComponentState] = useState<string>('Init')
  const [timeForNextAttempt, setTimeForNextAttempt] = useState<any>(0)
  const [userEmail, setUserEmail] = useState<string>('')
  const [updatedEmail, setUpdatedEmail] = useState<string>('')
  const [referalCode, setReferalCode] = useState<string>('')
  const [faucetId, setFaucetId] = useState<string>('')
  const [err, setErr] = useState<boolean>(false)
  const [faucetLoading, setFaucetLoading] = useState<boolean>(false)
  const [balanceHrs, setBalanceHrs] = useState('')
  const matches = useMediaQuery('(max-width:768px)')
  const [currentDate, setCurrentDate] = useState(0)
  const [sendLoading, setSendLoading] = useState(false)
  useEffect(() => {
    let date = Date.now()
    setCurrentDate(date)
  }, [])

  useEffect(() => {
    if (connectedAccount.address) {
      getFaucetStatus(connectedAccount.address).then((res) => {
        if (res.status === true && res.timer > 0) {
          setComponentState('Success')
          setTimeForNextAttempt(res.timer)
          setReferalCode(res.referral)
          setFaucetId(res.faucetId)
        } else if (res.message === 'You can claim your gift code') {
          setComponentState('NextAttempt')
          setFaucetId(res.faucetId)
        } else {
          setComponentState('Init')
        }
      })
    }
  }, [connectedAccount.address])

  useEffect(() => {
    timeForNextAttempt > 0 && msToTime(timeForNextAttempt)
  }, [timeForNextAttempt])

  const msToTime = (ms: number) => {
    let hours = (ms / (1000 * 60 * 60)).toFixed(1)
    setBalanceHrs(hours.substring(0, 2) + ' hrs')
  }

  const validateEmail = async (email: any) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      if (connectedAccount !== '') {
        await setFaucetLoading(true)
        if (updatedEmail !== '') {
          await updateFaucet({ mail: updatedEmail }, faucetId)
        }
        await faucetHandler(
          connectedAccount,
          assetMetadata?.decimals,
          setFaucetLoading,
          setComponentState,
          userEmail,
          setFaucetEl,
          setTimeForNextAttempt,
          referalCode,
          setReferalCode,
          setFaucetId,
          setSendLoading,
        )
      }
    } else {
      setComponentState('Invalid')
    }
  }

  const validateEmailClaim = async (email: any) => {
    if (updatedEmail !== '') {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        if (connectedAccount !== '') {
          await setFaucetLoading(true)
          await updateFaucet({ mail: updatedEmail }, faucetId)
          await faucetHandler(
            connectedAccount,
            assetMetadata?.decimals,
            setFaucetLoading,
            setComponentState,
            updatedEmail,
            setFaucetEl,
            setTimeForNextAttempt,
            referalCode,
            setReferalCode,
            setFaucetId,
            setSendLoading,
          )
        }
      } else {
        setErr(true)
      }
    } else {
      await setFaucetLoading(true)
      await getFaucetDetails(faucetId).then(async (res: any) => {
        await faucetHandler(
          connectedAccount,
          assetMetadata?.decimals,
          setFaucetLoading,
          setComponentState,
          res.mail,
          setFaucetEl,
          setTimeForNextAttempt,
          referalCode,
          setReferalCode,
          setFaucetId,
          setSendLoading,
        )
        setFaucetLoading(false)
      })
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      setComponentState('NextAttempt')
    } else {
      return (
        <>
          {hours}:{minutes}:{seconds}
        </>
        // <Box
        //   sx={{
        //     textAlign: 'center',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     color: '#45B549',
        //   }}
        // >
        //   <Box>
        //     <S.TimerText>{hours} :</S.TimerText>
        //     <S.TimerSubText>HOURS</S.TimerSubText>
        //   </Box>
        //   <Box>
        //     <S.TimerText>{minutes} :</S.TimerText>
        //     <S.TimerSubText>MINUTES</S.TimerSubText>
        //   </Box>
        //   <Box>
        //     <S.TimerText>{seconds}</S.TimerText>
        //     <S.TimerSubText>SECONDS</S.TimerSubText>
        //   </Box>
        // </Box>
      )
    }
  }

  if (sendLoading) {
    return (
      <S.CustomStakeOverlayCard sx={{ flexDirection: `${matches ? 'column' : 'row'}`, justifyContent: 'center' }}>
        <CircularProgress color="success" size={25} />
      </S.CustomStakeOverlayCard>
    )
  }
  return (
    <>
      <S.CustomStakeOverlayCard sx={{ flexDirection: `${matches ? 'column' : 'row'}` }}>
        <S.FaucetContainer>
          {(componentState === 'Init' || componentState === 'Invalid') && (
            <>
              <S.EmailText>Enter your email to recieve test tokens</S.EmailText>
              <S.EmailSubTextContainer>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  <img src={GiantCoin} style={{ height: '28px', marginRight: '0.6rem' }} alt="giantcoint img" />
                  &emsp;
                  <S.EmailSubText>100 GNT Tokens</S.EmailSubText>
                </Box>
                {componentState === 'Invalid' && <S.ErrorText>Invalid email</S.ErrorText>}
              </S.EmailSubTextContainer>
              <S.StakeTextContainer>
                <S.FaucetTextField
                  onChange={(e: any) => setReferalCode(e.target.value)}
                  id="filled-basic"
                  variant="filled"
                  size="medium"
                  // margin="normal"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: 0,
                    },
                  }}
                  sx={{ height: '2.75rem' }}
                  placeholder="Referral code(optional)"
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '0.2rem 0' }}>
                  <S.FaucetTextField
                    onChange={(e: any) => setUserEmail(e.target.value)}
                    id="filled-basic"
                    variant="filled"
                    size="medium"
                    // margin="normal"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        padding: 0,
                      },
                    }}
                    sx={{ height: '2.75rem' }}
                    placeholder="Enter your email"
                  />
                  <PrimaryButton
                    style={{
                      height: '44px',
                      width: `${matches ? '100%' : '132px'}`,
                      alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                      referalCode !== ''
                        ? (window as any).analytics.identify({
                            email: userEmail,
                            address: connectedAccount?.address,
                            referralCode: referalCode,
                          })
                        : (window as any).analytics.identify({
                            email: userEmail,
                            address: connectedAccount?.address,
                          })
                      ;(window as any).analytics.track('Clicked on Get Tokens button', {
                        email: userEmail,
                      })
                      validateEmail(userEmail)
                    }}
                  >
                    {faucetLoading ? <CircularProgress color="success" size={25} /> : 'Get Tokens'}
                  </PrimaryButton>
                </Box>
              </S.StakeTextContainer>
            </>
          )}
          {componentState === 'Success' && (
            <S.MyWalletSuccessContainer>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                <img src={GiantCoin} style={{ height: '28px', marginRight: '0.6rem' }} alt="giantcoint img" />
                <S.EmailSubText sx={{ color: 'inherit' }}>
                  100 GNT Tokens <img src={GreenTick} alt="green tick icon" />
                </S.EmailSubText>
              </Box>
              <S.CongratsText>
                Share your referral code <b>{referalCode}</b> for <br /> a chance to win 12 months of mobile data.
              </S.CongratsText>
              <S.EmailSubText sx={{ color: 'inherit', textAlign: 'center' }}>
                Claim more tokens in{' '}
                <span style={{ color: '#45B549' }}>
                  {timeForNextAttempt > 0 && <Countdown date={currentDate + timeForNextAttempt} renderer={renderer} />}
                </span>
              </S.EmailSubText>
            </S.MyWalletSuccessContainer>
          )}
          {componentState === 'Timer' && (
            <S.MyWalletSuccessContainer>
              <S.CongratsText>
                {balanceHrs} until you can claim more <br /> tokens. This resets every 24 hrs.
              </S.CongratsText>
              {timeForNextAttempt > 0 && <Countdown date={currentDate + timeForNextAttempt} renderer={renderer} />}
            </S.MyWalletSuccessContainer>
          )}
          {componentState === 'NextAttempt' && (
            <S.MyWalletNextAttemptContainer>
              <S.FaucetTextField
                onChange={(e: any) => setUpdatedEmail(e.target.value)}
                id="filled-basic"
                variant="filled"
                size="medium"
                // margin="normal"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: 0,
                  },
                }}
                sx={{ height: '2.75rem' }}
                placeholder="Update your email(optional)"
              />
              {err && <h6 style={{ color: 'red', margin: 0 }}>Invalid email *</h6>}
              <PrimaryButton
                style={{
                  height: '44px',
                  width: '100%',
                  alignSelf: 'flex-end',
                }}
                onClick={() => {
                  ;(window as any).analytics
                    .identify({
                      email: updatedEmail,
                      address: connectedAccount?.address,
                    })(window as any)
                    .analytics.track('Clicked on Claim 100 GNT Tokens button', {
                      email: updatedEmail,
                    })

                  validateEmailClaim(updatedEmail)
                }}
              >
                {faucetLoading ? <CircularProgress color="success" size={25} /> : 'Claim 100 GNT Tokens'}
              </PrimaryButton>
              <S.CongratsText>
                Share your referral code <b>{referalCode}</b>for <br /> a chance to win 12 months of mobile data.
              </S.CongratsText>
              {/* {timeForNextAttempt > 0 && <Countdown date={currentDate + timeForNextAttempt} renderer={renderer} />} */}
            </S.MyWalletNextAttemptContainer>
          )}
        </S.FaucetContainer>
        <IconButton
          onClick={() => setFaucetEl(null)}
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            padding: '0.3rem !important',
          }}
        >
          <CloseIcon sx={{ fontSize: '1.5rem', color: '#0B213E' }} />
        </IconButton>
      </S.CustomStakeOverlayCard>
    </>
  )
}

export default FaucetOverlay
