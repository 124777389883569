import styled from '@emotion/styled'
import { Box } from '@mui/system'
import { FC } from 'react'
import InfoIcon from 'assets/icons/InfoIcon.svg'
import WarningIcon from 'assets/icons/WarningIcon.svg'

interface Props {
  type: string
  content: string
}

const WarningAlert: FC<Props> = ({ type, content }) => {
  const iconImg = type === 'warning' ? WarningIcon : InfoIcon

  return (
    <AlertContainer type={type}>
      <img src={iconImg} alt="info icon" />
      <p>{content}</p>
    </AlertContainer>
  )
}

export default WarningAlert

const AlertContainer = styled(Box)(({ theme, type }: any) => ({
  background: type === 'info' ? theme.palette.primary.main : 'rgba(255, 196, 48, 0.28)',
  color: type === 'info' ? theme.palette.primary.contrastText : '#FF7A00',
  alignItems: 'center',
  display: 'flex',
  minHeight: '50px',
  height: '100%',
  padding: '0 2rem',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    padding: '1rem 2rem',
    gap: '1rem',
  },
}))
