import React, { FC } from 'react'
import { styled } from '@mui/system'
import { ResponsiveContainer, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ComposedChart, Line } from 'recharts'
import { CHART_DATA_TYPE } from '../../types'

interface Props {
  height?: string
}

const GiantChart = styled(ComposedChart)(({ theme }: any) => ({
  width: '100% !important',
  svg: {
    width: '100% !important',
  },
}))

const BarChart: FC<Props> = ({ height }) => {
  const cartesianGridStyle = { stroke: '#E9ECF0', strokeWidth: '0.7' }
  const graphStyle = { margin: { top: 30, right: 10, bottom: 20, left: 0 } }
  const axisStyle = {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: '#0B213E',
  }
  const chartData: Array<CHART_DATA_TYPE> = [
    {
      name: 'Jan',
      value1: 800,
      value2: 1400,
    },
    {
      name: 'Feb',
      value1: 967,
      value2: 1506,
    },
    {
      name: 'Apr',
      value1: 1098,
      value2: 989,
    },
    {
      name: 'Mar',
      value1: 1200,
      value2: 1228,
    },
    {
      name: 'May',
      value1: 1108,
      value2: 1100,
    },
    {
      name: 'Jun',
      value1: 680,
      value2: 1700,
    },
  ]

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={height}>
      <GiantChart data={chartData} {...graphStyle}>
        <CartesianGrid vertical={false} {...cartesianGridStyle} />
        <XAxis dataKey="name" scale="band" tickLine={false} axisLine={false} style={axisStyle} />
        <YAxis yAxisId={0} tickLine={false} axisLine={false} style={axisStyle} />
        <Bar dataKey="value1" barSize={20} fill="#999999" />
        <Line type="monotone" dataKey="value2" stroke="#45B549" />
        <Tooltip />
      </GiantChart>
    </ResponsiveContainer>
  )
}

export default BarChart
