import React, { FC } from 'react'
import Layout from 'components/layout/Layout'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BigNumber from 'bignumber.js'
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})
const App: FC = () => (
  <>
    <Layout />
    <ToastContainer position="bottom-right" />
  </>
)

export default App
