import axios from 'axios'
import { HttpClient } from './httpClient'

let httpClient = new HttpClient()

export const getCountiresDataForScroll = (params, country) => {
  return httpClient.get(
    // country !== null ? `countries?_limit=3&_start=${params}&country=${country}` : `countries?_limit=3&_start=${params}`,
    country !== '' ? `countries?_limit=${params}&country=${country}` : `countries?_limit=${params}`,
  )
}

export const getAccountByAddress = (address) => {
  return httpClient.get(`accounts?address=${address}`)
}

export const getCurrentAccounts = () => {
  return httpClient.get('accounts')
}

export const createAccount = (params) => {
  return httpClient.post('accounts', params)
}
// export const createInstance = (params) => {
//   return httpClient.post('instances', params)
// }
export const updateAccount = (params, id) => {
  return httpClient.put(`accounts/${id}`, params)
}

export const updateFaucet = (params, id) => {
  return httpClient.put(`/faucets/${id}`, params)
}

export const getFaucetDetails = (id) => {
  return httpClient.get(`/faucets/${id}`)
}

export const createInstance = (address, instanceId, packId) => {
  return httpClient.get(`instance/create?address=${address}&instanceId=${instanceId}&packId=${packId}`)
}

export const dctExpire = (id) => {
  return httpClient.post(`instanceExpire?instanceId=${id}`)
}

export const getFaucetStatus = (address) => {
  return httpClient.get(`faucetStatus?address=${address}`)
}

// export const getCountiresDataForPagination = (params, country) => {
//   return httpClient.get(
//     country !== null ? `countries?_limit=3&_start=${params}&country=${country}` : `countries?_limit=3&_start=${params}`,
//   )
// }

// export const getCountryByCountryName = (params) => {
//   return httpClient.get(`countries?country=${params}`)
// }

// export const getCountryCount = (params) => {
//   return httpClient.get(`countries/count`)
// }

// export const getCountryById = (params) => {
//   return httpClient.get(`countries?id=${params}`)
// }

// export const getPackById = (params) => {
//   return httpClient.get(`packs?id=${params}`)
// }

export const headers = {
  'content-type': 'application/json',
  // Authorization: '<token>',
}

export const fetchQueries = async (graphqlQuery) => {
  return await axios({
    url: `${process.env.REACT_APP_BACKEND_URL}graphql`,
    method: 'POST',
    headers: headers,
    data: JSON.stringify(graphqlQuery),
  })
}

export const generateGiftCode = async (params) => {
  return await httpClient.get(`generateGiftCode?owner=${params.owner}&instanceId=${params.instanceId}`)
}

export const validateCode = async (params) => {
  return await httpClient.get(`validateCode?code=${params}`)
}

export const providerList = async () => {
  return await httpClient.get('providerList')
}
