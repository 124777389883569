import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import { useWalletContext } from 'contexts/wallet'
import PrimaryCard from 'components/cards/PrimaryCard'
import { S } from './modal.styles'
import { dctExpire, getAccountByAddress } from 'utils/constants/config'
import { expireDct } from 'polkadot/sendmethods/provider'
import { Box, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
interface Props {
  show: boolean
  handleCloseModal: () => void
  data: any
}

const ManageModal: React.FC<Props> = ({ show, handleCloseModal, data }) => {
  const { connectedAccount } = useWalletContext()
  const [apiInstances, setApiInstances] = useState([])

  useEffect(() => {
    getAccountByAddress(connectedAccount.address).then((account) => {
      setApiInstances(account[0]?.instances)
    })
  }, [connectedAccount])

  return (
    <Modal
      open={show}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.ManageModalContainer>
        <Box sx={{ display: 'flex', height: '5rem', justifyContent: 'flex-end' }}>
          <S.CustomIconButton onClick={handleCloseModal}>
            <CloseIcon />
          </S.CustomIconButton>
        </Box>
        <S.ManageModalBody>
          {data?.data?.data?.instances.length > 0 &&
            data?.data?.data?.instances.map((data: any) => {
              return (
                <PrimaryCard
                  key={data.id}
                  mainData={data}
                  data={data?.pack}
                  country={data?.pack?.country?.country}
                  color={data?.pack?.country?.countryColor}
                  flag={data?.pack?.country?.flagCountryCode}
                  location="manage"
                  handleOpen={() => dctExpire(data.instanceId)}
                  apiInstance={data?.data?.data?.instances}
                />
              )
            })}
        </S.ManageModalBody>
      </S.ManageModalContainer>
    </Modal>
  )
}

export default ManageModal
