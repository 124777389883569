import { Box, CircularProgress, Grid, useMediaQuery } from '@mui/material'
import { FC, Fragment, useState, useContext, useEffect } from 'react'
import { HOW_IT_WORKS_DATATYPE, WHY_US_DATATYPE } from 'types'
import PrimaryButton from 'components/buttons/PrimaryButton'
import SecondaryButton from 'components/buttons/SecondaryButton'
import SecondaryCard from 'components/cards/SecondaryCard'
import { HOWITWORKSDATA, WHY_US_CARD_DATA } from '../../utils/constants'
import USlogo from 'assets/icons/USlogo.png'
import WhyUsCard from 'components/cards/WhyUsCard'
import PrimaryCard from 'components/cards/PrimaryCard'
import { useNavigate } from 'react-router-dom'
import { S } from './landingpage.style'
import { fetchQueries } from 'utils/constants/config'
import { useQuery } from 'react-query'
import { fetchDummyCountries } from 'utils/constants/queries'
import PurchaseModal from 'components/modals/PurchaseModal'
import { WalletContext } from 'contexts/wallet'
import WarningAlert from 'components/notification/WarningAlert'

import { HOW_TO_WORKS_CARD } from 'utils/constants/index'

const LandingPage: FC = () => {
  const navigate = useNavigate()
  const matches = useMediaQuery('(max-width:600px)')
  const match2 = useMediaQuery('(max-width:1360px)')
  const [showModal, setShowModal] = useState<any>(false)
  const { modalDataHandler } = useContext(WalletContext)

  const [updatedCountryList, setUpdatedCountryList] = useState([])
  // const [countryList, setCountryList] = useState([])
  // const [isLoading, setIsLoading] = useState(false)

  const { data: countryList, isLoading } = useQuery('getDummyCountries', () =>
    fetchQueries(fetchDummyCountries(variable)),
  )

  useEffect(() => {
    let temp = countryList?.data?.data?.countries.sort((a: any, b: any) =>
      b.packs[0].priceInWifiToken.toString().localeCompare(a.packs[0].priceInWifiToken.toString()),
    )
    setUpdatedCountryList(temp)
  }, [countryList])

  const variable = {
    where: {
      country: ['Europe', 'Global', 'United States'],
    },
    packsWhere2: {
      dataLimitInBytes: 5368709120,
    },
  }

  const { data: usaData } = useQuery('getCountryByUSA', () => fetchQueries(fetchDummyCountries(variables)))
  const variables = {
    where: {
      country: 'United States',
    },
    packsWhere2: {
      dataLimitInBytes: 5368709120,
    },
  }
  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <S.BoxCustomContainer>
        <WarningAlert
          type="info"
          content="Connect your Polkadot.js wallet and visit the Earn page to claim your devnet tokens."
        />
        <S.CustomContainer
          sx={{
            justifyContent: `${match2 ? 'center' : 'space-between'}`,
            gap: `${match2 ? '5rem' : '0'}`,
            padding: `${match2 ? '2rem 0 5rem 0' : '0 6rem'}`,
            height: `${match2 ? '100%' : '70vh'}`,
            flexDirection: `${match2 ? 'column' : 'row'}`,
          }}
        >
          <S.HeroContainer>
            <S.HeroHeaderText>
              <span style={{ color: '#45B549' }}>Global internet</span> <br /> accessible to anyone <br />
              owned by everyone
            </S.HeroHeaderText>
            <S.HeroSubHeaderText>
              Your crypto mobile data plan — It pays to connect <br />
              Earn 13% APY + 5% of sales when you stake
            </S.HeroSubHeaderText>
            <S.BtnsContainer>
              <PrimaryButton
                style={{ height: '3.75rem', width: `${matches ? '100%' : '11.75rem'}` }}
                onClick={() => navigate('/shop')}
              >
                Shop
              </PrimaryButton>
              <SecondaryButton
                style={{
                  height: '3.75rem',
                  width: `${matches ? '100%' : '11.75rem'}`,
                  marginLeft: `${matches ? '0rem' : '2rem'}`,
                }}
                onClick={() => navigate('/earn')}
              >
                Earn
              </SecondaryButton>
            </S.BtnsContainer>
          </S.HeroContainer>
          <S.HeroCardContainer>
            {usaData?.data?.data?.countries.map((val: any) => {
              let packs = val?.packs[0]
              return (
                <S.CustomCard sx={{ position: 'relative' }} key={val.id}>
                  <div>
                    <S.CustomCardHeader>
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <S.MainText>
                          {packs?.dataLimitInBytes / (1024 * 1024) / 1024}GB
                          <span
                            style={{
                              fontWeight: '700',
                              fontSize: '1.125rem',
                              marginLeft: '0.5rem',
                            }}
                          >
                            4G/LTE
                          </span>
                        </S.MainText>
                      </Box>
                      <S.CountryBox>
                        {val.country}
                        <img src={USlogo} alt="" style={{ marginLeft: '0.5rem' }} />
                      </S.CountryBox>
                    </S.CustomCardHeader>
                  </div>
                  <S.CustomCardFooter>
                    <S.PriceContainer>
                      <s style={{ color: 'red', textDecorationThickness: '3px' }}>
                        <S.StrikedPrice>40 GNT</S.StrikedPrice>
                      </s>
                      <S.MainPrice>{packs?.priceInWifiToken} GNT</S.MainPrice>
                    </S.PriceContainer>
                    <S.MoreInfoContainer>
                      <S.MoreInfoButton
                        onClick={async () => {
                          await setShowModal(true)
                          let manipulatedData = {
                            ...packs,
                            country: val?.country,
                            color: val?.countryColor,
                            flagCountryCode: val?.flagCountryCode,
                          }
                          modalDataHandler({
                            data: manipulatedData,
                            modalCountryData: usaData?.data?.data?.countries[0],
                          })
                        }}
                      >
                        Preview and Buy
                      </S.MoreInfoButton>
                    </S.MoreInfoContainer>
                  </S.CustomCardFooter>
                </S.CustomCard>
              )
            })}
          </S.HeroCardContainer>
        </S.CustomContainer>
      </S.BoxCustomContainer>

      <S.DataPlansContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <S.HeaderText>Explore data plans</S.HeaderText>
        </Box>
        <Grid container sx={{ padding: '2rem 0' }}>
          {isLoading && (
            <S.DataPlansDataContainer
              item
              sx={{
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </S.DataPlansDataContainer>
          )}
          <S.DataPlansDataContainer
            item
            sx={{
              justifyContent: `${match2 ? 'center' : 'space-between'}`,
              gap: `${match2 ? '2rem' : '0'}`,
            }}
          >
            {updatedCountryList?.map((val: any) => {
              let country = val.country
              let countryColor = val.countryColor
              let countryFlag = val.flagCountryCode

              return (
                <Fragment key={val.id}>
                  {val.packs.map((pack: any) => {
                    return (
                      <PrimaryCard
                        key={pack.id}
                        data={pack}
                        country={country}
                        flag={countryFlag}
                        color={countryColor}
                        countryData={val}
                        handleOpen={() => setShowModal(true)}
                      />
                    )
                  })}
                </Fragment>
              )
            })}
          </S.DataPlansDataContainer>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2.5rem' }}>
          <SecondaryButton
            style={{
              height: '3.75rem',
              width: '24.125rem',
              marginLeft: '0',
            }}
            onClick={() => navigate('/shop')}
          >
            Find more at the Shop
          </SecondaryButton>
        </Box>
      </S.DataPlansContainer>
      <S.HowItWorksContainer>
        <S.HowItWorksHeader>
          <S.HeaderText>How It Works</S.HeaderText>
          <S.HeaderSubText>Connect to earn in three easy steps</S.HeaderSubText>
        </S.HowItWorksHeader>
        <S.HowItWorksBody
          sx={{
            justifyContent: `${match2 ? 'center' : 'space-between'}`,
            gap: `${match2 ? '4rem' : '0'}`,
          }}
        >
          {HOW_TO_WORKS_CARD.map((item) => (
            <div key={item.id}>
              <S.DataPlan>
                <img src={item.svgImg} />
                <S.DataPlanText
                  sx={{
                    paddingTop: `${match2 ? '1rem' : '3rem'}`,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </S.DataPlan>
            </div>
          ))}
        </S.HowItWorksBody>

        {/* <S.HowItWorksDataContainer
          container
          sx={{
            justifyContent: `${match2 ? 'center' : 'space-between'}`,
            gap: `${match2 ? '2rem' : '0'}`,
          }}
        >
          {HOWITWORKSDATA.map((data: HOW_IT_WORKS_DATATYPE) => {
            return (
              <Grid item key={data.id}>
                <SecondaryCard data={data} />
              </Grid>
            )
          })}
        </S.HowItWorksDataContainer> */}
      </S.HowItWorksContainer>
      <S.WhyUsBoxContainer>
        <S.WhyUsContainer>
          <Box>
            <S.BigText>Why choose GIANT?</S.BigText>
            <S.WhyUsSubText>
              Join a new connectivity economy built on top of decentralized internet <br /> access layer owned and
              operated by the community
            </S.WhyUsSubText>
          </Box>
          <Grid container sx={{ padding: '2rem 0' }}>
            <S.WhyUsDataContainer
              item
              sx={{
                justifyContent: `${match2 ? 'center' : 'space-between'}`,
                gap: `${match2 ? '2rem' : '0'}`,
              }}
            >
              {WHY_US_CARD_DATA.map((data: WHY_US_DATATYPE) => {
                return (
                  <Grid item key={data.id}>
                    <WhyUsCard data={data} />
                  </Grid>
                )
              })}
            </S.WhyUsDataContainer>
          </Grid>
          <Grid>
            <S.GreenText>
              <a href="https://giantprotocol.org/" target="_blank" style={{ color: '#45B549' }}>
                <i className="fa fa-info-circle" aria-hidden="true">
                  &nbsp;
                </i>
                Learn more about GIANT Protocol
              </a>
            </S.GreenText>
          </Grid>
        </S.WhyUsContainer>
      </S.WhyUsBoxContainer>

      <PurchaseModal show={showModal} handleCloseModal={handleClose} location="landing-page" />
    </>
  )
}

export default LandingPage
