import React, { FC, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Select, { components, ControlProps } from 'react-select'
import ReactCountryFlag from 'react-country-flag'

import { S } from './modal.styles'
import DownArrow from 'assets/icons/DownArrowWhite.svg'
import USLogo from 'assets/icons/UsaIcon.svg'
import QRImg from 'assets/gaintQR.png'
import CloseIcon from 'assets/icons/CloseIcon.svg'
import WorldIcon from 'assets/icons/WorldIcon.svg'
import ShareIcon from 'assets/icons/shareIcon.svg'
import linkIcon from 'assets/icons/linkIcon.svg'
import copyIcon from 'assets/icons/copyIcon.svg'
import CheckIcon from 'assets/icons/checkIcon.png'
import ActivateNowIllustration from 'assets/ActivateNowIllustration.svg'
import GiftIllustration from 'assets/GiftIllustration.svg'
import AlertIcon from 'assets/icons/AlertIcon.svg'
import ShareIllustration from 'assets/ShareIllustration1.svg'
import WarningIcon from 'assets/icons/WarningIcon.svg'

import { DATAPLANS } from 'utils/constants'
import { useWalletContext, WalletContext } from 'contexts/wallet'
import { tohex, toWei } from 'utils/utils'
import { toast } from 'react-toastify'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { CircularProgress, Tooltip, useMediaQuery } from '@mui/material'
import { createInstance, getAccountByAddress, getFaucetStatus, updateAccount } from 'utils/constants/config'
import ShareModal from './shareModal'
import { handleMint } from 'polkadot/sendmethods/provider'
import { handleGift, handleRedeem } from 'polkadot/sendmethods/dct'

import QRCode from 'react-qr-code'
interface Props {
  show: boolean
  handleCloseModal: () => void
  location?: string
}

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <>
      <components.Control {...props}>
        {children} <img src={DownArrow} alt="arrow" />
      </components.Control>
    </>
  )
}

const PurchaseModal: FC<Props> = ({ show, handleCloseModal, location }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [selectPlan, setSelectPlan] = useState<any>({ value: 1, label: '1GB' })
  const [countryPlan, setCountryPlan] = useState<any>([])
  const [type, setType] = useState<number>(0)
  const [shareModal, setShareModal] = useState<boolean>(false)

  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [copyState, setCopyState] = useState<boolean>(false)
  const [disabledValue, setDisabledValue] = useState<boolean>(true)
  const [giftCode, setGiftCode] = useState<string>('')
  const [instanceId, setInstanceId] = useState<number>(0)
  const [redeemLoading, setRedeemLoading] = useState<boolean>(false)
  const [giftLoading, setGiftLoading] = useState<boolean>(false)
  const [referralCode, setReferralCode] = useState<string>('')
  const { modalData, modalCountryData, demandReward } = useContext(WalletContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { isConnected, connectedAccount, assetMetadata, assetBalance, balance } = useWalletContext()
  const mobileView = useMediaQuery('(max-width:768px)')

  let shareData = {
    url: 'https://loacakamsd.com',
    text: 'You can share the claimable eSIM from GiantConnect',
    title: 'GiantConnect Gift Card',
  }

  const handleIsConnectToast = () => toast.error('Wallet is Not Connected')

  useEffect(() => {
    if (modalData) {
      let packSize = modalData?.dataLimitInBytes / (1024 * 1024) / 1024
      setSelectPlan({ value: packSize.toString(), label: `${packSize}GB` })
    }
  }, [modalData?.dataLimitInBytes])

  useEffect(() => {
    if (modalCountryData?.packs?.length > 0) {
      let filteredData = modalCountryData?.packs?.filter(
        (data: any) => data.dataLimitInBytes / (1024 * 1024) / 1024 === parseInt(selectPlan.value),
      )
      setCountryPlan(filteredData)
    }
  }, [selectPlan])

  useEffect(() => {
    if (
      isChecked === true &&
      parseFloat(balance?.full) > 0 &&
      modalData?.priceInWifiToken < parseFloat(balance?.full)
    ) {
      setDisabledValue(false)
    } else {
      setDisabledValue(true)
    }
  }, [connectedAccount, balance, isChecked, modalData])

  useEffect(() => {
    if (connectedAccount.address) {
      getFaucetStatus(connectedAccount.address).then((res) => {
        setReferralCode(res?.referral)
      })
    }
  }, [connectedAccount.address])

  const handleGiftSuccess = async (type: any, code: string) => {
    setGiftCode(code)
    setType(type)
  }


  return (
    <div>
      <Modal
        open={show}
        onClose={() => {
          setType(0)
          setIsChecked(false)
          handleCloseModal()
          location === 'landing-page' ? navigate('/') : navigate('/shop')
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.ModalMainContainer>
          <S.ModalHeader
            sx={{
              background: `linear-gradient(113.74deg, ${
                modalCountryData?.countryColor?.start ? modalCountryData?.countryColor?.start : '#1471DE'
              } 9.6%, ${
                modalCountryData?.countryColor?.end ? modalCountryData?.countryColor?.end : '#109D6A'
              } 91.34%);`,
            }}
          >
            {' '}
            <Box>
              {/* <Select
                options={DATAPLANS}
                value={selectPlan}
                onChange={setSelectPlan}
                isSearchable={false}
                components={{ Control }}
                placeholder={'Choose your country'}
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    display: 'none',
                  }),
                  control: (base) => ({
                    ...base,
                    backgroundColor: 'none',
                    border: 'none',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    fontWeight: 700,
                    fontSize: '2.25rem',
                    lineHeight: '2.9375rem',
                    color: '#0B213E',
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    display: 'none',
                    width: '10rem',
                  }),
                }}
              /> */}
              <Typography sx={{ fontWeight: '700', fontSize: '2.25rem' }}>
                {modalData?.dataLimitInBytes / (1024 * 1024) / 1024}GB
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ fontWeight: 700, fontSize: '2rem', marginRight: '0.5rem' }}>{modalData?.country}</p>
              {modalData?.flagCountryCode === 'Global' ? (
                <img src={WorldIcon} alt="currency img" style={{ width: '28px', height: '28px' }} />
              ) : (
                <ReactCountryFlag countryCode={modalData?.flagCountryCode} style={{ paddingTop: '10px' }} />
              )}
            </Box>
            <img
              src={CloseIcon}
              alt=""
              onClick={() => {
                setType(0)
                handleCloseModal()
                setIsChecked(false)
                location === 'landing-page' ? navigate('/') : navigate('/shop')
              }}
              style={{ position: 'absolute', right: '1rem', top: '1rem', cursor: 'pointer' }}
            />
          </S.ModalHeader>
          {(type === 0 || type === 2) && (
            <S.ModalBody>
              <S.DataContainer>
                <S.TitleContainer>
                  <S.TitleText>Active Period</S.TitleText>
                  <S.ValueText>30 Days</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>Service Type</S.TitleText>
                  <S.ValueText>4G LTE</S.ValueText>
                </S.TitleContainer>
                <S.TitleContainer>
                  <S.TitleText>GNT Rewards</S.TitleText>
                  <S.ValueText>
                    {(modalData?.priceInWifiToken ? modalData?.priceInWifiToken * demandReward : 0).toFixed(2)} GNT
                  </S.ValueText>
                </S.TitleContainer>
              </S.DataContainer>
              {type === 0 && (
                <S.DynamicContainer>
                  <S.AggrementText>
                    4G LTE plans are non-refundable and non-transferable. Please ensure your device is unlocked AND{' '}
                    <br />
                    supports eSim. To verify, you can check&nbsp;
                    <a
                      style={{
                        fontWeight: 700,
                        fontSize: '1rem',
                        lineHeight: '21px',
                        color: '#45B549',
                        textDecoration: 'none',
                      }}
                      href="https://giantconnect.zendesk.com/hc/en-us/articles/4419817382679-e-sim-FAQs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eSim FAQs {'  '}
                    </a>
                    and&nbsp;
                    <a
                      style={{
                        fontWeight: 700,
                        fontSize: '1rem',
                        lineHeight: '21px',
                        color: '#45B549',
                        textDecoration: 'none',
                      }}
                      href="https://giantconnect.zendesk.com/hc/en-us/articles/4419826397847-How-to-setup-e-sim"
                      target="_blank"
                      rel="noreferrer"
                    >
                      eSim setup guide.
                    </a>{' '}
                  </S.AggrementText>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: '2rem',
                      gap: '0.5rem',
                    }}
                  >
                    <S.CheckBox type="checkbox" value={isChecked} onChange={() => setIsChecked(!isChecked)} />{' '}
                    <S.AggreeText> I agree with the above terms and conditions</S.AggreeText>
                  </Box>
                  <S.MainContent sx={{ marginTop: '2rem' }}>
                    <S.ConfirmBtn
                      // onClick={() => {
                      //   isConnected === true
                      //     ? handleMint(countryPlan[0], connectedAccount, countryPlan[0]?.priceInWifiToken)
                      //     : handleIsConnectToast()
                      //   handlePurchase()
                      // }}
                      onClick={() => {
                        if (isConnected === true) {
                          console.log("clicked")
                          handleMint(
                            modalData,
                            connectedAccount,
                            modalData?.priceInWifiToken,
                            setIsLoading,
                            assetMetadata,
                            setType,
                            setInstanceId,
                          )
                        } else {
                          handleIsConnectToast()
                        }
                         ;(window as any).analytics.track('Clicked on Confirm Purchase button', {
                           country: modalData?.country,
                           dataLimitInBytes: modalData?.dataLimitInBytes,
                           price: modalData?.priceInWifiToken,
                           serviceType: '4G/LTE',
                           rewards: (modalData?.priceInWifiToken * demandReward).toFixed(2),
                         })
                        // setType(3)
                        // handlePurchase()
                      }}
                      disabled={disabledValue}
                    >
                      {isLoading === true ? (
                        <CircularProgress color="success" size={25} />
                      ) : (
                        `${parseFloat(balance?.full) > 0 ? 'Confirm Purchase' : 'Insufficient funds'}`
                      )}
                    </S.ConfirmBtn>
                    <S.GntText>{modalData?.priceInWifiToken} GNT</S.GntText>
                  </S.MainContent>
                </S.DynamicContainer>
              )}
              {type === 2 && (
                <div style={{ height: '80%', display: 'grid', placeContent: 'center' }}>
                  <S.SuccessContainer>
                    <S.SuccessText>You successfully activated your plan!</S.SuccessText>
                    <S.StatusText>
                      You can always check your plan status on your{' '}
                      <a href="#" style={{ textDecoration: 'none', color: '#45B549' }}>
                        wallet
                      </a>
                    </S.StatusText>
                  </S.SuccessContainer>
                </div>
              )}
            </S.ModalBody>
          )}
          {type === 1 && (
            <>
              <S.ModalWarning>
                {' '}
                <img src={WarningIcon} alt="" /> &nbsp; This runs on devnet and no real money or data is involved.
              </S.ModalWarning>
              <S.CongratsText sx={{ textAlign: 'center', padding: '4rem 0 0 0' }}>
                Thank you for participating in our devnet!
              </S.CongratsText>
              <S.CustomDynamicContainer>
                <S.NotDoneContainer>
                  <S.TextWrapper>
                    <S.RoundWrapper>
                      1
                      <span
                        style={{
                          position: 'absolute',
                          background: '#00C853',
                          height: '100%',
                          width: '2px',
                          bottom: '-1.8rem',
                          left: '1rem',
                        }}
                      ></span>
                    </S.RoundWrapper>
                    <Box>
                      <S.ShareHeaderText>Share with your community</S.ShareHeaderText>
                      <S.ShareHeaderSubText>
                        Share your referral code <b>{referralCode}</b> with friends for a chance to earn mobile data for 12 months
                      </S.ShareHeaderSubText>
                    </Box>
                  </S.TextWrapper>
                  <S.TextWrapper>
                    <S.RoundWrapper>
                      2
                      <span
                        style={{
                          position: 'absolute',
                          background: '#00C853',
                          height: '100%',
                          width: '2px',
                          bottom: '-1.8rem',
                          left: '1rem',
                        }}
                      ></span>
                    </S.RoundWrapper>

                    <Box>
                      <S.ShareHeaderText>Scan QR code for real data and rewards</S.ShareHeaderText>
                      <S.ShareHeaderSubText>
                        Use your phone camera to scan the QR code to download the GIANT Connect mobile app
                      </S.ShareHeaderSubText>
                    </Box>
                  </S.TextWrapper>
                  <S.TextWrapper>
                    <S.RoundWrapper>3</S.RoundWrapper>
                    <Box>
                      <S.ShareHeaderText>Connect and earn</S.ShareHeaderText>
                      <S.ShareHeaderSubText>
                        Buy cellular eSIM data in 118 countries through GIANT Connect to earn GIANT rewards
                      </S.ShareHeaderSubText>
                    </Box>
                  </S.TextWrapper>
                </S.NotDoneContainer>
                <Box
                  sx={{
                    display: 'grid',
                    placeContent: 'center',
                    border: '5.98131px solid #CED6E0',
                    borderRadius: '9.5px',
                    padding: '20px',
                  }}
                >
                  <QRCode size={200} value="https://devapp.giantprotocol.org/thanks" />
                </Box>
              </S.CustomDynamicContainer>
            </>
          )}
          {type === 3 && (
            <S.CustomModalBody>
              <S.ActivateCongratsText>Congratulations! Now connect to earn GNT rewards!</S.ActivateCongratsText>
              <S.ActivateSubContainer>
                <S.ActivateContainer>
                  <img src={ActivateNowIllustration} style={{ height: '120px' }} alt="" />
                  <S.ConfirmBtn
                    onClick={() => {
                      handleRedeem(connectedAccount, instanceId, setRedeemLoading, setType)
                      ;(window as any).analytics.track('Clicked on Activate Now button', {
                        country: modalData?.country,
                        dataLimitInBytes: modalData?.dataLimitInBytes,
                        price: modalData?.priceInWifiToken,
                        serviceType: '4G/LTE',
                        rewards: (modalData?.priceInWifiToken * demandReward).toFixed(2),
                      })
                    }}
                    sx={{ width: '16rem', alignSelf: 'center' }}
                  >
                    {redeemLoading ? <CircularProgress color="success" size={25} /> : 'Activate Now'}
                  </S.ConfirmBtn>
                </S.ActivateContainer>
                <S.ActivateContainer>
                  <img src={GiftIllustration} style={{ height: '120px' }} alt="" />
                  <S.ConfirmBtn
                    onClick={() => {
                      handleGift(connectedAccount, instanceId, setGiftLoading, handleGiftSuccess)
                      ;(window as any).analytics.track('Clicked on Send as gift button', {
                        country: modalData?.country,
                        dataLimitInBytes: modalData?.dataLimitInBytes,
                        price: modalData?.priceInWifiToken,
                        serviceType: '4G/LTE',
                        rewards: (modalData?.priceInWifiToken * demandReward).toFixed(2),
                      })
                    }}
                    sx={{ width: '16rem', alignSelf: 'center' }}
                  >
                    {giftLoading ? <CircularProgress color="success" size={25} /> : 'Send as gift'}
                  </S.ConfirmBtn>
                </S.ActivateContainer>
              </S.ActivateSubContainer>
              <S.ActivateCongratSubText>
                <span>
                  {' '}
                  <img src={AlertIcon} alt="" />
                  &nbsp;{' '}
                </span>
                Do NOT close this window or you will loose your eSIM
              </S.ActivateCongratSubText>
              {/* <S.CongratsContainer>
                <S.CongratsText>Congratulations! eSIM plan purchased successfully!</S.CongratsText>
                <S.CongratsText>Now you can activate it or gift it to someone</S.CongratsText>
              </S.CongratsContainer> */}
              {/* <S.BtnContainer>
                <S.ConfirmBtn 
                  onClick={() => {
                    handleRedeem(connectedAccount, instanceId, setRedeemLoading, setType)
                  }}
                >
                  {redeemLoading ? <CircularProgress color="success" size={25} /> : 'activate'}
                </S.ConfirmBtn>
                <S.ConfirmBtn
                  onClick={() => {
                    handleGift(connectedAccount, instanceId, setGiftLoading, handleGiftSuccess)
                  }}
                >
                  {giftLoading ? <CircularProgress color="success" size={25} /> : 'Send as gift'}
                </S.ConfirmBtn>
              </S.BtnContainer> */}
            </S.CustomModalBody>
          )}
          {type === 4 && (
            <S.ShareBody>
              <S.CongratsText>Share this unique URL with your friend.</S.CongratsText>
              <img src={ShareIllustration} alt="" />
              {giftCode && (
                <S.CopyContainer>
                  <S.LinkIconContainer>
                    <img src={linkIcon} style={{ height: '15px', width: '15px', marginRight: '0.5rem' }} alt="" />
                  </S.LinkIconContainer>
                  <S.CopyInput
                    readOnly
                    onClick={(e: any) => e.target.select()}
                    value={`${window.location.origin}/gift/${giftCode}`}
                  />
                  <Tooltip title={`${copyState ? 'copied!' : 'copy to clipboard'}`}>
                    <S.CopyIconContainer
                      onClick={() => {
                        setCopyState(true)
                        setTimeout(() => {
                          setCopyState(false)
                        }, 2000)
                        navigator.clipboard.writeText(`${window.location.origin}/gift/${giftCode}`)
                      }}
                    >
                      {!copyState ? (
                        <img src={copyIcon} alt="" />
                      ) : (
                        <img src={CheckIcon} style={{ height: '15px', width: '15px' }} alt="" />
                      )}
                    </S.CopyIconContainer>
                  </Tooltip>
                  {mobileView ? (
                    <S.ShareIconContainer onClick={() => navigator.share(shareData)}>
                      <img src={ShareIcon} alt="" />
                    </S.ShareIconContainer>
                  ) : (
                    <S.ShareIconContainer onClick={() => setShareModal(true)}>
                      <img src={ShareIcon} alt="" />
                    </S.ShareIconContainer>
                  )}
                </S.CopyContainer>
              )}

              {/* <S.CongratsSubText to="/shop/gift/abc123">${window.location.origin}/gift/abc123</S.CongratsSubText>
              <S.ConfirmBtn sx={{ maxWidth: '5rem' }} onClick={() => navigator.clipboard.writeText('copied!')}>
                Copy
              </S.ConfirmBtn> */}
              <S.NoteText>Note: On clicking the URL, they will be able to activate the data plan instantly</S.NoteText>
            </S.ShareBody>
          )}
        </S.ModalMainContainer>
      </Modal>
      {shareModal && (
        <ShareModal
          setType={setType}
          shareUrl={`${window.location.origin}/gift/${giftCode}`}
          onClose={() => setShareModal(false)}
        />
      )}
    </div>
  )
}

export default PurchaseModal
