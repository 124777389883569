import React, { useState } from 'react'
import { Typography, Box, Button } from '@mui/material'
import { S } from './styled'
import Polkadot from 'assets/icons/Polkadot.svg'
import CoinImg from 'assets/coinImg.png'
import ConnectWalletModal from '../../components/layout/header/ConnetWalletModal'

const DisconnectedToken = () => {
  const [connect, setConnect] = useState(false)

  const handlePolkadot = () => {
    const hasExt = window.injectedWeb3
    if (Object.keys(hasExt).includes('polkadot-js')) setConnect(true)
    else window.open('https://polkadot.js.org/extension/')
  }

  return (
    <>
      <S.TokenCard sx={{ display: 'grid', gridTemplateRows: '1fr 1fr', gap: '1.5rem' }}>
        <Box>
          <S.MyWalletSubText
            sx={{
              borderBottom: '1px solid #DEE7FD',
            }}
          >
            Connect with one of our available wallet providers or create an account.
          </S.MyWalletSubText>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Button
            sx={{
              width: '100%',
              background: 'rgba(69, 181, 73, 0.1)',
              borderRadius: '0.5rem',
              padding: '0.75rem',
              alignItems: 'center',
              gap: '0.5rem',
              textTransform: 'none',
            }}
            onClick={handlePolkadot}
          >
            <img src={Polkadot} alt="" />
            <Typography sx={{ fontSize: '1.125rem', fontWeight: 'bold', color: '#0B213E' }}>
              polkadot &#123;.js&#125;
            </Typography>
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box sx={{ width: '30%', display: 'grid', placeContent: 'center' }}>
            <img src={CoinImg} style={{ height: '60px', width: '60px' }} alt="" />
          </Box>
          <S.MyWalletTextContainer>
            <S.MyWalletSubText>
              Once you connect a wallet you'll be eligible to receive test tokens for free
            </S.MyWalletSubText>
          </S.MyWalletTextContainer>
        </Box>
        <ConnectWalletModal isToggle={connect} handleModalClose={() => setConnect(false)} />
      </S.TokenCard>
    </>
  )
}

export default DisconnectedToken
