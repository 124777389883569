import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import GiantTokens from './GiantTokens'
import GiantProtocol from './GiantProtocol'
import GiantConnectTable from './GiantConnectTable'
import ProtocolLogo from 'assets/icons/GiantProtocolLogo.svg'
import { LEGENDS } from '../../utils/constants'
import { S } from './styled'
import { createInstance, updateAccount, getAccountByAddress, getFaucetStatus } from 'utils/constants/config'
import { useWalletContext } from 'contexts/wallet'
import { getDifference } from 'utils/utils'
import MyDataPlans from './MyDataPlans'
import WarningAlert from 'components/notification/WarningAlert'

const Wallet = () => {
  const { isConnected, connectedAccount } = useWalletContext()

  return (
    <>
      <WarningAlert
        type="warning"
        content="This runs on devnet and no real money is involved. Expect things to break with future releases."
      />
      <S.WalletContainer>
        <Grid
          sx={{
            display: 'flex',
            gridColumnGap: '2rem',
            flexWrap: 'wrap',
            gridRowGap: '1.5rem',
          }}
        >
          <GiantTokens />
          <GiantProtocol
            flex="2 1"
            source={ProtocolLogo}
            hasFee={true}
            value={LEGENDS}
            justifyContent="space-between"
            showCharts={true}
          />
        </Grid>
        {/* <GiantConnect /> */}
        <MyDataPlans />
        {/* <GiantProtocol source={ProtocolLogo} hasFee={false} value={CONNECT_VALUES} showCharts={false} /> */}
        <GiantConnectTable />
      </S.WalletContainer>
    </>
  )
}

export default Wallet
