import { createContext, useContext, useEffect, useReducer } from 'react'
import { getItem } from '../services/localStorage/localStorage.service'
import { fetchStakeBalance, getPolkadotAccounts, handleEvents, handleWalletConnect } from '../polkadot'
import { toast } from 'react-toastify'
import { createAccount, getAccountByAddress, getCurrentAccounts } from 'utils/constants/config'
import { getInstanceByAccount } from 'polkadot/callmethods/dct'
import { getAssetBalance } from 'polkadot/callmethods/assets'
import { getBalance } from 'polkadot/callmethods/system'
import { getCurrentRewardDemand } from 'polkadot/callmethods/provider'

export enum API_STATE {
  CONNECT_INIT,
  CONNECTING,
  READY,
  ERROR,
  NULL,
}

interface WalletContextType {
  isConnected: boolean
  isConnecting: boolean
  connectedAccount: any
  demandReward: any
  accounts: any
  balance: {
    full: string
    deci: string
  }
  assetBalance: any
  stakeBalance: {
    total: string
    earnedBalance: string
  }
  assetMetadata: any
  api: any
  apiState: API_STATE
  modalCountryData: any
  modalData: any
  isLogged: boolean
  walletConnectHandler: (account: any) => void
  accountHandler: () => void
  disconnectHandler: () => void
  modalDataHandler: (data: any) => void
  getInstanceHandler: (account: any) => void
}

const initialState: WalletContextType = {
  isConnected: false,
  isConnecting: false,
  connectedAccount: {},

  accounts: [],
  balance: {
    full: '0',
    deci: '0',
  },
  demandReward: 0,
  assetBalance: '0',
  stakeBalance: {
    total: '0',
    earnedBalance: '0',
  },
  assetMetadata: {
    name: 'Giant',
    symbol: 'GNT',
    decimals: 6,
    isFrozen: false,
    deposit: 0,
  },
  api: null,
  apiState: API_STATE.NULL,
  modalData: [],
  modalCountryData: [],
  isLogged: false,
  walletConnectHandler: () => {},
  accountHandler: () => {},
  disconnectHandler: () => {},
  modalDataHandler: () => {},
  getInstanceHandler: () => {},
}

export const ASSETID = 1

const reducer = (state: WalletContextType, action: any) => {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return { ...state, accounts: action.payload }
    case 'CONNECT_INIT':
      return { ...state, isConnecting: action.payload }
    case 'CONNECTED':
      return { ...state, isConnecting: false, isConnected: action.payload._isConnected }
    case 'CONNECT_SUCCESS':
      return {
        ...state,
        isConnecting: false,
        isConnected: true,
        connectedAccount: action.payload.account,
        balance: action.payload.bal,
        assetBalance: action.payload.assetBal,
        stakeBalance: action.payload.stakeBal,
      }
    case 'UPDATE_BALANCE':
      return { ...state, balance: action.payload }
    case 'DISCONNECT':
      return { ...state, isConnected: false, connectedAccount: '', isConnecting: false }
    case 'SET_METADATA':
      return { ...state, assetMetadata: action.payload }
    case 'SET_API':
      return { ...state, api: action.payload }
    case 'SET_API_STATE':
      if (action.payload === API_STATE.ERROR) {
        toast.error('Error connecting to RPC, trying to reconnect', {})
      }
      return { ...state, apiState: action.payload }
    case 'SET_STAKE_BALANCE':
      return { ...state, stakeBalance: action.payload }
    case 'SET_ASSET_BALANCE':
      return { ...state, assetBalance: action.payload }
    case 'SET_MODAL_DATA':
      return { ...state, modalData: action.payload.data, modalCountryData: action.payload.modalCountryData }
    case 'SET_LOGGED':
      return { ...state, isLogged: action.payload }
    case 'SET_REWARD_DEMAND':
      return { ...state, demandReward: action.payload }
    default:
      throw new Error(`Unknown type: ${action.type}`)
  }
}

export const WalletContext = createContext<WalletContextType>(initialState)

function WalletProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.apiState === API_STATE.READY) {
      // getProvidersList()
      getCurrentRewardDemand().then((res) => {
        dispatch({ type: 'SET_REWARD_DEMAND', payload: res })
      })
    }
  }, [state.apiState, state.assetMetadata])

  useEffect(() => {
    handleEvents(dispatch)
  }, [dispatch])

  useEffect(() => {
    const _isConnected = getItem<boolean>('isConnected')
    dispatch({ type: 'CONNECTED', payload: { _isConnected } })
  }, [])

  useEffect(() => {
    const fetchBalance = async () => {
      if (state.connectedAccount !== '') {
        let balance = await getBalance(state.connectedAccount)
        dispatch({ type: 'UPDATE_BALANCE', payload: balance })
      }
    }

    const getStakeBalance = async () => {
      if (state.accounts.length) {
        let stakeBalance = await fetchStakeBalance(state.connectedAccount)
        dispatch({ type: 'SET_STAKE_BALANCE', payload: stakeBalance })
      }
    }

    // const handleAssetBalance = async () => {
    //   if (state.accounts.length) {
    //     let assetBalance = await getAssetBalance(state.connectedAccount)
    //     dispatch({ type: 'SET_ASSET_BALANCE', payload: assetBalance })
    //   }
    // }

    let interval = setInterval(() => {
      fetchBalance()
      getStakeBalance()
      // handleAssetBalance()
    }, 5000)
    return () => clearInterval(interval)
  }, [state.connectedAccount.address, state.assetMetadata?.decimals])

  // useEffect(() => {
  // }, [state.api])

  const handleWallet = async (account: any) => {
    getAccountByAddress(account.address).then((res) => {
      if (res.length > 0) {
        dispatch({ type: 'SET_LOGGED', payload: true })
      } else {
        createAccount({
          address: account.address,
        }).then(() => {
          dispatch({ type: 'SET_LOGGED', payload: true })
        })
      }
    })
    if (state.apiState === API_STATE.READY) {
      try {
        dispatch({ type: 'CONNECT_INIT', payload: true })
        let walletDetails = await handleWalletConnect(account, state?.assetMetadata?.decimals)
        if (walletDetails.isConnected) {
          dispatch({ type: 'CONNECT_SUCCESS', payload: walletDetails })
        } else {
          dispatch({ type: 'CONNECT_INIT', payload: false })
        }
      } catch (e) {
        dispatch({ type: 'CONNECT_INIT', payload: false })
      }
    }
  }

  const handlePolkadotAccounts = async () => {
    try {
      if (state.apiState === API_STATE.READY) {
        getPolkadotAccounts().then((accounts: any) => {
          dispatch({ type: 'SET_ACCOUNTS', payload: accounts.account })
        })
      } else {
        toast.error(
          <div
            onClick={() => handlePolkadotAccounts()}
            style={{ border: 'none', background: 'transparent', width: '100%', textAlign: 'left' }}
          >
            Please wait for the RPC to be ready,Click to Try again
          </div>,
          {},
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleModalData = (data: any) => {
    dispatch({ type: 'SET_MODAL_DATA', payload: data })
  }

  const handleDisconnect = () => {
    try {
      dispatch({ type: 'DISCONNECT' })
    } catch (err) {
      console.log(err)
    }
  }

  let data = {
    ...state,
    modalDataHandler: handleModalData,
    walletConnectHandler: handleWallet,
    accountHandler: handlePolkadotAccounts,
    disconnectHandler: handleDisconnect,
  }

  return <WalletContext.Provider value={data}>{props.children}</WalletContext.Provider>
}

const useWalletContext = () => useContext(WalletContext)

export { WalletProvider, useWalletContext }
