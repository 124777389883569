import React, { FC, useState } from 'react'
import { CircularProgress, IconButton, InputAdornment, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { S } from './styled'
import Polygon from 'assets/icons/Polygon.svg'

import { useWalletContext } from 'contexts/wallet'
import { handleStake } from 'polkadot/sendmethods/staking'
interface Props {
  open: boolean
  stakeEl: HTMLElement
  setStakeEl: (stakeEl: any) => void
}

const StakeOverlay: FC<Props> = ({ open, stakeEl, setStakeEl }) => {
  const matches = useMediaQuery('(max-width:768px)')
  const { connectedAccount, assetMetadata } = useWalletContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stakeAmount, setStakeAmount] = useState<string>('')

  return (
    <>
      <S.Backdrop onClick={() => setStakeEl(null)} />
      <S.CustomPopper
        open={open}
        anchorEl={stakeEl}
        sx={{
          border: '1px solid #45B549',
          padding: `${matches ? '2rem 1rem 1rem 1rem' : '0 0'}`,
          left: 'unset !important',
        }}
      >
        <img src={Polygon} alt="polygon" style={{ position: 'absolute', top: '-1rem', left: '12rem' }} />
        <S.StakeOverlayCard sx={{ flexDirection: `${matches ? 'column' : 'row'}` }}>
          <S.StakeTextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ color: '#66717B' }}>
                  GNT
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                '&::placeholder': {
                  color: 'black',
                },
              },
            }}
            placeholder="Enter amount to stake"
            onChange={(e) => setStakeAmount(e.target.value)}
          ></S.StakeTextField>
          <S.ConfirmBtn
            disabled={isLoading || stakeAmount === ''}
            sx={{ height: '42px', width: '103px' }}
            onClick={() => {
              ;(window as any).analytics.track('Clicked on Stake Confirm button', {
                stakeAmout: stakeAmount,
              })
              handleStake(stakeAmount, connectedAccount, setIsLoading, assetMetadata, setStakeEl)
            }}
          >
            {isLoading === true ? <CircularProgress color="inherit" size={25} /> : 'Confirm'}
          </S.ConfirmBtn>
          <IconButton
            onClick={() => setStakeEl(null)}
            sx={{
              position: `${matches ? 'absolute' : 'unset'}`,
              top: `${matches ? '0' : 'unset'}`,
              right: `${matches ? '0' : 'unset'}`,
              padding: `${!matches ? '0 !important' : '0.5rem'}`,
            }}
          >
            <CloseIcon sx={{ fontSize: '1.5rem', color: '#0B213E' }} />
          </IconButton>
        </S.StakeOverlayCard>
      </S.CustomPopper>
    </>
  )
}

export default StakeOverlay
