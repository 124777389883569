import { Box, Modal, Typography } from '@mui/material'
import React, { FC, useContext } from 'react'
import { S } from './styled'
import PolkadotImg from 'assets/icons/Polkadot.svg'
import ModalRightArrow from 'assets/icons/modal-right-arrow.svg'
import { WalletContext } from 'contexts/wallet'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

interface Props {
  isToggle: boolean
  handleModalClose: () => void
}

const ConnetWalletModal: FC<Props> = ({ isToggle, handleModalClose }) => {
  const { accounts, walletConnectHandler, accountHandler } = useContext(WalletContext)

  return (
    <>
      <Modal
        open={isToggle}
        onClose={() => {
          handleModalClose()
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <S.ModalContainer>
          <S.ModalHeading>
            <h2 id="parent-modal-title">Connect wallet</h2>
            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </S.ModalHeading>

          <S.ModalContent>
            {accounts.length > 0 ? (
              accounts.map((account: any) => {
                return (
                  <S.ModalContentBox
                    key={account.address}
                    onClick={async () => {
                      await walletConnectHandler(account)
                      handleModalClose()
                    }}
                  >
                    <S.ModalContentBoxItems sx={{ display: 'flex' }}>
                      <img src={PolkadotImg} alt="walletLogo" />
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>{account.meta.name}</Typography>
                        <Typography>
                          {account.address.substring(0, 6)}...{account.address.substring(account.address.length - 4)}
                        </Typography>
                      </Box>
                    </S.ModalContentBoxItems>
                    <img src={ModalRightArrow} alt="walletLogo" />
                  </S.ModalContentBox>
                )
              })
            ) : (
              <S.ModalContentBox
                onClick={() => {
                  accountHandler()
                }}
              >
                <S.ModalContentBoxItems sx={{ display: 'flex' }}>
                  <img src={PolkadotImg} alt="walletLogo" />
                  <Typography>Polkadot.js</Typography>
                </S.ModalContentBoxItems>
                <img src={ModalRightArrow} alt="walletLogo" />
              </S.ModalContentBox>
            )}
          </S.ModalContent>
        </S.ModalContainer>
      </Modal>
    </>
  )
}

export default ConnetWalletModal
