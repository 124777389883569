import Select, { components, ControlProps } from 'react-select'
import DownArrow from 'assets/icons/DownArrow.svg'
import { FC } from 'react'

interface Props {
  swapValue: any
  swapOption: any
  setSwapValue: any
  disable?:any
}

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <>
      <components.Control {...props}>
        {children} <img src={DownArrow} alt="arrow" />
      </components.Control>
    </>
  )
}

const SwapDropDown: FC<Props> = ({ swapOption, swapValue, setSwapValue, disable }) => {
  return (
    <>
      <Select
        options={swapOption}
        value={swapValue}
        onChange={setSwapValue}
        isSearchable={false}
        components={{ Control }}
        // isDisabled={true}
        isOptionDisabled={(option) => option === disable}
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: '#f1f3f5',
            border: 'none',
            padding: '0 0.8rem',
            outline: 'none',
            minHeight: '3.4375rem',
          }),
          singleValue: (base) => ({
            ...base,
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: '1.75rem',
            color: '#000000',
          }),
          indicatorsContainer: (base) => ({
            ...base,
            display: 'none',
          }),
        }}
      />
    </>
  )
}

export default SwapDropDown
