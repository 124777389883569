/* eslint-disable @typescript-eslint/no-unused-vars */
import jsonrpc from '@polkadot/types/interfaces/jsonrpc'
import { web3Accounts, web3Enable, web3FromSource } from '@polkadot/extension-dapp'
import { ApiPromise, WsProvider } from '@polkadot/api'
import { keyring as Keyring } from '@polkadot/ui-keyring'
import { isTestChain } from '@polkadot/util'

import { toast } from 'react-toastify'

import { setItem } from '../services/localStorage/localStorage.service'
import { API_STATE, ASSETID } from 'contexts/wallet'
import { toDecimal, transformParams } from 'utils/utils'
import { getInstanceByAccount } from './callmethods/dct'
import { getAssetBalance } from './callmethods/assets'
import { getStakes } from './callmethods/staking'
import { getBalance, retrieveChainInfo } from './callmethods/system'

// type HANDLE_WALLET_CONNECT = () => void
type GET_ACCOUNTS = () => Promise<any[]>

// const wsProvider = new WsProvider('wss://rpc.polkadot.io')
const wsProvider = new WsProvider(process.env.REACT_APP_WSPROVIDER)
export const apiProvider: any = new ApiPromise({ provider: wsProvider, rpc: jsonrpc })

export const getAccounts: GET_ACCOUNTS = async () => {
  const accounts = await web3Accounts()
  let allAccounts = accounts.map(({ address, meta }) => ({
    address,
    meta: { ...meta, name: `${meta.name} (${meta.source})` },
  }))
  const { systemChain, systemChainType } = await retrieveChainInfo()

  const isDevelopment = systemChainType.isDevelopment || systemChainType.isLocal || isTestChain(systemChain)

  Keyring.loadAll({ isDevelopment }, allAccounts)
  return accounts
}

export function toUint8Array(json: any): number[] {
  const str = JSON.stringify(json)

  var bytes = new Uint8Array(str.length)

  for (var iii = 0; iii < str.length; iii++) {
    bytes[iii] = str.charCodeAt(iii)
  }

  return Array.from(new Uint8Array(bytes))
}

const getFromAcct = async (currentAccount: any) => {
  const {
    address,
    meta: { source },
  } = currentAccount

  // if (!isInjected) {
  //   return [currentAccount]
  // }

  // currentAccount is injected from polkadot-JS extension, need to return the addr and signer object.
  // ref: https://polkadot.js.org/docs/extension/cookbook#sign-and-send-a-transaction

  const injector = await web3FromSource(source)
  return [address, { signer: injector.signer }]
}

export const handleEvents = (dispatch: any) => {
  dispatch({ type: 'SET_API_STATE', payload: API_STATE.CONNECT_INIT })
  apiProvider.on('connected', () => {
    apiProvider.isReady.then(async (_api: any) => {
      // let meta = await getMetadata()
      // dispatch({ type: 'SET_METADATA', payload: meta })
      dispatch({ type: 'SET_API', payload: _api })
      dispatch({ type: 'SET_API_STATE', payload: API_STATE.READY })
    })
  })
  apiProvider.on('ready', () => {
    dispatch({ type: 'SET_API_STATE', payload: API_STATE.READY })
  })
  apiProvider.on('error', () => {
    dispatch({ type: 'SET_API_STATE', payload: API_STATE.ERROR })
  })
}

export const txSend = async (
  accounts: any[],
  paramFields: Boolean[],
  inputParams: any,
  palletRPC: string,
  callable: string,
) => {
  const fromAcct = await getFromAcct(accounts)
  let params = transformParams(paramFields, inputParams)

  const txExecute = apiProvider.tx[palletRPC][callable](...params)
  // const unsub = txExecute.signAndSend(...fromAcct, txResHandler).catch(txErrHandler)
  return { txExecute, fromAcct }
  // console.log(unsub)
}
export const fetchStakeBalance = async (account: any) => {
  try {
    let stakes = await getStakes(account.address)
    let deciStakes = {
      total: toDecimal(stakes.total).deci,
      earnedBalance: toDecimal(stakes.reward).deci,
    }
    return deciStakes
  } catch (e: any) {
    return {
      total: '0',
      earnedBalance: '0',
    }
  }
}

export const handleWalletConnect = async (account: any, decimal: any) => {
  let walletDetails = {
    account: '',
    bal: 0,
    isConnected: false,
  }
  try {
    setItem<boolean>('isConnected', true)
    let balance = await getBalance(account)
    let assetBalance = await getAssetBalance(account)
    let stakes = await fetchStakeBalance(account)
    return {
      bal: balance,
      assetBal: assetBalance,
      isConnected: true,
      stakeBal: stakes,
      account: account,
    }
  } catch (e) {
    console.log('wallet-connection-error', e)
    return walletDetails
  }
}

export const getPolkadotAccounts = async () => {
  const injectedWeb3 = (window as any).injectedWeb3
  let walletDetails = {
    account: '',
  }
  if (Object.keys(injectedWeb3).length) {
    try {
      await web3Enable('giantconnectui')
      let accounts = await getAccounts()
      return {
        account: accounts,
      }
    } catch (e) {
      console.log('accounts-error', e)
      return walletDetails
    }
  } else {
    toast.warn('Please install polkadot{.js} extension!', {
      toastId: 'wallet-connection-error',
    })
    return walletDetails
  }
}
