import styled from '@emotion/styled'
import { Typography, Box } from '@mui/material'
import { FC } from 'react'
import GreenDot from 'assets/icons/GreenDot.svg'
import GrayDot from 'assets/icons/GrayElipse.svg'
import ReactCountryFlag from 'react-country-flag'
import { expireDct } from 'polkadot/sendmethods/provider'
import { useWalletContext } from 'contexts/wallet'
import { dctExpire } from 'utils/constants/config'
import { toast } from 'react-toastify'
import WorldIcon from 'assets/icons/WorldIcon.svg'

interface Props {
  data: any
}

const DataPlanCard: FC<Props> = ({ data }) => {
  const { demandReward } = useWalletContext()

  return (
    <CardContainer>
      <CardHeader
        style={{
          background: `linear-gradient(113.74deg, ${
            data?.data?.pack?.country?.countryColor?.start ? data?.data?.pack?.country?.countryColor?.start : '#1471DE'
          } 9.6%, ${
            data?.data?.pack?.country?.countryColor?.end ? data?.data?.pack?.country?.countryColor?.end : '#109D6A'
          } 91.34%)`,
        }}
      >
        <Box sx={{ minWidth: '250px', fontWeight: '700', fontSize: '46.87px', lineHeight: '61px' }}>
          <span>
            <img
              src={data?.data?.isExpired === false ? GreenDot : GrayDot}
              alt=""
              style={{ paddingBottom: '0.5rem' }}
            />
            &nbsp;
          </span>
          {data?.data?.pack?.dataLimitInBytes / (1024 * 1024) / 1024} GB
          <span style={{ fontSize: '26.87px' }}>/30days</span>
        </Box>
        {data?.data?.isExpired === false && (
          <Box>
            <ExpirePlanBtn
              onClick={() => {
                ;(window as any).analytics.track('Clicked on Expire Plan button', {
                  country: data?.data?.pack?.country?.country,
                  dataLimitInBytes: data?.data?.pack?.dataLimitInBytes,
                  price: data?.data?.pack?.priceInWifiToken,
                  serviceType: '4G/LTE',
                  rewards: (data?.data?.pack?.priceInWifiToken * demandReward).toFixed(2),
                  insatanceId: data?.data?.instanceId,
                })
                dctExpire(data?.data?.instanceId).then((res) => res.status === true && toast.success('Expired'))
              }}
            >
              Expire Plan
            </ExpirePlanBtn>
          </Box>
        )}
      </CardHeader>
      <CardBody>
        <DataContainer>
          <DataPlansSubText>Coverage </DataPlansSubText>
          <DataPlansText sx={{ display: 'flex', alignItems: 'center', width: 'auto !important' }}>
            {data?.data?.pack?.country?.flagCountryCode === 'Global' ? (
              <img src={WorldIcon} alt="currency img" style={{ width: '28px', height: '28px', paddingBottom: '5px' }} />
            ) : (
              <ReactCountryFlag
                // style={{ paddingTop: '10px' }}
                countryCode={data?.data?.pack?.country?.flagCountryCode}
              />
            )}
            &nbsp;
            <p
              style={{
                margin: '0',
                minWidth: '50px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {data?.data?.pack?.country?.country}
            </p>
          </DataPlansText>
        </DataContainer>
        <DataContainer>
          <DataPlansSubText>GNT Rewards </DataPlansSubText>
          <DataPlansText>{(data?.data?.pack?.priceInWifiToken * demandReward).toFixed(2)}</DataPlansText>
        </DataContainer>
      </CardBody>
    </CardContainer>
  )
}

export default DataPlanCard

const CardContainer = styled(Box)(({ theme }: any) => ({
  border: '1px solid #DEE7FD',
  borderRadius: '8px',
  minHeight: '223px',
  width: '592px',
  minWidth: '250px',
}))
const CardHeader = styled(Box)(({ theme }: any) => ({
  minHeight: '92px',
  borderRadius: '8px 8px 0 0 ',
  background: 'orange',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 2rem 0 1rem',
  '@media (max-width: 750px)': {
    padding: '1rem',
    justifyContent: 'center',
  },
}))
const CardBody = styled(Box)(({ theme }: any) => ({
  height: 'calc(223px - 92px)',
  padding: '1.9375rem',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 508px)': {
    padding: '0.2rem 1rem',
  },
}))
const DataContainer = styled(Box)(({ theme }: any) => ({
  width: '50%',
  minWidth: '150px',
  '@media (max-width: 450px)': {
    width: '100%',
  },
}))
const DataPlansSubText = styled(Typography)(({ theme }: any) => ({
  color: '#66717B',
  fontSize: '18px',
  fontWeight: 400,
}))
const DataPlansText = styled(DataPlansSubText)(({ theme }: any) => ({
  width: '50%',
  color: '#131A22',
  fontWeight: '700',
}))
const ExpirePlanBtn = styled(Box)(({ theme }: any) => ({
  border: '1px solid #00C853',
  background: '#00C853',
  borderRadius: '0.651rem',
  height: '2.1156rem',
  minWidth: '8.9975rem',
  color: '#FFFFFF',
  display: 'grid',
  placeContent: 'center',
  cursor: 'pointer',
}))
