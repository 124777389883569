import styled from '@emotion/styled'
import { Box, Button, Card, Grid, Typography } from '@mui/material'

export namespace S {
  export const CustomContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateRows: '10% 20% 20% 20% 25% 10%',
    height: '100%',
  }))
  export const ThanksBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }))
  export const DownloadContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.25rem',
  }))
  export const PacksContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    background: '#DBE8F1',
    position: 'relative',
  }))
  export const Packs = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.5rem',
    padding: '0 1rem 0 1.5rem',
  }))
  export const CommunityContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#E5E5E5',
    padding: '2rem',
    backgroundColor: theme.palette.grey[400],
  }))
  export const WorldImageContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    img: {
      height: '-webkit-fill-available !important',
    },
  }))
  export const SocialAppContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0',
    backgroundColor: theme.palette.grey[400],
    gap: '1rem',
  }))
}
