import React from 'react'
import { Grid, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ConnectedToken from './ConnectedToken'
import DisconnectedToken from './DisconnectedToken'
import { useWalletContext } from 'contexts/wallet'

const GiantTokens = () => {
  const { isConnected } = useWalletContext()

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '1rem',
        flex: '1 1',
      }}
    >
      <Grid sx={{ display: 'flex', gridColumnGap: '0.5rem' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>My Wallet</Typography>
        {/* <KeyboardArrowDownIcon sx={{ alignSelf: 'center' }} /> */}
      </Grid>
      {isConnected ? <ConnectedToken /> : <DisconnectedToken />}
    </Grid>
  )
}

export default GiantTokens
