import React, { useEffect, useState } from 'react'
import { Typography, Grid, IconButton, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { PROTOCOL_ADDRESS, TABLE_CONTENT, TABLE_HEADERS } from 'utils/constants'
import MoreInfoPopUp from './MoreInfoPopUp'
import { providerList } from 'utils/constants/config'
import { toDecimal } from 'utils/utils'

const GiantTableContainer = () => {
  const [moreEl, setMoreEl] = useState<HTMLElement | any>(null)
  const [stakers, setStakers] = useState<any>([])
  const [stakerDetails, setStakerDetails] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const moreDrop = Boolean(moreEl)

  useEffect(() => {
    setIsLoading(true)
    providerList()
      .then((res) => {
        setStakers(res.stakers)
        setStakerDetails(res.stakerDetails)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        console.log('Fetch provider error--->', e)
      })
  }, [])

  const getAddress = (address: String) => {
    const response = PROTOCOL_ADDRESS.find((data) => data.address === address)
    if (response !== undefined) return <img src={response.logo} alt="Protocol logo" />
    else return `${address.slice(0, 6)}...${address.slice(-4)}`
  }

  return (
    <TableContainer sx={{ borderTop: '1px solid #DEE7FD', paddingBottom: '1rem' }}>
      <Table sx={{ minWidth: 1000 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ height: '4rem' }}>
            {TABLE_HEADERS.map((data, i) => (
              <TableCell
                key={i}
                sx={{ fontWeight: 'bold', fontSize: '1.125rem', paddingLeft: `${i === 0 ? '2rem' : '1rem'}` }}
              >
                {data}
              </TableCell>
            ))}
            {/* <TableCell></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            TABLE_CONTENT?.length &&
            stakers.length !== 0 &&
            stakers.map((data: any, i: number) => (
              <TableRow key={i} sx={{ height: '6rem' }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ paddingLeft: '2rem', fontWeight: 'bold', fontSize: '1.125rem', width: '50%' }}
                >
                  {stakers[i] ? getAddress(stakers[i]) : ''}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: '1.125rem' }}>
                  {stakerDetails[i]['total'] ? toDecimal(stakerDetails[i]['total']).deci : TABLE_CONTENT[i]?.amount}
                </TableCell>
                {/* <TableCell align="left" sx={{ fontSize: '1.125rem' }}>
                  {TABLE_CONTENT[i]?.pool ? TABLE_CONTENT[i]?.pool : 100}%
                </TableCell> */}
                {/* <TableCell align="left" sx={{ color: '#45B549' }}>
                  <Grid sx={{ display: 'flex' }}>
                    <ArrowDropUpIcon />
                    <Typography sx={{ fontSize: '1.125rem' }}>
                      {TABLE_CONTENT[i]?.gain ? TABLE_CONTENT[i]?.gain : 47280}
                    </Typography>
                  </Grid>
                </TableCell> */}
                <TableCell align="left" sx={{ color: '#45B549', fontSize: '1.125rem' }}>
                  {TABLE_CONTENT[i]?.eAPY ? TABLE_CONTENT[i]?.eAPY : 13.5}%
                </TableCell>
                {/* <TableCell align="left" sx={{ fontSize: '1.125rem' }}>
                  {TABLE_CONTENT[i]?.duration ? TABLE_CONTENT[i]?.duration : 100} days
                </TableCell> */}
                {/* <TableCell>
                  <MoreIconButton onClick={(e) => setMoreEl(moreEl ? null : e.currentTarget)}>
                    <MoreVertIcon />
                    {moreDrop ? <MoreInfoPopUp open={moreDrop} moreEl={moreEl} /> : null}
                  </MoreIconButton>
                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', height: '7rem', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && stakers.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: '7rem',
            alignItems: 'center',
            fontSize: '1.5rem',
          }}
        >
          <p>No items to show...</p>
        </div>
      )}
    </TableContainer>
  )
}

const MoreIconButton = styled(IconButton)(({ theme }) => ({
  fill: theme.palette.common.black,
}))
export default GiantTableContainer
