import React, { FC, Dispatch, SetStateAction } from 'react'
import Modal from '@mui/material/Modal'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import CloseIcon from 'assets/icons/CloseIcon.svg'
import { S } from './modal.styles'
interface Props {
  setType: Dispatch<SetStateAction<number>>
  onClose: () => void
  shareUrl: any
}

const ShareModal: FC<Props> = ({ setType, onClose, shareUrl }) => {

  return (
    <Modal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <S.ShareModalMainContainer
        style={{
          padding: '2rem',
          minHeight: '100px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <img
          src={CloseIcon}
          alt=""
          onClick={onClose}
          style={{ cursor: 'pointer', position: 'absolute', top: '0.3125rem', right: '0.3125rem' }}
        />
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={52} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={52} round={true} />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={52} round={true} />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl}>
          <EmailIcon size={52} round={true} />
        </EmailShareButton>
      </S.ShareModalMainContainer>
    </Modal>
  )
}

export default ShareModal
