import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CircularProgress, Grid, Tooltip, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import MainLogo from 'assets/icons/MainLogo.svg'
import MobileViewLogo from 'assets/icons/GiantMobileLogo.svg'
import WalletIcon from 'assets/icons/WalletIcon.svg'
import { getItem, setItem } from '../../../services/localStorage/localStorage.service'
import { PAGES } from '../../../utils/constants'
import { PAGES_TYPE } from '../../../types'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { WalletContext } from '../../../contexts/wallet'
import Collapse from './Collapse'
import ProfileMenu from './ProfileMenu'
import ConnetWalletModal from './ConnetWalletModal'
import DisconnectWalletModal from './DisconnectWalletModal'
import { getBalance } from 'polkadot/callmethods/system'
const injectedWeb3 = (window as any).injectedWeb3

export default function Header() {
  // const [address, setAddress] = useState<string>('')
  // const isConnected = getItem<boolean>("isConnected");
  const { isConnected, isConnecting, connectedAccount, balance } = useContext(WalletContext)
  const matches = useMediaQuery(`${isConnected ? '(max-width:1100px)' : '(max-width:900px)'}`)
  const mobileView = useMediaQuery('(max-width:450px)')

  const [isToggle, setIsToggle] = useState<boolean>(false)
  const handleModalOpen = () => setIsToggle(true)
  const handleModalClose = () => setIsToggle(false)

  const [isDisconnectToggle, setIsDisconnectToggle] = useState<boolean>(false)
  const handleDisconnectModalOpen = () => setIsDisconnectToggle(true)
  const handleDisconnectModalClose = () => setIsDisconnectToggle(false)
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  useEffect(() => {
    const checkInjected = async () => {
      if (Object.keys(injectedWeb3).length && isConnected) {
        const accounts = getItem<any[]>('accounts', [])

        if (accounts.length) {
          // setAddress(accounts[0].address)
          getBalance(accounts[0].address).then((bal) => {})
        } else console.log('please link your account to the wallet.')
      } else {
        setItem<boolean>('isConnected', false)
      }
    }
    checkInjected()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position="static">
        <HeaderContainer container>
          <LogoContainer item onClick={() => navigate('/')}>
            <img src={mobileView ? MobileViewLogo : MainLogo} alt="" />
          </LogoContainer>
          {matches && <Collapse />}
          {!matches && (
            <>
              <NavContainer item>
                {PAGES.map((page: PAGES_TYPE) => {
                  return (
                    <NavItem
                      to={page.route}
                      key={page.id}
                      style={({ isActive }) => {
                        return {
                          borderBottom: isActive ? '5px solid #45B549' : '',
                        }
                      }}
                    >
                      <HeaderText>{page.title}</HeaderText>
                    </NavItem>
                  )
                })}
              </NavContainer>
              <WalletContainer item>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {isConnected ? (
                    <>
                      <ProfileMenu />
                      <BalanceContainer onClick={() => handleDisconnectModalOpen()}>
                        <LeftContainer
                          className="animate__animated animate__fadeInRight"
                          sx={{ fontWeight: 700, fontSize: '18px' }}
                        >
                          {balance?.deci ?? '0'}&nbsp;GNT
                        </LeftContainer>

                        <img
                          src={WalletIcon}
                          style={{
                            zIndex: '99',
                            background: isConnected ? '#CED6E0' : '',
                            height: '2.5rem',
                          }}
                          className="animate__animated animate__fadeIn"
                          alt="walletLogo"
                        />
                        <RightContainer
                          sx={{
                            fontWeight: 400,
                            color: '#66717B',
                            fontSize: '1.125rem',
                          }}
                          className="animate__animated animate__fadeInLeft"
                        >
                          {connectedAccount?.address.substring(0, 6)}...
                          {connectedAccount?.address.substring(connectedAccount.address.length - 4)}
                        </RightContainer>
                      </BalanceContainer>
                    </>
                  ) : isConnecting ? (
                    <Box sx={{ display: 'flex' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // walletConnectHandler()
                        handleModalOpen()
                      }}
                    >
                      <Tooltip title="Connect wallet">
                        <img src={WalletIcon} alt="walletLogo" />
                      </Tooltip>
                    </div>
                  )}
                </Box>

                <ConnetWalletModal isToggle={isToggle} handleModalClose={handleModalClose} />
                <DisconnectWalletModal isToggle={isDisconnectToggle} handleModalClose={handleDisconnectModalClose} />
              </WalletContainer>
            </>
          )}
        </HeaderContainer>
      </CustomAppBar>
    </Box>
  )
}

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
  color: theme.palette.text.primary,
  position: 'fixed',
  top: 0,
  zIndex: 99,
  height: '5rem',
}))

const HeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0rem 2rem',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '0rem 0 0 1rem',
  },
}))
const LogoContainer = styled(Grid)(({ theme }) => ({
  cursor: 'pointer',
}))
const NavContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  height: '100%',
}))
const WalletContainer = styled(Grid)(({ theme }) => ({}))

const LeftContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  height: '2.5rem',
  borderRadius: '2rem 0 0 2rem',
  padding: '0 1rem',
}))
const RightContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.light,
  height: '2.5rem',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  padding: '0 1rem',
  borderRadius: '0 2rem 2rem 0',
}))
const BalanceContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
  borderRadius: '20px',
}))

const NavItem = styled(NavLink)(({ theme }) => ({
  color: theme.palette.common.black,
  boxSizing: 'border-box',
  height: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '5rem',
  // minWidth: '6rem',
  textDecoration: 'none',
  padding: '0 1rem',
}))

const HeaderText = styled(Typography)(({ theme }) => ({}))
