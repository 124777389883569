import { Grid, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { S } from './styled'
import DataPlanCard from '../../components/cards/DataPlanCard'
import ManageModal from 'components/modals/ManageModal'
import { useWalletContext } from 'contexts/wallet'
import { fetchQueries, getAccountByAddress } from 'utils/constants/config'
import { getInstancesByAddress } from 'utils/constants/queries'
import { useQuery } from 'react-query'

const MyDataPlans: FC = () => {
  const [showModal, setShowModal] = useState<any>(false)
  const [apiInstances, setApiInstances] = useState([])
  const { connectedAccount } = useWalletContext()

  const variable = {
    where: {
      account: {
        address: connectedAccount.address,
      },
    },
  }

  const { data: InstanceList, isLoading } = useQuery(
    ['GetInstancesByAddress', connectedAccount],
    () => fetchQueries(getInstancesByAddress(variable)),
    {
      enabled: !!connectedAccount.address && connectedAccount.address !== null,
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 20,
    },
  )

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(11, 33, 62, 0.25)',
        borderRadius: '8px',
        padding: '1.5rem 2.5rem',
      }}
      item
      xs={12}
    >
      <S.MyDataPlansHeader>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>My Data Plans</Typography>
        <Typography
          sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: '#45B549', cursor: 'pointer' }}
          onClick={() => setShowModal(true)}
        >
          View all plans
        </Typography>
      </S.MyDataPlansHeader>
      <S.MyDataPlansBody>
        {InstanceList?.data?.data?.instances?.length > 0 &&
          InstanceList?.data?.data?.instances.slice(-2).map((data: any, index: any) => {
            return <DataPlanCard key={index} data={{ data }} />
          })}
        {InstanceList?.data?.data?.instances?.length <= 0 && (
          <Typography sx={{ color: '#66717B', height: '5rem', textAlign: 'center' }}>No Active Data Plan</Typography>
        )}
      </S.MyDataPlansBody>
      <ManageModal data={InstanceList} show={showModal} handleCloseModal={handleClose} />
    </Grid>
  )
}

export default MyDataPlans
