import { apiProvider } from 'polkadot'

export const getStakes = (account: any) => {
  let stakeDetail: any
  return apiProvider.query.staking
    .stakingPool(account)
    .then((res: any) => {
      if (!res.isEmpty) {
        stakeDetail = JSON.parse(res)
        return stakeDetail
      }
    })
    .catch((err: any) => {
      console.log("Can't get Stake details", err)
    })
}

export const getProvidersList = async () => {
  let providersList: any
  let ASSET = 1
  return apiProvider.query.staking
    .stakingProviderPool(null)
    .then((res: any) => {
      if (!res.isEmpty) {
        providersList = JSON.parse(res)
        return providersList
      } else {
        return Promise.resolve([])
      }
    })
    .catch((err: any) => {
      console.log("Can't get Providers List", err)
    })
}
