import styled from '@emotion/styled'
import { Box, Button, Card, Grid, Typography } from '@mui/material'
import BgImg from 'assets/HeroBg.png'
import HeroCardBg from 'assets/HeroCardBg.png'

export namespace S {
  export const CustomContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    background: `url(${BgImg})`,
    backgroundSize: 'cover',
    maxWidth: '1400px',
    margin: 'auto',
  }))
  export const BoxCustomContainer = styled(Box)(({ theme }: any) => ({
    background: `url(${BgImg})`,
    backgroundSize: 'cover',
  }))

  export const HeroContainer = styled(Box)(({ theme }: any) => ({
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  }))

  export const BtnsContainer = styled(Box)(({ theme }: any) => ({
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
  }))

  export const HeroCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
  }))

  export const CountryBox = styled(Box)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  }))

  export const PriceContainer = styled(Box)(({ theme }: any) => ({
    width: '50%',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
  }))

  export const StrikedPrice = styled(Typography)(({ theme }: any) => ({
    fontWeight: '500',
    fontSize: '1.3125rem',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  }))
  export const MainPrice = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '2.625rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
    },
  }))
  export const MainText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  }))
  export const MoreInfoContainer = styled(Box)(({ theme }: any) => ({
    width: '50%',
    display: 'flex',
    alignItems: 'flex-end',
    padding: '3rem',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    },
  }))
  export const HowItWorksContainer = styled(Box)(({ theme }: any) => ({
    minHeight: '30vh',
    padding: '4rem 6rem ',

    margin: 'auto',
    backgroundColor: '#fff',

    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2rem',
    },
  }))
  export const DataPlansContainer = styled(Box)(({ theme }: any) => ({
    height: '100%',
    padding: '3rem 6rem',
    maxWidth: '1400px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2rem',
    },
  }))
  export const WhyUsDataContainer = styled(Grid)(({ theme }: any) => ({
    display: 'flex',
    allignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  }))

  export const DataPlansDataContainer = styled(Grid)(({ theme }: any) => ({
    display: 'flex',
    allignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  }))
  export const HowItWorksDataContainer = styled(Grid)(({ theme }: any) => ({
    display: 'flex',
    allignItems: 'center',
  }))

  export const HowItWorksBody = styled(Grid)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  }))
  export const DataPlan = styled(Grid)(({ theme }: any) => ({
    width: '300px',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  }))
  export const DataPlanText = styled(Grid)(({ theme }: any) => ({
    fontWeight: '500',
  }))

  export const HeaderText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '42px',
  }))
  export const HeaderSubText = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '31px',
  }))
  export const BigText = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    fontSize: '2.3125rem',
  }))
  export const WhyUsSubText = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    fontSize: '1rem',
  }))

  export const GreenText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 900,
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
  }))

  export const CustomCard = styled(Card)(({ theme }: any) => ({
    height: '25rem',
    width: '40rem',
    [theme.breakpoints.down('lg')]: {
      height: '20rem',
      width: '30rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '18rem',
      width: '22rem',
    },
    borderRadius: '16px',
    color: '#FFFFF',
    background: `url(${HeroCardBg})`,
    backgroundSize: 'cover',
  }))

  export const CustomCardHeader = styled(Grid)(({ theme }: any) => ({
    borderRadius: 0,
    height: '5.25rem',
    color: theme.palette.primary.contrastText,
    padding: '3.2rem 2rem',
    // background: 'linear-gradient(113.74deg, #DE1428 6.7%, #1471DE 88.43%)',
    background: 'rgb(222 20 40 / 20%)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  }))

  export const CustomCardFooter = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    bottom: '0',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    width: '100%',
  }))

  export const HowItWorksHeader = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  }))

  export const HeroHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '3rem',
    lineHeight: '3.375rem',
  }))

  export const HeroSubHeaderText = styled(Typography)(({ theme }: any) => ({
    marginTop: '2rem',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.9375rem',
  }))

  export const WhyUsContainer = styled(Box)(({ theme }: any) => ({
    background: theme.palette.common.white,
    padding: '3rem 6rem',
    height: '100%',
    maxWidth: '1400px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2rem',
    },
  }))

  export const WhyUsBoxContainer = styled(Box)(({ theme }: any) => ({
    background: theme.palette.common.white,
  }))

  export const MoreInfoButton = styled(Button)(({ theme }: any) => ({
    color: theme.palette.common.white,
    background: 'rgba(255, 255, 255, 0.35)',
    borderRadius: '8px',
    width: 'auto',
    minHeight: '2.25rem',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0 40px',
    textTransform: 'none',
    border: '2px solid #3330',
    fontWeight: 700,
    fontSize: '1.125rem',
    // width: auto;
    '&:hover': {
      opacity: 0.7,
      border: `2px solid ${theme.palette.common.white}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      fontSize: '0.8rem',
    },
  }))
}
