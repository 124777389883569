import PrepayIcon from 'assets/icons/PrepayIcon.svg'
import GlobeIcon from 'assets/icons/GlobeIcon.svg'
import MobileIcon from 'assets/icons/MobileIcon.svg'
import LegendGreen from 'assets/icons/LegendGreen.svg'
import LegendGray from 'assets/icons/LegendGray.svg'
import WorldIcon from 'assets/icons/WorldIcon.svg'
import UsaIcon from 'assets/icons/UsaIcon.svg'
import GiantConnectSmallLogo from 'assets/icons/GiantConnectSmallLogo.svg'
import TwitterLogo from 'assets/icons/TwitterIcon.svg'
import LinkedinLogo from 'assets/icons/LinkedInIcon.svg'
import TelegramLogo from 'assets/icons/TelegramIcon.svg'
import FacebookLogo from 'assets/icons/FacebookIcon.svg'

import DataPlanLogo from 'assets/icons/DataPlan.svg'
import QRCodeLogo from 'assets/icons/QRCode.svg'
import ReWardsLogo from 'assets/icons/Rewards.svg'

import {
  HOW_IT_WORKS_DATATYPE,
  PAGES_TYPE,
  // SHOP_VALUE,
  WHY_US_DATATYPE,
  LEGENDS_DATATYPE,
  SOCIAL_ICONSTYPE,
  SHOP_VALUE,
  POPULAR_COUNTRIES_TYPE,
  DATAPLANS_TYPE,
} from 'types'
import { deflate } from 'zlib'

export const PAGES: Array<PAGES_TYPE> = [
  {
    id: 1,
    title: 'Home',
    route: '/',
  },
  // {
  //   id: 2,
  //   title: 'My Page',
  //   route: '/mypage',
  // },
  {
    id: 3,
    title: 'Shop',
    route: '/shop',
  },
  {
    id: 4,
    title: 'Earn',
    route: '/earn',
  },
  // {
  //   id: 5,
  //   title: 'Vote',
  //   route: '/vote',
  // },
  // {
  //   id: 6,
  //   title: 'Explore',
  //   route: '/explore',
  // },
]

export enum PAGES_ROUTES {
  SHOP = '/shop',
  SWAP = '/swap',
  POOL = '/pool',
  VOTE = '/vote',
  EXPLORE = '/explore',
  WALLET = '/earn',
}

export const HOWITWORKSDATA: Array<HOW_IT_WORKS_DATATYPE> = [
  {
    id: 1,
    title: 'Prepay and Save',
    subTitle: 'Prepaid balances earn crypto rewards',
    icon: PrepayIcon,
  },
  {
    id: 2,
    title: 'Seamless, global coverage',
    subTitle: 'Connect to 4G LTE in 120 countries',
    icon: GlobeIcon,
  },
  {
    id: 3,
    title: 'Join the community',
    subTitle: 'Everyone can join this new economy',
    icon: MobileIcon,
  },
]

export const WHY_US_CARD_DATA: Array<WHY_US_DATATYPE> = [
  {
    id: 1,
    title: 'Community owned',
    subTitle: 'GIANT is the first decentralized internet aggregation layer accessible to anyone owned by everyone.',
  },
  {
    id: 2,
    title: 'Super charged token incentives',
    subTitle: 'Earn GNT token incentives when you buy data and hold a prepaid balance.',
  },
  {
    id: 3,
    title: 'One internet for all',
    subTitle:
      'We see internet access as a basic human right. An Internet that works for everyone can only be built together.',
  },
]

export const SHOP_VALUES: Array<SHOP_VALUE> = [
  {
    id: 1,
    dataPack: 1,
    gnt: 16.0,
    country: 'Global', //Azerbaijan  Macau  Nigeria  Pakistan  Saudi Arabia South Africa Uzbekistan
    icon: WorldIcon,
    earnGnt: 8,
    countryColor: 'linear-gradient(113.74deg, #1471DE 9.6%, #109D6A 91.34%)',
  },
  {
    id: 2,
    dataPack: 1,
    gnt: 16.0,
    country: 'Europe',
    icon: WorldIcon,
    earnGnt: 8,
    countryColor: 'linear-gradient(113.74deg, #052462 9.6%, #1471DE 91.34%)',
  },
  {
    id: 3,
    dataPack: 1,
    gnt: 16.0,
    country: 'USA',
    icon: UsaIcon,
    earnGnt: 8,
    countryColor: 'linear-gradient(113.74deg, #DE1428 9.6%, #1471DE 91.34%)',
  },
]

export const LEGENDS: Array<LEGENDS_DATATYPE> = [
  { id: 1, name: 'TVL', src: LegendGreen, amount: '280.5M', percentage: '20.3' },
  {
    id: 2,
    name: 'Volume 24H',
    src: LegendGray,
    amount: '10.2M',
    percentage: '20.3',
  },
]

export const CONNECT_VALUES: Array<LEGENDS_DATATYPE> = [
  {
    id: 3,
    name: 'TVL',
    src: LegendGreen,
    amount: '1,526,932.99',
    percentage: '20.3',
  },
  {
    id: 4,
    name: 'Volume 24H',
    src: LegendGray,
    amount: '6,090,195.70',
    percentage: '20.3',
  },
]

export const PROTOCOL_ADDRESS = [
  { address: '5DkHgHXbCs4BDqjvMvYzd866UH3q9H9g9VYWeSdhj8m62SbD', logo: GiantConnectSmallLogo },
]
export const TABLE_HEADERS = ['User', 'Staked Amt', 'eAPY%']
//removed 'Total Gain%' '% of Pool', , 'Duration'
export const MYPAGE_TABLE_HEADERS = ['Provider', 'Staked Amount', 'Earned Amount', 'APY(30d)']

export const TABLE_CONTENT = [
  {
    id: 1,
    holder: <img src={GiantConnectSmallLogo} alt="" />,
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
    eAPY: '20.3',
    duration: 720,
  },
  {
    id: 2,
    holder: '0xb9c4...3ae3',
    amount: '1,112,995.43',
    pool: '18.2',
    gain: '2,485,293',
    eAPY: '18.9',
    duration: 389,
  },
  { id: 3, holder: '0x87d3...091a', amount: '193,939.00', pool: '0.3', gain: '472,902', eAPY: '20.3', duration: 323 },
  { id: 4, holder: '0x1e2a...f416', amount: '1,125.12', pool: '0.0', gain: '753,833', eAPY: '18.8', duration: 183 },
]
export const MYPAGE_TABLE_CONTENT = [
  {
    id: 1,
    holder: <img src={GiantConnectSmallLogo} alt="" />,
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
  },
  {
    id: 1,
    holder: <img src={GiantConnectSmallLogo} alt="" />,
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
  },
  {
    id: 1,
    holder: <img src={GiantConnectSmallLogo} alt="" />,
    amount: '3,011,049.99',
    pool: '49.4',
    gain: '1,168,245',
  },
]

export const SOCIAL_ICONS: Array<SOCIAL_ICONSTYPE> = [
  {
    id: 1,
    icon: TwitterLogo,
    link: ' https://twitter.com/giantprotocol',
    name: 'Twitter Link',
  },
  {
    id: 2,
    icon: LinkedinLogo,
    link: 'https://www.linkedin.com/company/giantprotocol/',
    name: 'Linkedin Link',
  },
  {
    id: 3,
    icon: TelegramLogo,
    link: 'https://t.me/giantprotocol',
    name: 'Telegram Link',
  },
  {
    id: 4,
    icon: FacebookLogo,
    link: ' https://www.facebook.com/giantprotocol',
    name: 'Facebook Link',
  },
]

export const POPULAR_COUNTRIES: Array<POPULAR_COUNTRIES_TYPE> = [
  {
    id: 1,
    name: 'United States',
    flag: 'US',
  },
  {
    id: 2,
    name: 'India',
    flag: 'IN',
  },
  {
    id: 3,
    name: 'Europe',
    flag: 'EU',
  },
]

export const DATAPLANS: Array<DATAPLANS_TYPE> = [
  { value: '1', label: '1GB' },
  { value: '2', label: '2GB' },
  { value: '5', label: '5GB' },
  // { value: '15', label: '15GB' },
]
export const DummyCountryData: any = [
  {
    country: 'Global',
    countryColor: { start: '#109D6A', end: 'rgba(16, 157, 106, 0.31)' },
    destination: 'GLOBAL',
    flagCountryCode: 'Global',
    id: '1',
    packs: [{ id: '1', dataLimitInBytes: 5368709120, vendorPrice: 37, priceInWifiToken: 20 }],
    region: 'Global',
    serviceType: '4G/LTE',
    vendor: 'Celitech',
  },
  {
    country: 'Europe',
    countryColor: { start: '#0075FF', end: 'rgba(0, 117, 255, 0.31)' },
    destination: 'EUROPE',
    flagCountryCode: 'Europe',
    id: '2',
    packs: [{ id: '1', dataLimitInBytes: 5368709120, vendorPrice: 37, priceInWifiToken: 20 }],
    region: 'Europe',
    serviceType: '4G/LTE',
    vendor: 'Celitech',
  },
  {
    country: 'USA',
    countryColor: { start: '#DE1428', end: 'rgba(222, 20, 40, 0.31)' },
    destination: 'USA',
    flagCountryCode: 'USA',
    id: '3',
    packs: [{ id: '1', dataLimitInBytes: 5368709120, vendorPrice: 37, priceInWifiToken: 20 }],
    region: 'USA',
    serviceType: '4G/LTE',
    vendor: 'Celitech',
  },
]

export const HOW_TO_WORKS_CARD = [
  {
    id: 1,
    svgImg: DataPlanLogo,
    title: 'Purchase your <br />  eSIM data plan',
  },
  {
    id: 2,
    svgImg: QRCodeLogo,
    title: 'Scan the QR code to <br /> install your eSIM',
  },
  {
    id: 3,
    svgImg: ReWardsLogo,
    title: 'Visit the Earn page to see your GIANT ewards after  your plan expires.',
  },
]
