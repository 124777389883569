import { apiProvider, txSend } from 'polkadot'
import { getInstanceByAccount, getInstanceId } from 'polkadot/callmethods/dct'
import { toast } from 'react-toastify'
import { createInstance, getAccountByAddress, updateAccount } from 'utils/constants/config'
import { getDifference, toWei } from 'utils/utils'

export const expireDct = async (account: any, instanceId: any) => {
  if (instanceId) {
    let paramFields = [true]
    let inputParams = [instanceId]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'provider', 'dctExpired')
    const unsub = txExecute
      .signAndSend(...fromAcct, ({ status }: any) => {
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          return
        }
        if (status.type === 'Finalized') {
          toast.success('Expired')
        }
      })
      .catch((err: any) => {
        console.log('Transaction Failed', err)
      })
  } else {
    toast.error('No instance found')
  }
}

export const handleMint = async (
  data: any,
  account: any,
  amount: any,
  setIsLoading: any,
  assetMetadata: any,
  setType: any,
  setInstanceId: any,
) => {
  try {
    await setIsLoading(true)
    let metadata = JSON.stringify(data) // where METADATA is the object that contains the metadata of the purchase
    let paramFields = [true, true]
    let weiAmount = toWei(amount)
    let inputParams = [weiAmount, metadata]
    let { txExecute, fromAcct } = await txSend(account, paramFields, inputParams, 'provider', 'purchase')
    const unsub = await txExecute
      .signAndSend(...fromAcct, async ({ status, events }: any) => {
        events
          // find/filter for failed events
          .filter(({ event }: any) => apiProvider.events.system.ExtrinsicFailed.is(event))
          // we know that data for system.ExtrinsicFailed is
          // (DispatchError, DispatchInfo)
          .forEach(
            ({
              event: {
                data: [error, info],
              },
            }: any) => {
              if (error.isModule) {
                // for module errors, we have the section indexed, lookup
                const decoded = apiProvider.registry.findMetaError(error.asModule)
                const { docs, method, section } = decoded

                toast.error(`${section}.${method}: ${docs.join(' ')}`)
                setIsLoading(false)
              } else {
                // Other, CannotLookup, BadOrigin, no extra info
                console.log(error.toString())
              }
            },
          )
        if (status.type === 'Invalid') {
          toast.error('Invalid transaction')
          setIsLoading(false)
          return
        }
        if (status.type === 'Finalized') {
          toast.success('Minted')
          setIsLoading(false)
          setType(3)
          let currentInstanceId = await getInstanceId()
          setInstanceId(currentInstanceId)
          createInstance(
            account?.address,
            currentInstanceId,
            data?.id,
          )
        }
      })
      .catch((err: any) => {
        // console.log('Transaction Failed', err.message)
        let errorMessage =
          err.message === '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low'
            ? 'Account balance too low'
            : err.message
        toast.error(errorMessage)
        setIsLoading(false)
      })
  } catch (err) {
    console.log('error', err)
  }
}
