import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'

export namespace S {
  export const ModalContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '2rem',
    boxShadow: '24px',
    borderRadius: '8px',
    maxWidth: '41rem',
    // minHeight: '502px',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    '&:focus-visible': {
      outline: 'none',
    },
  }))
  export const DisconnectModalContainer = styled(ModalContainer)(({ theme }: any) => ({
    maxWidth: '40%',
    width: '100%',
    padding: '1.5rem',
    '@media (max-width: 600px)': {
      maxWidth: '70%',
    },
  }))

  export const ModalHeading = styled.div(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '5px',

    h2: {
      margin: '0px',
    },
  }))

  export const ModalContent = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '0.5rem',
  }))
  export const DisconnectModalContent = styled(ModalContent)(({ theme }: any) => ({
    width: '100%',
  }))
  export const ModalContentBox = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '0.5rem',
    padding: '1rem',
    background: 'transparent',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.main}`,
    width: '20rem',
    borderRadius: '5px',
    cursor: 'pointer',
    opacity: '0.6',
    '&:hover': {
      opacity: 1,
    },
  }))
  export const DisconnectModalContentBox = styled(ModalContentBox)((props) => {
    return {
      boxSizing: 'border-box',
      width: '100%',
      background: props.theme.palette.secondary.main,
      color: props.theme.palette.common.black,
      opacity: props.toggleTheme ? '1' : '0.6',
      '&:hover': {
        opacity: 1,
      },
    }
  })
  export const ConnectedAccountBox = styled(DisconnectModalContentBox)(({ theme }: any) => ({
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    opacity: 1,
  }))
  export const DisconnectBtn = styled(Button)(({ theme }: any) => ({
    background: 'transparent',
    border: `1px solid ${theme.palette.primary.main}`,
    height: '2rem',
    borderRadius: '1rem',
    fontSize: '0.7rem',
  }))
  export const ModalContentBoxItems = styled(Box)(({ theme }: any) => ({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '0.6rem',
    boxSizing: 'border-box',
  }))
  export const AddressText = styled(Typography)(({ theme }: any) => ({
    fontSize: '0.8rem',
    fontWeight: '200',
  }))
  export const MainText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1rem',
    fontWeight: '900',
  }))
}
