import React, { FC, useState } from 'react'
import PrimaryButton from 'components/buttons/PrimaryButton'
import { S } from './styled'
import SwapDropDown from './SwapDropDown'
import UpDownArrow from 'assets/icons/arrow-downarrow-up.svg'
import More from 'assets/icons/more.svg'
import { IconButton } from '@mui/material'
import styled from '@emotion/styled'

// selected dataset
const swapData = [
  { value: 'USG', label: 'USG' },
  { value: 'GNT', label: 'GNT' },
]

const Swap: FC = () => {
  const [swapFirstValue, setSwapFirstValue] = useState<string>('')
  const [selectFirstSwap, setSelectFirstSwap] = useState<any>(swapData[0])

  const [swapSecondValue, setSwapSecondValue] = useState<string>('')
  const [selectSecondSwap, setSelectSecondSwap] = useState<any>(swapData[1])
  const [isSwap, setIsSwap] = useState<Boolean>(false)

  const handleSwap = async () => {
    setIsSwap(!isSwap)
    if (swapSecondValue !== '' || swapSecondValue !== '') {
      setSwapFirstValue(swapSecondValue)
      setSwapSecondValue((parseFloat(swapSecondValue) / 1).toString())
    } else if (swapSecondValue === '' || swapSecondValue === '') {
      return
    }
  }

  return (
    <S.SwapContainer>
      <S.SwapCard>
        <S.SwapHeader>
          <S.SwapHeaderLeft>Swap</S.SwapHeaderLeft>
          <S.SwapHeaderRight>
            <CustomIconButton aria-label="delete">
              <img src={More} alt="More" />
            </CustomIconButton>
          </S.SwapHeaderRight>
        </S.SwapHeader>

        <S.SwapMain theme={{ isSwap: isSwap }}>
          <S.SwapMainLeft
            placeholder="0.00"
            value={swapFirstValue}
            onChange={(e: any) => {
              let regex = /^-?[0-9]\d*\.?\d*$/
              if (e.target.value === '' || regex.test(e.target.value)) {
                setSwapFirstValue(e.target.value)
                if (e.target.value === '') {
                  setSwapSecondValue('')
                } else {
                  setSwapSecondValue((parseFloat(e.target.value) / 1).toString())
                }
              }
            }}
          />
          <SwapDropDown
            disable={selectSecondSwap}
            swapOption={swapData}
            swapValue={selectFirstSwap}
            setSwapValue={setSelectFirstSwap}
          />
        </S.SwapMain>
        <S.SwapExchange onClick={() => handleSwap()}>
          <img src={UpDownArrow} alt="UpDownArrow" />
        </S.SwapExchange>

        <S.SwapMain theme={{ isSwap: isSwap }}>
          <S.SwapMainLeft
            placeholder="0.00"
            value={swapSecondValue}
            onChange={(e: any) => {
              let regex = /^-?[0-9]\d*\.?\d*$/
              if (e.target.value === '' || regex.test(e.target.value)) {
                setSwapSecondValue(e.target.value)
                if (e.target.value === '') {
                  setSwapFirstValue('')
                } else {
                  setSwapFirstValue((parseFloat(e.target.value) / 1).toString())
                }
              }
            }}
          />
          <SwapDropDown
            disable={selectFirstSwap}
            swapOption={swapData}
            swapValue={selectSecondSwap}
            setSwapValue={setSelectSecondSwap}
          />
        </S.SwapMain>
        <S.SwapInfo>
          <i className="fa fa-info-circle" style={{ color: '#66717B' }}></i> {''} {''}
          <span style={{ color: '#66717B' }}>{'1.00 USG = 1.00 GNT ($1.00)'}</span>
        </S.SwapInfo>
        <PrimaryButton style={{ height: '3.75rem', width: '100%' }}>Swap</PrimaryButton>
      </S.SwapCard>
    </S.SwapContainer>
  )
}

const CustomIconButton = styled(IconButton)`
  width: 2.188rem;
`

export default Swap
