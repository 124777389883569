import styled from '@emotion/styled'
import { Box, Button, Card, CardContent, Container, Grid, IconButton, Typography } from '@mui/material'
import { lineHeight, typography } from '@mui/system'
import BgImg from 'assets/ShopHeaderBg.png'
import { Link } from 'react-router-dom'

export namespace S {
  export const ModalHeader = styled(Box)(({ theme }: any) => ({
    minHeight: '84px',
    // background: 'linear-gradient(113.74deg, #DE1428 9.6%, rgba(222, 20, 40, 0.31) 91.34%);',
    // opacity: 0.2,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 4rem 0 2rem',
    borderRadius: '8px 8px 0px 0px',
    gap: '0.5rem',
    flexWrap: 'wrap',
  }))
  export const ModalMainContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    // minWidth: '50%',
    //502,
    overflowX: 'hidden',
    overflowY: 'auto',
    // height: 'calc(512px - 84px)',
    '@media (min-width: 876px)': {
      minHeight: 502,
      minWidth: 810,
    },
    '@media (max-width: 876px)': {
      width: '80%',
      maxHeight: '80%',
    },
  }))
  export const ShareModalMainContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    // minWidth: '50%',
    // height: 502,
    maxHeight: '80%', //502,
    overflowX: 'hidden',
    overflowY: 'auto',
  }))
  export const ManageModalContainer = styled(Box)(({ theme }: any) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '65%',
    height: 675,
  }))
  export const ManageModalBody = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    background: '#F3F5F8',
    overflow: 'auto',
    height: 'calc(100% - 5rem)',
  }))
  export const CustomIconButton = styled(IconButton)(({ theme }: any) => ({
    '&:hover': {
      background: 'none',
    },
    svg: {
      color: '#000',
      padding: '1rem',
      borderRadius: '50%',
      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
  }))
  export const ModalBody = styled(Box)(({ theme }: any) => ({
    maxHeight: '80%',
    padding: '2rem',
    display: 'flex',
    gap: '1.5rem',
    flexDirection: 'column',
    boxSizing: 'border-box',
  }))
  export const DataContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: '2rem',
    gap: '1.5rem',
    borderBottom: '1px solid #DEE7FD',
  }))
  export const TitleContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    '@media (max-width: 576px)': {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }))
  export const TitleText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
  }))
  export const ValueText = styled(TitleText)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '1.5rem',
  }))
  export const DynamicContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '75%',
    paddingTop: '1.5rem',
  }))
  export const CustomDynamicContainer = styled(Grid)(({ theme }: any) => ({
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexWrap: 'wrap',
    // height: '100%',
    height: 'cal(423px - 84px)',
    padding: '1rem 2rem',
    gap: '2rem',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
    },
  }))

  export const ModalWarning = styled(Box)(({ theme }: any) => ({
    background: ' rgba(255, 196, 48, 0.28)',
    color: '#FF7A00',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem',
    position: 'absolute',
    width: '100%',
  }))

  export const GiftModalBody = styled(Box)(({ theme }: any) => ({
    minHeight: 'calc(502px - 84px)',
    disply: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'space-between',
    padding: '2.2rem',
    boxSizing: 'border-box',
  }))
  export const GiftModalContainerBox = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    placeContent: 'center',
    padding: '20px',
  }))
  export const GiftModalContainerBoxLeft = styled(Box)(({ theme }: any) => ({
    height: '35vh',
    width: '50%',
    minWidth: '300px',
    display: 'grid',
    placeContent: 'center',
  }))
  export const GiftModalContainerBoxRight = styled(Box)(({ theme }: any) => ({
    height: '35vh',
    width: '50%',
    minWidth: '300px',
    display: 'grid',
    placeContent: 'center',
  }))
  export const GiftModalText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'center',
    // padding: '1rem 0',
    boxSizing: 'border-box',
  }))
  export const GiftModalSubText = styled(Typography)(({ theme }: any) => ({
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 700,
    textAlign: 'center',
  }))
  export const GiftModalSubText2 = styled(Typography)(({ theme }: any) => ({
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'center',
  }))
  export const GiftSuccessContainer = styled(CustomDynamicContainer)`
    gap: 0;
    flex-direction: column;
  `
  export const GiftCheers = styled(Box)(({ theme }: any) => ({
    width: '50%',
    display: 'grid',
    placeContent: 'center',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  }))

  export const SuccessContainer = styled(Box)(({ theme }: any) => ({
    background: '#f3f5f7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    '@media (min-width: 876px)': {
      height: '13rem',
      width: '46.4375rem',
    },
    '@media (max-width: 876px)': {
      maxWidth: '80%',
      padding: '4rem',
      textAlign: 'center',
    },
  }))
  export const SuccessText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '1.5rem',
  }))
  export const StatusText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1rem',
    fontWeight: 400,
    marginTop: '1rem',
  }))

  export const NotDoneContainer = styled(Box)(({ theme }: any) => ({
    // width: '50%',
    // padding: '2rem 0 0rem 4rem',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-around',
    // gap: '3rem',
    // minHeight: '256px',
    // boxSizing: 'border-box',
  }))
  export const ShareHeaderText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#414141',
  }))
  export const ShareHeaderSubText = styled(ShareHeaderText)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
  }))
  export const AggreeText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 400,
    fontSize: '0.9375rem',
    lineHeight: '1.25rem',
    color: '#0B213E',
    paddingLeft: '5px',
  }))
  export const RoundWrapper = styled(Box)(({ theme }: any) => ({
    background: '#00C853',
    width: '2rem',
    height: '2rem',
    display: 'grid',
    borderRadius: '50%',
    placeContent: 'center',
    color: theme.palette.primary.contrastText,
  }))
  export const TextWrapper = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '3rem 90%',
    position: 'relative',
    minWidth: '300px',
    margin: '1rem 0',
    // gap:"1rem"
  }))
  export const AggrementText = styled(AggreeText)(({ theme }: any) => ({
    color: '#66717B',
  }))
  export const NotDoneText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1.5rem',
    fontWeight: 700,
  }))

  export const InstructionText = styled(NotDoneText)(({ theme }: any) => ({
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
  }))

  export const ActivationText = styled(Typography)(({ theme }: any) => ({
    fontSize: '1rem',
    fontWeight: 400,
  }))

  export const MainContent = styled(Box)(({ theme }: any) => ({
    gap: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  }))
  export const GntText = styled(Typography)(({ theme }: any) => ({
    fontWeight: 700,
    fontSize: '2.625rem',
    lineHeight: '3.4375rem',
    color: theme.palette.text.primary,
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      fontSize: '2rem',
    },
  }))
  export const ConfirmBtn = styled(Button)(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    color: theme.palette.common.white,
    minWidth: '12rem !important',
    height: '50px',
    boxShadow: 'none',
    border: 'none',
    fontWeight: 700,
    padding: '0rem 0.625rem',
    fontSize: '1.125rem',
    lineHeight: '1.4375rem',
    textTransform: 'capitalize',
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.75,
    },
    '&:disabled': {
      background: '#C5C5C5',
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '12rem !important',
    },
    [theme.breakpoints.only('xs')]: {
      minWidth: '12rem !important',
    },
  }))
  export const CheckBox = styled.input(({ theme }: any) => ({
    width: '21px',
    height: '21px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
  }))

  export const CongratsContainer = styled(Box)(({ theme }: any) => ({
    height: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }))
  export const CongratsText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  }))
  export const NoteText = styled(Typography)(({ theme }: any) => ({
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '17px',
  }))
  export const CongratsSubText = styled(Link)(({ theme }: any) => ({
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  }))
  export const BtnContainer = styled(Box)(({ theme }: any) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',
  }))

  export const ActivateCongratsText = styled(Typography)(({ theme }: any) => ({
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '28px',
    textAlign: 'center',
  }))
  export const ActivateCongratSubText = styled(Box)(({ theme }: any) => ({
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#66717B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }))

  export const CustomModalBody = styled(ModalBody)(({ theme }: any) => ({
    padding: '2rem 4rem 4rem 4rem',
    display: 'flex',
    height: 'calc(502px - 84px)',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }))
  export const ActivateContainer = styled(Box)(({ theme }: any) => ({
    // width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '200px',
    maxHeight: '200px',
  }))
  export const ActivateSubContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr',
    },
  }))

  export const ShareBody = styled(CustomModalBody)(({ theme }: any) => ({
    height: 504,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    padding: '4rem',
  }))
  export const CopyInput = styled.input(({ theme }: any) => ({
    background: '#f4f5f7',
    color: theme.palette.primary.main,
    width: '90%',
    border: 'none',
    borderRadius: '10px 0 0 10px',
    padding: '1rem 2rem 1rem 3rem',
    fontSize: '1.125rem',
    // borderLeft:"1px solid red",
    fontWeight: 700,
    height: '47px',
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
    },
  }))
  export const CopyContainer = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    position: 'relative',
    width: '100%',
  }))
  export const CopyIconContainer = styled(Box)(({ theme }: any) => ({
    display: 'grid',
    placeContent: 'center',
    width: '2.9375rem',
    // height: '100%',
    borderRadius: '0 10px 10px 0',
    background: theme.palette.primary.main,
  }))
  export const LinkIconContainer = styled(Box)(({ theme }: any) => ({
    // display: 'grid',
    // placeContent: 'center',
    // padding: '16px',
    // background: '#f4f5f7',
    height: '15px',
    left: '1rem',
    position: 'absolute',
    top: '33%',
    borderRight: '1px solid #C3C4C6',
    // borderRadius: '10px 0 0 10px',
  }))
  export const ShareIconContainer = styled(Box)(({ theme }: any) => ({
    height: '2.9375rem',
    width: '2.9375rem',
    display: 'grid',
    placeContent: 'center',
    background: theme.palette.primary.main,
    borderRadius: '10px',
    marginLeft: '10px',
  }))
  export const CustomMediaButton = styled.button(
    () => `
   display: flex
  justify-content: center
  align-items: center
  flex-shrink: 0
  width: 48px
  height: 48px
  border-radius: 50%
  background: #E6E8EC
  transition: all .2s
  svg
      transition: fill .2s
  &:hover
      background: #3772FF
      svg
          fill: #FCFCFD
  +dark
      background: #353945
      svg
          fill: #FCFCFD
      &:hover
          background: #3772FF
          svg
              fill: #FCFCFD
    &:not(:last-child)
        margin-right: 24px
  `,
  )
}
