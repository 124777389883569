export const getInstancesByAddress = (variables: any) => {
  return {
    operationName: 'GetInstancesByAddress',
    query: `query GetInstancesByAddress($where: JSON) {
  instances(where: $where) {
    id
    account {
      address
      id
    }
    instanceId
    isExpired
    pack {
      id
      dataLimitInBytes
      vendorPrice
      priceInWifiToken
      country {
        id
        region
        vendor
        country
        destination
        serviceType
        flagCountryCode
        countryColor
      }
    }
  }
}`,
    variables: variables,
  }
}

export const fetchCountries = (variables: any) => {
  return {
    operationName: 'fetchCountries',
    query: `query fetchCountries($where: JSON, $limit: Int, $start: Int) {
  countries( where: $where, limit: $limit, start: $start,) {
    id
    country
    destination
    serviceType
    region
    vendor
    flagCountryCode
    countryColor
    packs {
      id
      createdAt
      dataLimitInBytes
      vendorPrice
      priceInWifiToken
    }
  }
}`,
    variables: variables,
  }
}

export const fetchDummyCountries = (variables: any) => {
  return {
    operationName: 'fetchDummyCountries',
    query: `query fetchDummyCountries($where: JSON, $limit: Int, $start: Int,$packsWhere2: JSON) {
  countries( where: $where, limit: $limit, start: $start,) {
    id
    country
    destination
    serviceType
    region
    vendor
    flagCountryCode
    countryColor
    packs(where: $packsWhere2) {
      id
      createdAt
      dataLimitInBytes
      vendorPrice
      priceInWifiToken
    }
  }
}`,
    variables: variables,
  }
}

export const fetchPacksById = (variables: any) => {
  return {
    operationName: 'fetchPacksById',
    query: `query fetchPacksById ($where: JSON) {
  packs(where: $where) {
    id
    dataLimitInBytes
    vendorPrice
    priceInWifiToken
    country {
      id
      country
      destination
      serviceType
      region
      vendor
      flagCountryCode
      countryColor
    }
  }
}`,
    variables: variables,
  }
}

export const fetchCountryCount = {
  operationName: 'fetchCountryCount',
  query: `query fetchCountryCount {
  countriesConnection {
    aggregate {
      count
      totalCount
    }
  }
}`,
}
